import { useState } from "react";

import axios from "axios";
import Icon from "../../../../../components/body/icon";
import Td from "../../../../../components/body/table/tbody/td";
import Tr from "../../../../../components/body/table/tr";
import Loader from "../../../../../components/body/loader";
import Modal from '../Modal';

export default function Item({ item, tipo, id_loja, filter_date_start, filter_date_end, callback, active }) {
    // ESTADOS
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [lojas, setLojas] = useState([]);
    const [showModal, setShowModal] = useState(false);

    // FILTROS MODAL
    const [status, setStatus] = useState('');
    const [title, setTitle] = useState('');
    const [frequency, setFrequency] = useState('');
    const [system, setSystem] = useState('');
    const [store, setStore] = useState('');

    // VARIÁVEIS
    let porc_1 = (item?.qtd_finalizado && item?.qtd_total ? (((item?.qtd_finalizado / item?.qtd_total) * 100).toFixed(0)) : 0);
    let porc_2 = (item?.qtd_checklist_finalizado && item?.qtd_checklist_total ? (((item?.qtd_checklist_finalizado / item?.qtd_checklist_total) * 100).toFixed(0)) : 0);
    let className_aux_1 = (porc_1 < 70 ? 'text-danger' : 'text-success');
    let className_aux_2 = (porc_1 < 70 ? 'text-danger' : 'text-success');

    // TIPO 1 = LOJA
    // TIPO 2 = SUPERVISOR

    function get_lojas(id_usuario) {
        if (tipo === 2) {
            if (show) {
                setShow(false);
                setTimeout(() => {
                    setLoading(true);
                }, 100);

                // MANDA ID ATIVO PRO COMPONENTE PAI
                if (callback) {
                    callback('');
                }
            } else {
                if (id_usuario) {
                    setShow(true);

                    axios({
                        method: 'get',
                        url: window.host_madnezz + '/systems/integration-react/api/request.php?type=Dashboard&do=getShopVisit',
                        params: {
                            filter_id_user: id_usuario,
                            //filter_id_supervisor: id_usuario,
                            filter_date_start: filter_date_start,
                            filter_date_end: filter_date_end,
                            filter_type: 'store',
                            filter_subcategory: [4589, 4740]
                        }
                    }).then((response) => {
                        setLoading(false);

                        if (response?.data?.data) {
                            setLojas(response?.data?.data);
                        }
                    });

                    // MANDA ID ATIVO PRO COMPONENTE PAI
                    if (callback) {
                        callback(id_usuario);
                    }
                }
            }
        }
    }
    const [subcategoria2, setSubcategoria2] = useState('');
    // EXIBE MODAL
    function getDetail(status, title, frequency, system, store, subcategoria) {
        setStatus(status);
        setTitle(title);
        setFrequency(frequency);
        setSystem(system);
        setStore(store);
        setSubcategoria2(subcategoria)
        setShowModal(true);
    }

    // ESCONDE MODAL
    const handleHideModal = (e) => {
        setShowModal(e.show);
    }

    function calcularPorcentagem(num, total) {
        // Calcula a porcentagem
        const resultado = ((num * 100) / total).toFixed(0)
        //  const resultado = Math.min(Math.round((num * 100) / total), 100);

        return resultado;
    }

    return (
        <>
            <Modal
                show={showModal}
                title={title}
                tipo={tipo}
                loja={(item?.id_filial ? 'Filial ' + item?.id_filial + ' - ' + item?.loja : item?.loja)}
                usuario={item?.usuario}
                id_usuario={item?.id}
                id_loja={(store ? store : (tipo === 1 ? id_loja : ''))}
                filter_date_start={filter_date_start}
                filter_date_end={filter_date_end}
                filter_status={status}
                filter_frequency={frequency}
                filter_system={system}
                subcategoria_2={subcategoria2}
                onHide={handleHideModal}
            />

            <Tr disabled={(active === item?.id || active === '' ? false : true)}>
                {(tipo === 1 ?
                    <Td>{(item?.id_filial ? 'Filial ' + item?.id_filial + ' - ' + item?.loja : item?.loja)}</Td>
                    : <></>)}

                <Td overflow="visible" disableView={false}>
                    {(item?.nome ?
                        <div className={'d-flex align-items-center justify-content-between ' + (tipo === 2 ? 'cursor-pointer' : '')} onClick={() => get_lojas(item?.id)}>
                            {item?.nome.split('(')[0]}

                            {(tipo === 2 ?
                                <Icon type={(show ? 'chevron-up' : 'chevron-down')} />
                                : '')}
                        </div>
                        : '-')}
                </Td>

                <Td>{item?.rede}</Td>
                {
                    item.visitas.filter((auditoria) => auditoria.subcategoria === "Auditoria").length > 0 ? (
                        item.visitas
                            .filter((auditoria) => auditoria.subcategoria === "Auditoria")
                            .map((auditoria, i) => (
                                <>
                                    <Td align="center" cursor="pointer" onClick={() => getDetail(undefined, 'Visitas Agendadas', [global.frequencia.mensal, global.frequencia.unico], undefined, undefined, auditoria.id_subcategoria)} >
                                        {auditoria.qtd_total}
                                    </Td>
                                    <Td align="center" cursor="pointer" onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Visitas Realizadas', [global.frequencia.mensal, global.frequencia.unico], undefined, undefined, auditoria.id_subcategoria)}>
                                        {auditoria.qtd_finalizado}
                                    </Td>
                                    <Td align="center" >
                                        {auditoria.qtd_pendente}
                                    </Td>
                                    <Td align="center" >
                                        {auditoria.qtd_total ?
                                            `${calcularPorcentagem(auditoria.qtd_finalizado, auditoria.qtd_total)}%`
                                            : '0%'}
                                    </Td>
                                </>
                            ))
                    ) : (
                        <>
                            {/* <Td> </Td>
                            <Td> </Td>
                            <Td> </Td>
                            <Td> </Td> */}
                            <Td align="center" >0</Td>
                            <Td align="center" >0</Td>
                            <Td align="center" >0</Td>
                            <Td align="center" >0%</Td>
                        </>
                    )
                }
                {
                    item.visitas.filter((visita) => visita.subcategoria === "Visita").length > 0 ? (
                        item.visitas
                            .filter((visita) => visita.subcategoria === "Visita")
                            .map((visita, i) => (
                                <>
                                    <Td align="center" cursor="pointer" onClick={() => getDetail(undefined, 'Visitas Agendadas', [global.frequencia.mensal, global.frequencia.unico], undefined, undefined, visita.id_subcategoria)} >
                                        {visita.qtd_finalizado ? (visita.qtd_finalizado + visita.qtd_pendente) : 0}
                                    </Td>
                                    <Td align="center" cursor="pointer" onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Visitas Realizadas', [global.frequencia.mensal, global.frequencia.unico], undefined, undefined, visita.id_subcategoria)} >
                                        {visita.qtd_finalizado}
                                    </Td>
                                    <Td align="center" >
                                        {visita.qtd_total ? `${calcularPorcentagem(visita.qtd_finalizado, visita.qtd_total)}%` : '0%'}
                                    </Td>
                                    <Td align="center"
                                        cursor="pointer" onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Checklists Realizados', [global.frequencia.mensal, global.frequencia.unico], [global.sistema.checklist], undefined, visita.id_subcategoria)}
                                    >
                                        {visita.qtd_checklist_finalizado || visita.qtd_checklist_finalizado == 0 ? visita.qtd_checklist_finalizado : ''}
                                    </Td>
                                </>
                            ))
                    ) : (
                        <>
                            <Td align="center" >0</Td>
                            <Td align="center" >0</Td>
                            <Td align="center" >0%</Td>
                            <Td align="center" >0</Td>
                        </>
                    )
                }
                <Td align="center">
                    {item.qtd_finalizado_total}
                </Td>
                <Td align="center">
                    {item.qtd_avulsa_total}
                </Td>
            </Tr>

            {(show ?
                (loading ?
                    <Tr>
                        <Td colspan="100%" align="center" overflow={'visible'}>
                            <Loader />
                        </Td>
                    </Tr>
                    :
                    lojas.map((item, i) => {
                        return (
                            <Tr key={'visita_' + item?.id_usuario + '_' + item?.id_loja + '_' + i}>
                                <Td className="ps-5" disableView={false}>{(item?.id_filial ? 'Filial ' + item?.id_filial + ' - ' + item?.loja : item?.loja)}</Td>
                                <Td>{item?.empreendimento}</Td>
                                {
                                    item.visitas.filter((auditoria) => auditoria.subcategoria === "Auditoria").length > 0 ? (
                                        item.visitas
                                            .filter((auditoria) => auditoria.subcategoria === "Auditoria")
                                            .map((auditoria, i) => (

                                                <>
                                                    <Td align="center"
                                                        cursor="pointer"
                                                        onClick={() => getDetail(undefined, 'Visitas Agendadas', [global.frequencia.mensal, global.frequencia.unico], undefined, auditoria?.id_loja, auditoria.id_subcategoria)} >
                                                        {auditoria.qtd_total} </Td>
                                                    <Td align="center"
                                                        cursor="pointer"
                                                        onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Visitas Realizadas', [global.frequencia.mensal, global.frequencia.unico], undefined, auditoria?.id_loja, auditoria.id_subcategoria)}
                                                    >
                                                        {auditoria.qtd_finalizado} </Td>
                                                    <Td align="center" > {auditoria.qtd_pendente} </Td>
                                                    <Td align="center" >
                                                        {auditoria.qtd_total ?
                                                            `${calcularPorcentagem(auditoria.qtd_finalizado, auditoria.qtd_total)}%`
                                                            : '0%'}
                                                    </Td>
                                                </>
                                            ))
                                    ) : (
                                        <>
                                            <Td align="center" >0</Td>
                                            <Td align="center" >0</Td>
                                            <Td align="center" >0</Td>
                                            <Td align="center" >0%</Td>
                                        </>
                                    )
                                }
                                {
                                    item.visitas.filter((visita) => visita.subcategoria === "Visita").length > 0 ? (
                                        item.visitas
                                            .filter((visita) => visita.subcategoria === "Visita")
                                            .map((visita, i) => (
                                                <>
                                                    <Td align="center"
                                                        cursor="pointer"
                                                        onClick={() => getDetail(undefined, 'Visitas Agendadas', [global.frequencia.mensal, global.frequencia.unico], undefined, visita?.id_loja, visita.id_subcategoria)}
                                                    >
                                                        {visita.qtd_finalizado ? (visita.qtd_finalizado + visita.qtd_pendente) : 0}
                                                    </Td>
                                                    <Td align="center"
                                                        cursor="pointer"
                                                        onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Visitas Realizadas', [global.frequencia.mensal, global.frequencia.unico], undefined, visita?.id_loja, visita.id_subcategoria)}
                                                    >
                                                        {visita.qtd_finalizado}
                                                    </Td>
                                                    <Td align="center" >
                                                        {visita.qtd_total || visita.qtd_finalizado ? `${calcularPorcentagem(visita.qtd_finalizado, visita.qtd_total)}%` : '0%'}
                                                    </Td>

                                                    <Td align="center"
                                                        cursor="pointer" onClick={() => getDetail([1, 2, 3, 5, 6, 7], 'Checklists Realizados', [global.frequencia.mensal, global.frequencia.unico], [global.sistema.checklist], visita?.id_loja, visita.id_subcategoria)}
                                                    >
                                                        {visita.qtd_checklist_finalizado}
                                                    </Td>
                                                </>
                                            ))
                                    ) : (
                                        <>
                                            <Td align="center" >0</Td>
                                            <Td align="center" >0</Td>
                                            <Td align="center" >0%</Td>
                                            <Td align="center" >0</Td>
                                        </>
                                    )
                                }
                                <Td align="center">
                                    {item.qtd_finalizado_total}
                                </Td>
                                <Td align="center">
                                    {item.qtd_avulsa_total}
                                </Td>
                            </Tr>
                        )
                    })
                )
                : <></>)}
        </>
    )
}