import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/Global";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Title from "../../../components/body/title";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Icon from "../../../components/body/icon";
import Tr from "../../../components/body/table/tr";
import ModalListaVM from "./modal";
import SelectReact from "../../../components/body/select";
import Lightbox from '../../../components/body/ligthbox';
import TableBody from "./tableBody";
import { cd } from "../../../_assets/js/global";

export default function Lista() {

    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // INFORMAÇÕES DA API
    const [info, setInfo] = useState([]);
    //console.log(info)
    const handleSetItems = (e) => {
        setInfo(e);
    };

    //MODAL
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);
    const [reload, setReload] = useState(false);
    const handleCloseModalLista = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false)
        }, 500);
    };

    //FILTROS
    //ESTADOS ITENS DA TABELA QUE VÃO POR PARAMS
    const [lojas, setLojas] = useState('');
    const [campanha, setCampanhas] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [descricao, setDescricao] = useState('');

    //ABRIR FOTOS
    const [toggler, setToggler] = useState(false);
    const [img, setImg] = useState();

    //ESTADOS FILTRO DO TITLE
    const [selectValue, setSelectValue] = useState('0');
    // Valor inicial 0 pra tabela iniciar com conteúdo, mas está iniciando vazia

    //FILTRO DO TITLE
    function changeSelectValue(e) {
        setSelectValue(e);
    };

    function changeTableInfo(selectValue) {
        setSelectValue(selectValue);
        handleSetFilter(true);
    };

    useEffect(() => {
        changeTableInfo(selectValue)
    }, [selectValue]);

    //FILTROS
    const handleLojas = (e) => {
        setLojas(e);
        handleSetFilter(true);
    };

    const handleCampanha = (e) => {
        setCampanhas(e);
    };

    const handleDescricao = (e) => {
        setDescricao(e);
    };

    // FILTRO DE DATA (INÍCIO)
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    };

    // FILTRO DE DATA (FIM)
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    };

    //ABRIR O MODAL COM FOTOS NA CÉLULA DA TABELA
    function handleClick(e) {
        setImg(e);
        setToggler(!toggler);
    };

    // ABRIR O MODAL NO MODO EDITAR
    const [campanhaId, setCampanhaId] = useState('');
    const [status, setStatus] = useState('');
    //arrow function que recebe id e manda com o status atual pro modal editar
    const getChildInfo = (id) => {
        setCampanhaId(id);
        setStatus(selectValue);
        handleShow();
    }
    // ABRIR O MODAL NO MODO CADASTRO
    function openNew(){
        setCampanhaId();
        setStatus();
        handleShow();
    }

    return (
        <>
            <ModalListaVM 
            show={show} 
            handleShow={handleShow} 
            onHide={handleCloseModalLista} 
            campanhaId={campanhaId} 
            status={status} 
           // getChildInfo={getChildInfo} 
            />
            {/* LIGHTBOX */}
            <Lightbox toggler={toggler} sources={[img]} />
            <Row>
                <Col>
                    <Title
                        overflow={true}
                        icon={<Icon type="new" onClick={()=>openNew()} />}
                        >
                        Relatório - <strong> Campanha</strong>
                        <SelectReact
                                value={selectValue}
                                options={[
                                    { value: '0', label: "Em andamento" },
                                    { value: '1', label: "Finalizadas" },
                                    { value: '2', label: "Suspensas" }
                                ]}
                                onChange={(e) => changeSelectValue(e.value)}
                            />
                    </Title>
                    <Table
                        api={window.host + "/systems/vm/api/vm.php?do=get"}
                        params={{
                            page: 0,
                            filtro_status_campanha: (selectValue ? selectValue : '0' ),//selectValue,
                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                            data_fim: (dataFim ? cd(dataFim) : ''),
                            filtro_loja: lojas,
                            filtro_campanha: campanha,
                            filtro_descricao: descricao,
                        }}
                        onLoad={handleSetItems}
                        reload={reload}
                    >
                        <Thead>
                            <Tr>
                                <Th
                                    name='busca_loja'
                                    api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true"}
                                    onChange={handleLojas}
                                >Loja
                                </Th>
                                <Th
                                    name='busca_campanha'
                                    onChange={handleCampanha}
                                >Campanha
                                </Th>
                                <Th
                                    type="date"
                                    start={{ value: dataInicio, onChange: handleSetDataInicio }}
                                    end={{ value: dataFim, onChange: handleSetDataFim }}
                                >Data
                                </Th>
                                <Th>Data Execução
                                </Th>
                                <Th
                                    name='busca_descricao'
                                    onChange={handleDescricao}
                                >Descrição
                                </Th>
                                <Th>Foto</Th>
                                <Th>Aprov/Neg</Th>
                                <Th>Editar</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(info.length > 0 ?
                                info.map((item) => {
                                    return (
                                        <TableBody item={item} image={handleClick} status={selectValue} getChildInfo={getChildInfo}/>
                                    )
                                }) : "")}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}