import { useState } from "react";
import "./style.scss";
import Form from "../../../../../../components/body/form";
import Input from "../../../../../../components/body/form/input";
import Modal from "../../../../../../components/body/modal";
import ModalBody from "../../../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../../components/body/modal/modalHeader/modalTitle";
import Icon from "../../../../../../components/body/icon";
import axios from "axios";
import Loader from "../../../../../../components/body/loader";
import { useEffect } from "react";
import { cdh } from "../../../../../../_assets/js/global";
import Textarea from "../../../../../../components/body/form/textarea";
import Button from "../../../../../../components/body/button";
import Chat from '../../../../../../components/body/chat';
import Message from '../../../../../../components/body/chat/message';

export default function ModalComentarios(props) {
    //ESTADOS
    const [anexo, setAnexo] = useState('');
    const [anexoValue, setAnexoValue] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusButton, setStatusButton] = useState('');

    //VARIAVEL FECHAR MODAL
    const handleClose = () => {
        props?.onHide(false);
        setTimeout(() => {
            setMessage('');
            setAnexo('');
            setAnexoValue('');
        },500);
    };
    
    // SETAR INPUT DOCUMENTO
    const handleSetAnexo = (response) => {
        let anexo_aux = [];

        if(response){
            JSON.parse(response[0]).map((anexo, i) => {
                anexo_aux.push(anexo.id);
            });
        }else{
            anexo_aux = '';
        }

        setAnexo(anexo_aux.toString());
        setAnexoValue(response[0]);
    };

    // GET MENSAGENS
    function get_messages(loading){
        if(props?.job?.id_job_status){
            if(loading !== false){
                setLoading(true);
            }
            axios({
                method: 'get',
                url: window.host_madnezz+'/systems/integration-react/api/list.php?do=get_msg&id='+props?.job?.id_job_status,
                params: {
                    order: 'desc'
                }
            }).then((response) => {
                setLoading(false);
                if(response.data){
                    setMessages(response.data);
                }                
            });
        }
    }

    // CHAMA O GET MESSAGES SEMPRE QUE ABRE O MODAL
    useEffect(() => {
        get_messages(true);
    },[props?.show]);

    // DATA
    const data = {
        ativ: 'Enviou uma mensagem',
        id_job: props?.job?.id_job,
        id_mov: props?.job?.id_job_status,
        ativ_desc: message,
        anexos: (anexoValue ? anexoValue : '')
    }

    // STATUS BOTÃO FORM
    const handleSetButtonState = (e) => {
        setStatusButton(e);
    }

    // AÇÕES AO ENVIAR FORM
    const handleSubmit = () => {
        get_messages(false);
        setMessage('');
        setAnexo('');
        setAnexoValue('');
    }

    return (
        <>
            <Modal show={props?.show} handleShow={props?.handleShow} onHide={handleClose} >
                <ModalHeader>
                    <ModalTitle>
                        {props?.job?.subcategoria}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {(window.rs_id_office != 6113 && window.rs_id_office != 1559 && props?.interaction !== false ? // SE O CARGO FOR DIFERENTE DE "GESTÃO (8512)" E "COMERCIAL (1559)", HABILITA A OPÇÃO DE ENVIAR MENSAGENS
                        <Form
                            className="mb-4"
                            api={window.host_madnezz+'/systems/integration-react/api/list.php?do=set_msg'}
                            data={data}
                            toast="Mensagem enviada"
                            callback={handleSubmit}
                            status={handleSetButtonState}
                        >
                            <Textarea
                                name="mensagem"
                                label="Escreva uma atualização"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <Input
                                name="atualizacao"
                                type="file"
                                label="Anexo"
                                value={anexo}
                                multiple={true}
                                callback={handleSetAnexo}
                                required={false}
                            />
                            <Button
                                type="submit"
                                status={statusButton}
                            >
                                Enviar
                            </Button>
                        </Form>
                    :'')}

                    {(loading ? 
                        <Loader show={true} />
                    :
                        (messages.length > 0 ?
                            <>
                                <Chat
                                    id={'mensagens_job_'+props?.job?.id_job_status}
                                    send={false}
                                >
                                    {messages.map((message, i) => {
                                        return(
                                            <>
                                                <Message
                                                    key={'atualizacao_'+message.id}
                                                    sender={message.name_usr}
                                                    date={(message?.cad ? cdh(message?.cad) : '')}
                                                    text={
                                                        <>
                                                            {(message?.anexos ?
                                                                <p className="comentario-obras-p-blue">                                                
                                                                    {JSON.parse(message?.anexos).map((anexo, i) => {
                                                                        let anexo_link;
                                                                        let anexo_nome;

                                                                        if(anexo){
                                                                            anexo_link = anexo.id;
                                                                            anexo_nome = anexo.name;
                                                                        }

                                                                        return(
                                                                            <a href={process.env.REACT_APP_URL_UPLOAD+'/'+anexo_link} target="_blank" className="d-block text-primary">
                                                                                <Icon type="file" disabled /> - {anexo_nome}
                                                                            </a>
                                                                        )
                                                                    })}                                              
                                                                </p>
                                                            :'')}

                                                            {(message?.anexos && !message?.motivo && !message?.mensagem ?
                                                                'Anexou o(s) arquivo(s)'
                                                            :
                                                                (message?.motivo ? 
                                                                    message.motivo 
                                                                :
                                                                    message?.mensagem
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                    align={(window.rs_id_usr == message.id_usr?'right':'left')}
                                                />
                                            </>
                                        )
                                    })}
                                </Chat>
                            </>
                        :
                            <p className="mb-0">
                                Nenhuma mensagem encotrada
                            </p>
                        )
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}