import axios from "axios";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import Input from "../../../../../components/body/form/input";
import Gerenciador from "../../../../../components/body/gerenciador";
import Icon from "../../../../../components/body/icon";
import Table from "../../../../../components/body/table";
import Tbody from "../../../../../components/body/table/tbody";
import Td from "../../../../../components/body/table/tbody/td";
import Tr from "../../../../../components/body/table/tr";
import Loader from "../../../../../components/body/loader";
import Switch from "../../../../../components/body/switch"
import NovoResponsavel from "./cadastroResponsavel";
import NovoItem from "./cadastroItens";
import NovaCategoria from "./cadastroCategoria";
import NovaSubcategoria from "./cadastroSubcategoria";
import { scrollToCol } from "../../../../../_assets/js/global";

export default function GerenciadorSubEstrutura({ id, edit_estrutura, callback_close_estrutura, }) {

    //ESTADOS TABELA RESPONSAVEIS
    const [resp, setResp] = useState([]);
    const [searchResp, setSearchResp] = useState('');
    const [inativosResp, setInativosResp] = useState('');
    const [loadingResp, setLoadingResp] = useState(true);
    //MODAL CADASTRAR/EDITAR RESPONSÁVEIS
    const [editResponsavel, setEditResponsavel] = useState(false);
    const [idResp, setIdResp] = useState('');
    const [emailResp, setEmailResp] = useState('');
    const [checkResp, setCheckResp] = useState('');
    //ESTADOS TABELA ITENS
    const [itens, setItens] = useState([]);
    const [searchItens, setSearchItens] = useState('');
    const [inativosItens, setInativosItens] = useState('');
    const [loadingItens, setLoadingItens] = useState('');
    //MODAL CADASTRAR/EDITAR ITENS
    const [editItem, setEditItem] = useState(false);
    const [idItem, setIdIdem] = useState('');
    const [itemDescricao, setItemDescricao] = useState('');
    const [itemPeso, setItemPeso] = useState('');
    const [itemPontos, setItemPontos] = useState('');
    const [item, setItem] = useState('');
    const [icon, setIcon] = useState('');
    //ESTADOS TABELA CATEGORIA
    const [categoria, setCategoria] = useState([]);
    const [searchCategoria, setSearchCategoria] = useState('');
    const [inativosCategoria, setInativosCategoria] = useState('');
    const [loadingCategoria, setLoadingCategoria] = useState(true);
    //MODAL CADASTRAR CATEGORIA
    const [editCategoria, setEditCategoria] = useState(false);
    const [idCategoria, setIdCategoria] = useState('');
    const [labelCategoria, setLabelCategoria] = useState('');
    //ABRIR GERENCIADOR SUBCATEGORIA
    const [idShowSubcategoria, setIdShowSubcategoria] = useState('');
    //ESTADOS TABELA SUBCATEGORIA
    const [subcategoria, setSubcategoria] = useState([]);
    const [searchSubcategoria, setSearchSubcategoria] = useState('');
    const [inativosSubcategoria, setInativosSubcategoria] = useState('');
    const [loadingSubcategoria, setLoadingSubcategoria] = useState(true)
    //MODAL CADASTRAR SUBCATEGORIA
    const [editSubcategoria, setEditSubcategoria] = useState(false);
    const [idSubcategoria, setIdSubcategoria] = useState('');
    const [labelSubcategoria, setLabelSubcategoria] = useState('');

    //ABRIR GERENCIADOR CADASTRO/EDIÇÃO RESPONSÁVEIS
    const handleEditResp = (id, email, check_id) => (
        setEditResponsavel(true),
        setIdResp(id),
        setEmailResp(email),
        setCheckResp(check_id),
        setEditItem(false),
        setEditCategoria(false),
        setEditSubcategoria(false),
        callback_close_estrutura(false)
    );

    //FECHAR GERENCIADOR RESPONSÁVEIS
    const callbackCloseResp = (res) => {
        setEditResponsavel(res);
    };

    //ABRIR GERENCIADRO CADASTRO/EDIÇÃO ITEM
    const handleShowItens = (id, item, icon, descricao, pontos, peso) => (
        setEditItem(true),
        setIdIdem(id),
        setItem(item),
        setIcon(icon),
        setItemDescricao(descricao),
        setItemPeso(peso),
        setItemPontos(pontos),
        setEditResponsavel(false),
        setEditCategoria(false),
        setEditSubcategoria(false),
        callback_close_estrutura(false)
    );

    //FECHAR GERENCIADOR ITEM
    const callbackCloseItem = (res) => {
        setEditItem(res);
    };

    //ABRIR GERENCIADRO CADASTRO/EDIÇÃO CATEGORIA
    const handleShowCategoria = (id, categoria) => (
        setEditCategoria(true),
        setIdCategoria(id),
        setLabelCategoria(categoria),
        setEditItem(false),
        setEditResponsavel(false),
        setEditSubcategoria(false),
        callback_close_estrutura(false),
        setIdShowSubcategoria('')
    );

    //FECHAR GERENCIADOR CATEGORIA
    const callbackCloseCategoria = (res) => {
        setEditCategoria(res);
    };

    //ABRIR GERENCIADRO CADASTRO/EDIÇÃO SUBCATEGORIA
    const handleShowSubcategoria = (id, subcategoria) => (
        setEditSubcategoria(true),
        setIdSubcategoria(id),
        setLabelSubcategoria(subcategoria),
        setEditItem(false),
        setEditResponsavel(false),
        setEditCategoria(false),
        callback_close_estrutura(false)
    );

    //FECHAR GERENCIADOR SUBCATEGORIA
    const callbackCloseSubcategoria = (res) => {
        setEditSubcategoria(res);
    };

    //PEGAR INFOS DA API PRO GERENCIADOR RESPONSAVEIS
    function getResponsaveis() {
        axios.get(window.host + "/systems/checklist/api/gerenciador.php?do=get_responsaveis")
            .then((response) => {
                setResp(response.data);
                setLoadingResp(false);
                setEditResponsavel(false);
                setEditItem(false);
            })
    };
    useEffect(() => { getResponsaveis() }, []);
    const callback_responsaveis = _ => { getResponsaveis() };

    //PEGAR INFOS DA API PRO GERENCIADOR ITENS
    function getItens() {
        axios.get(window.host + "/systems/checklist/api/gerenciador.php?do=get_itens_icones")
            .then((response) => {
                setItens(response.data);
                setLoadingItens(false);
                setEditResponsavel(false);
                setEditItem(false);
            })
    };
    useEffect(() => { getItens() }, []);
    const callback_items = _ => { getItens() };

    //PEGAR INFOS DA API PRO GERENCIADOR CATEGORIA
    function getCategoria() {
        axios.get(window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_category", {
            params: {
                filter_id_apl_default:0,
                filter_active: [0,1]
            }
        }).then((response) => {
            setCategoria(response.data);
            setLoadingCategoria(false);
        })
    }
    useEffect(() => { getCategoria(); }, [])
    const callback_categoria = _ => { getCategoria(); }

    //PEGAR INFOS DA API PRO GERENCIADOR SUBCATEGORIA
    function getSubcategoria() {
        axios.get(window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_subcategory", {
            params: {
                filter_id_category: idShowSubcategoria,
                filter_id_apl_default: 0,
                filter_active: [0,1]
            }
        }).then((response) => {
            setSubcategoria(response.data);
            setLoadingSubcategoria(false);
        })
    }
    useEffect(() => { getSubcategoria(); }, [idShowSubcategoria])
    const callback_subcategoria = _ => { getSubcategoria(); }

    //ATIVOS/INATIVOS -> RECEBE A TABELA POR PARAMETRO 
    function updateAtivos(id, status, table) {
        let res = status == 0 ? 1 : 0;
        let requestData = new FormData();
        requestData.append("id", id);
        requestData.append("status", res);
        requestData.append("tabela", table);
        requestData.append("pergunta_id", '');
        axios({
            method: "post",
            url: window.host + "/systems/checklist/api/gerenciador.php?do=status",
            headers: { "Content-Type": "multipart/form-data" },
            data: requestData,
        }).then(() => {
            if (table == "responsaveis") {
                getResponsaveis();
                if (res == 0) {
                    toast("Resposável desativado com sucesso!")
                } else if (res == 1) {
                    toast("Resposável ativado com sucesso!")
                };
            } else if (table == "itens_icones") {
                getItens();
                if (res == 0) {
                    toast("Item desativado com sucesso!")
                } else if (res == 1) {
                    toast("Item ativado com sucesso!")
                };
            }
        })
    };
    //UPDATE ATIVOS CATEGORIA / SUBCATEGORIA - recebe a tabela por parametro 
    function updateAtivosCategoriaSub(id, active, table, id_aux) {
        let res = active == 0 ? 1 : 0;
        axios({
            method: "post",
            url: window.host_madnezz + "/systems/integration-react/api/registry.php?do=" + table,
            headers: { "Content-Type": "multipart/form-data" },
            data: {
                id: id,
                active: res,
                id_ite: id_aux ? id_aux : ''
            }
        }).then(() => {
            if (table == "set_categoryActive") {
                getCategoria();
                if (res == 0) {
                    toast("Categoria desativada com sucesso!")
                } else if (res == 1) {
                    toast("Categoria ativada com sucesso!")
                }
            } else if (table == "set_subcategoryActive") {
                getSubcategoria();
                if (res == 0) {
                    toast("Subcategoria desativada com sucesso!")
                } else if (res == 1) {
                    toast("Subcategoria ativada com sucesso!")
                }
            };
        })
    }

    //Se abrir o gerenciador de edição na na primeira tabela, fechar as que estiverem abertas aqui
    useEffect(() => {
        if (edit_estrutura) {
            setEditResponsavel(false);
            setEditItem(false);
            setEditCategoria(false);
            setEditSubcategoria(false)
        }
    }, [edit_estrutura]);

    return (
        <>
            <>
                <Gerenciador
                    id="responsaveis"
                    title="Responsáveis"
                    icon={<Icon type="new" onClick={() => handleEditResp('', '', id, '')} />}
                    search={
                        <Input placeholder="Pesquisar" value={searchResp} onChange={(e) => setSearchResp(e.target.value)} />
                    }
                    switch={
                        <Input
                            type="checkbox"
                            id="check_inativos_responsaveis"
                            label="Mostrar Inativos"
                            inverse={true}
                            onChange={(e) => setInativosResp(e.target.checked)}
                        />
                    }
                >
                    <Table fullHeigth={true}>
                        <Tbody>
                            {(loadingResp ?
                                <Tr>
                                    <Td><Loader show={true} /></Td>
                                </Tr>
                                :
                                (resp.length > 0 ?
                                    (resp.filter((item) => {
                                        if (!searchResp) return true;
                                        if (item.email.toLowerCase().includes(searchResp.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr
                                                key={item.id}
                                                cursor="pointer"
                                            >
                                                <Td
                                                    hide={item.status == 0 ?
                                                        (inativosResp ? false : true)
                                                        : ''}
                                                >
                                                    {item.email}
                                                </Td>
                                                <Td
                                                    align="end"
                                                    hide={item.status == 0 ?
                                                        (inativosResp ? false : true)
                                                        : ''}
                                                >
                                                    <Icon
                                                        type="edit"
                                                        onClick={() => handleEditResp(item.id, item.email, item.checklist_id)}
                                                    />
                                                    <Switch
                                                        checked={(item.status == 1 ? true : false)}
                                                        onChange={() => updateAtivos(item.id, item.status, "responsaveis")}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                    )
                                    : <Tr><Td>Nenhum registro encontrado</Td></Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </Gerenciador>
                {editResponsavel ?
                    <NovoResponsavel
                        id={id}
                        id_table={idResp}
                        email_table={emailResp}
                        check_id_table={checkResp}
                        callback={callback_responsaveis}
                        callback_close={callbackCloseResp}
                    />
                    : <></>
                }
            </>
            <>
                <Gerenciador
                    id="itens"
                    title="Itens"
                    icon={<Icon type="new" onClick={() => handleShowItens()} />}
                    search={
                        <Input placeholder="Pesquisar" value={searchItens} onChange={(e) => setSearchItens(e.target.value)} />
                    }
                    switch={
                        <Input
                            type="checkbox"
                            id="check_inativos_itens"
                            label="Mostrar Inativos"
                            inverse={true}
                            onChange={(e) => setInativosItens(e.target.checked)}
                        />
                    }
                >
                    <Table fullHeigth={true}>
                        <Tbody>
                            {(loadingItens ?
                                <Tr>
                                    <Td>
                                        <Loader empty={true} />
                                    </Td>
                                </Tr>
                                :
                                (itens.length > 0 ?
                                    (itens.filter((item) => {
                                        if (!searchItens) return true;
                                        if (item.nome.toLowerCase().includes(searchItens.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr key={item.id}>
                                                <Td
                                                    hide={item.status == 0 ?
                                                        (inativosItens ? false : true)
                                                        : ''}
                                                >
                                                    {item.nome}
                                                </Td>
                                                <Td
                                                    align="end"
                                                    hide={item.status == 0 ?
                                                        (inativosItens ? false : true)
                                                        : ''}
                                                >
                                                    <Icon
                                                        type="edit"
                                                        onClick={() => handleShowItens(item.id, item.nome, item.icone, item.descricao, item.pontos, item.peso)}
                                                    />
                                                    <Switch
                                                        checked={(item.status == 1 ? true : false)}
                                                        onChange={() => updateAtivos(item.id, item.status, "itens_icones")}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                    )
                                    : <Tr><Td>Nenhum registro encontrado</Td></Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </Gerenciador>
                {
                    editItem ?
                        <NovoItem
                            id_table={idItem}
                            item_table={item}
                            icon_table={icon}
                            item_descricao={itemDescricao}
                            item_peso={itemPeso}
                            item_pontos={itemPontos}
                            callback={callback_items}
                            callback_close={callbackCloseItem}
                        />
                        :
                        <></>
                }
            </>
            <>
                <Gerenciador
                    id="categoria"
                    title="Categoria"
                    icon={<Icon type="new" onClick={() => handleShowCategoria()} />}
                    search={
                        <Input placeholder="Pesquisar" value={searchCategoria} onChange={(e) => setSearchCategoria(e.target.value)} />
                    }
                    switch={
                        <Input
                            type="checkbox"
                            id="check_inativos_itens"
                            label="Mostrar Inativos"
                            inverse={true}
                            onChange={(e)=>setInativosCategoria(e.target.checked)}
                        />
                    }
                >
                    <Table>
                        <Tbody>
                            {(loadingCategoria ?
                                <Tr>
                                    <Td>
                                        <Loader show={true} />
                                    </Td>
                                </Tr>
                                :
                                (categoria.length > 0 ?
                                    (categoria.filter((item) => {
                                        if (!searchCategoria) return true;
                                        if (item.label.toLowerCase().includes(searchCategoria.toLocaleLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr
                                                key={item.value}
                                                cursor="pointer"
                                                active={(idShowSubcategoria === item.value ? true : false)}
                                            // hide={item.status == 0 ? (inativos ? false : true) : ''}
                                            // innerRef={(selected == item.id) ? scrollElement : {}}
                                            >
                                                <Td
                                                    hide={item.ativo == 0 ? (inativosCategoria ? false : true) : ''}
                                                    onClick={() => (setIdShowSubcategoria(idShowSubcategoria == item.value ? '' : item.value), setEditCategoria(false), setEditSubcategoria(false), scrollToCol('subcategoria'))}
                                                > {item.label} </Td>
                                                <Td
                                                    align="end"
                                                    hide={item.ativo == 0 ?
                                                        (inativosCategoria ? false : true)
                                                        : ''}
                                                >
                                                    <Icon
                                                        type="edit"
                                                        onClick={() => (handleShowCategoria(item.value, item.label), scrollToCol('editar_categoria'))}
                                                    />
                                                    <Switch
                                                        checked={(item.ativo == 1 ? true : false)}
                                                        onChange={() => updateAtivosCategoriaSub(item.value, item.ativo, "set_categoryActive")}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                    )
                                    : <Tr><Td>Nenhum registro encontrado</Td></Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </Gerenciador>
                {
                    editCategoria ?
                        <NovaCategoria
                            id_table={idCategoria}
                            nome_table={labelCategoria}
                            callback={callback_categoria}
                            callback_close={callbackCloseCategoria}
                        />
                        : <></>
                }
            </>
            {
                idShowSubcategoria ?
                    <>
                        <Gerenciador
                            id="subcategoria"
                            title="Subcategoria"
                            icon={<Icon type="new" onClick={() => handleShowSubcategoria()} />}
                            search={
                                <Input placeholder="Pesquisar" value={searchSubcategoria} onChange={(e) => setSearchSubcategoria(e.target.value)} />
                            }
                            switch={
                                <Input
                                    type="checkbox"
                                    id="check_inativos_itens"
                                    label="Mostrar Inativos"
                                    inverse={true}
                                    onChange={(e)=>setInativosSubcategoria(e.target.checked)}
                                />
                            }
                        >
                            <Table>
                                <Tbody>
                                    {(loadingSubcategoria ?
                                        <Tr>
                                            <Td>
                                                <Loader show={true} />
                                            </Td>
                                        </Tr>
                                        :
                                        (subcategoria.length > 0 ?
                                            (subcategoria.filter((item) => {
                                                if (!searchSubcategoria) return true;
                                                if (item.label.toLowerCase().includes(searchSubcategoria.toLocaleLowerCase())) {
                                                    return true
                                                }
                                            }).map((item, i) => {
                                                return (
                                                    <Tr key={item.value}>
                                                        <Td
                                                        hide={item.ativo == 0 ?
                                                            (inativosSubcategoria ? false : true)
                                                            : ''}
                                                        > {item.label} </Td>
                                                        <Td
                                                            align="end"
                                                            hide={item.ativo == 0 ?
                                                                (inativosSubcategoria ? false : true)
                                                                : ''}
                                                        >
                                                            <Icon
                                                                type="edit"
                                                                onClick={() => (handleShowSubcategoria(item.value, item.label), scrollToCol('editar_subcategoria'))}
                                                            />
                                                            <Switch
                                                                checked={(item.ativo == 1 ? true : false)}
                                                                onChange={() => updateAtivosCategoriaSub(item.value, item.ativo, "set_subcategoryActive", item.aux1)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                            )
                                            : <Tr><Td>Nenhum registro encontrado</Td></Tr>
                                        )
                                    )}
                                </Tbody>
                            </Table>
                        </Gerenciador>
                        {
                            editSubcategoria ?
                                <NovaSubcategoria
                                    id_categoria={idShowSubcategoria}
                                    id_table={idSubcategoria}
                                    nome_table={labelSubcategoria}
                                    callback={callback_subcategoria}
                                    callback_close={callbackCloseSubcategoria}
                                />
                                : <></>
                        }
                    </>
                    : <></>
            }
        </>
    )
}