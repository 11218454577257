import { Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';
import { GerenciadorProvider } from '../../context/Gerenciador';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import Icon from '../../components/body/icon';
import { decrypt } from '../../_assets/js/global';
import axios from 'axios';

// PÁGINAS
import Lista from './Lista';
import Relatorio from './Relatorio';
import Gerenciador from './Gerenciador';

export default function Obras(){
  const params = useParams();  
  const paramsUrl = params['*'].substring(params['*'].indexOf('/')+1);
  const paramsUrlCount = (paramsUrl.match(/\//g)?paramsUrl.match(/\//g).length:0);
  var tokenUrl, tokenUrlAux;

  if(paramsUrlCount>0){
    tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
  }else{
    tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;
  window.rs_id_office = decryptedReturn.id_office;

  const location = useLocation();

  return (
    <GerenciadorProvider>
      <Navbar
        icons={
          (location.pathname == '/systems/obras-react/lista/'+window.token || location.pathname == '/systems/obras-react/relatorio/'+window.token ?
            <Icon type="print" />
          :'')
        }
      >
        <NavbarLink link={'lista/'+window.token} name="Lista" icon="analytics" active={true} />
        <NavbarLink link={'relatorio/'+window.token} name="Relatório" icon="relatorio" />

        {/* SE FOR DO CARGO "GESTÃO" OU PERMISSÃO MAIOR QUE "APROVADOR" */}
        {(window.rs_id_office == 6113 || window.rs_permission_apl === 'master' ? <NavbarLink link={'gerenciador/'+window.token} name="Gerenciador" icon="cog" /> : '')}
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index element={ <Navigate to={'lista/'+window.token} replace /> }/>
          <Route path="lista/:token" index element={ <Lista /> }/>
          <Route path="relatorio/:token" element={ <Relatorio /> }/>

          {/* SE FOR DO CARGO "GESTÃO" OU PERMISSÃO MAIOR QUE "APROVADOR" */}
          {(window.rs_id_office == 6113 || window.rs_permission_apl === 'master' ? 
            <Route path="gerenciador/:token">
              <Route path="" element={ <Gerenciador />} />
              <Route path=":loja/:lote/:id" element={ <Gerenciador />} />
            </Route>
          :'')}
        </Routes>
      </Container>
    </GerenciadorProvider>
  );
}
