import { useState, useContext } from "react";

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import axios from "axios";
import { useEffect } from "react";
import SelectReact from '../../../components/body/select';
import Input from "../../../components/body/form/input";
import { get_date } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";

export default function Posvenda({filters, icons}){
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // VARIÁVEIS
    var date = new Date();
    
    // HABILITA O LOADING DA TABELA NOVAMENTE AO TROCAR AS TELAS
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // ESTADOS
    const [items, setItems] = useState([]);
    const [dateStart, setDateStart] = useState(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', 29)));
    const [dateEnd, setDateEnd] = useState(new Date());
    const [period, setPeriod] = useState(1);
    const [thead, setThead] = useState([]);

    // ESTADOS FILTROS
    const [grupo, setGrupo] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [cardStatus, setCardStatus] = useState([]);

    // SETA ITENS VINDOS DA API
    const handleSetItems = (e) => {
        setItems(e);
    }
    // OPTIONS FILTRO PERÍODO
    const optionsPeriodo = [
        {value: 1, label: '30 dias'},
        {value: 2, label: '60 dias'},
        {value: 3, label: 'Personalizado'}
    ]

    // SETA FILTRO DATA INÍCIO
    const handleDateStart = (e) => {
        setDateStart(e);
        setPeriod(3);
        handleSetFilter(true);
    }

    // SETA FILTRO DATA FIM
    const handleDateEnd = (e) => {
        setDateEnd(e);
        setPeriod(3);
        handleSetFilter(true);
    }

    // SETA PERÍODO
    const handleSetPeriod = (e) => {
        let sub_days = 0;

        setPeriod(e.value);

        if(e.value === 1){
            sub_days = 29;
        }else if(e.value === 2){
            sub_days = 59;
        }

        setDateStart(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', sub_days)));
        setDateEnd(new Date());
        handleSetFilter(true);
    }

    // FILTRO GRUPO
    const handlesetGrupo = (e) => {
        setGrupo(e);
    }

    // FILTRO CLIENTE
    const handleSetCliente = (e) => {
        setCliente(e);
    }

    // FILTRO GRUPO
    const handlesetCardStatus = (e) => {
        setCardStatus(e);
    }

    // ENVIA FILTROS PARA O COMPONENTE PAI
    useEffect(() => {
        if(filters){
            filters(
                <>
                    <SelectReact
                        placeholder="Período"
                        options={optionsPeriodo}
                        onChange={(e) => handleSetPeriod(e)}
                        value={period}
                    />

                    <Input
                        type="period"
                        valueStart={dateStart}
                        valueEnd={dateEnd}
                        onChangeStart={handleDateStart}
                        onChangeEnd={handleDateEnd}
                    />
                </>
            );
        }
    },[period, dateStart, dateEnd]);

    // GET THEADS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Phase&do=getJobPhase'
        }).then((response) => {
            if(response?.data?.data_aux){
                setThead(response.data.data_aux);
            }
        })
    },[]);

    return(
        <Table
            id="posvenda"
            api={window.host_madnezz+'/systems/integration-react/api/request.php?type=Phase&do=getJobPhase'}  
            key_aux_2={'data'}      
            params={{
                filter_date_start: dateStart,
                filter_date_end: dateEnd,
                filter_id_group: grupo,
                filter_id_client: cliente,
                filter_id_card_status: cardStatus,
                limit: 50
            }}     
            onLoad={handleSetItems}
        >
            <Thead>
                <Tr>
                    <Th
                        id="filter_adm"
                        name="filter_adm"
                        api={{
                            url: window.host+'/api/sql.php?do=select&component=grupo'
                        }}
                        onChange={handlesetGrupo}
                    >
                        ADM
                    </Th>

                    <Th
                        id="filter_empreendimento"
                        name={(window.rs_id_emp == 26 ? 'filter_empreendimento' : undefined)}
                        api={(window.rs_id_emp == 26 ? {url: window.host+'/api/sql.php?do=select&component=empreendimento'} : undefined)}                        
                        onChange={handleSetCliente}
                    >
                        {(window.rs_id_emp == 26 ? 'Cliente' : 'Título')}
                    </Th>

                    <Th
                        id="filter_status"
                        name="filter_status"
                        api={{
                            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Job',
                            params: {
                                do: 'getTable',
                                tables: [{
                                    table: 'cardStatus',
                                    filter: {id_apl: 225}
                                }]
                            },
                            key_aux: ['data', 'cardStatus', 'data']
                        }}
                        onChange={handlesetCardStatus}
                    >
                        Status
                    </Th>

                    {(thead.map((item, i) => {
                        return(
                            <Th key={'th_'+item.id} minWidth={110} align="center">{item.nome}</Th>
                        )
                    }))}
                </Tr>
            </Thead>

            <Tbody>
                {(items.length > 0 ?
                    items.map((item, i) => {
                        let title_aux = '';

                        if(item.cliente){
                            title_aux = item.cliente;
                        }else{
                            title_aux = item.job;
                        }

                        return(
                            <Tr key={'usuario_'+item.id_job_status}>
                                <Td>{(item.grupo ? item.grupo : '-')}</Td>
                                <Td>{title_aux}</Td>
                                <Td>{(item?.card_status ? item.card_status : '-')}</Td>

                                {(thead.map((item_th, i_th) => {
                                    if(item[item_th.id].dataHora){
                                        let className_aux = 'text-secondary';

                                        if(item[item_th.id].fase_atual === 1){
                                            if(item?.dataHora_job < get_date('datetime_sql')){
                                                className_aux = 'font-weight-bold text-danger';
                                            }else{
                                                className_aux = 'font-weight-bold';
                                            }                                            
                                        }

                                        return(
                                            <Td align="center" className={className_aux}>
                                                {get_date('date', item[item_th.id].dataHora, 'date_sql')}
                                            </Td>
                                        )
                                    }else{
                                        return(
                                            <Td align="center">
                                                -
                                            </Td>
                                        )
                                    }                                    
                                }))}
                            </Tr>
                        )
                    })
                :
                    <></>
                )}
            </Tbody>
        </Table>
    )
}
