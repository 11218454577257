import { useEffect, useState } from "react";
import Form from "../../../../components/body/form";
import Input from "../../../../components/body/form/input";
import Modal from "../../../../components/body/modal";
import ModalBody from "../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import Button from "../../../../components/body/button";

export default function ModalAddCell({ show, handleShow, onHide, emp_id, nome, callback, categoria_id, sub_categoria_id, ativo, cell_id }) {

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    };

    // //ESTADOS SALVAR INFO 
    const [inputAnswer, setInputAnswer] = useState('');

    useEffect(() => {
        setInputAnswer(nome)
    }, [emp_id, nome, sub_categoria_id])

    const data = {
        id: cell_id ? cell_id : '',
        nome: inputAnswer,
        ativo: ativo,
        //categoria_id: categoria_id ? "" : categoria_id,
        categoria_id: categoria_id ? categoria_id : "" ,
        // empreendimento_id: emp_id,
    };

    function resetForm() {
        handleClose();
        setInputAnswer('');
        callback(true)
    };

    //VERIFICA SE É URL PARA EDITAR OU CADASTRAR, NA CATEGORIA OU SUBCATEGORIA
    var apiAnswer;
    //EDITAR CATEGORIA
    if (cell_id && !categoria_id) {
        apiAnswer = "/systems/galeria/api/gerenciador.php?do=update&what=categoria&id=" + cell_id +"&empreendimento_id="+ emp_id +"&token="
    };
    //EDITAR SUBCATEGORIA
    if (cell_id && categoria_id) {
        apiAnswer = "/systems/galeria/api/gerenciador.php?do=update&what=subcategoria&id=" + cell_id +"&empreendimento_id="+ emp_id + "&token="
    };
    //ADICIONAR CATEGORIA
    if (!cell_id && !categoria_id) {
        apiAnswer = "/systems/galeria/api/gerenciador.php?do=insert&what=categoria&empreendimento_id="+ emp_id +"&token="
    };
    //ADICIONAR SUBCATEGORIA
    if (!cell_id && categoria_id) {
        apiAnswer = "/systems/galeria/api/gerenciador.php?do=insert&what=subcategoria&empreendimento_id="+ emp_id +"&token="
    };

    return (
        <>
            <Modal show={show} handleShow={handleShow} onHide={handleClose}>
                <ModalHeader>
                    <ModalTitle>
                        {(cell_id ? "Editar" : "Adicionar")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host + apiAnswer}
                        data={data}
                        formData={true}
                        callback={resetForm}
                       // test
                    >
                        <Input
                            name="add-categoria"
                            label="Título"
                            value={inputAnswer}
                            onChange={(e) => setInputAnswer(e.target.value)}
                        />
                        <Button type="submit">Enviar</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}