import { useEffect, useState } from "react";
import Modal from "../../../components/body/modal";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import ModalBody from "../../../components/body/modal/modalBody";
import Form from "../../../components/body/form";
import InputContainer from "../../../components/body/form/inputcontainer";
import SelectReact from "../../../components/body/select";
import Textarea from "../../../components/body/form/textarea";
import Input from "../../../components/body/form/input";
import Button from "../../../components/body/button";
import Icon from "../../../components/body/icon";
import axios from "axios";
import CheckboxGroup from "../../../components/body/form/checkboxGroup";

export default function ModalTreinamento(props) {
    const options_disp_email = [
        { value: 1, label: "Sim" },
        { value: 2, label: "Não" }
    ]
    const options_disp_whatsapp = [
        { value: 1, label: "Sim" },
        { value: 2, label: "Não" }
    ]
    const options_disp_comunicado = [
        { value: 1, label: "Sim" },
        { value: 2, label: "Não" }
    ]
    const options_categoria = [
        { value: 1, label: "Derma Cosméticos" },
        { value: 2, label: "Produto" }
    ]
    const options_valor = [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" }
    ]
    const options_sub_categoria = [
        { value: 1, label: " " },
        { value: 2, label: "Lançamentos" }
    ]

    // ESTADOS DE OPTIONS
    const [optionsLojas, setOptionsLojas] = useState([]);
    const [optionsDepartamentos, setOptionsDepartamentos] = useState([]);
    const [optionsCargos, setOptionsCargos] = useState([]);
    const optionsBoolean = [{value: 1, label: "Sim"}, {value: 2, label: "Não"}]
    const optionsTipo = [{value: 1, label: "Convocação"}, {value: 2, label: "Convite"}]

    //ESTADOS MODAL    
    const [categoriaMdl, setCategoriaMdl] = useState('');
    const [urlMdl, setUrlMdl] = useState('');
    const [urlMdlDois, setUrlMdlDois] = useState('');
    const [urlMdlTres, setUrlMdlTres] = useState('');
    const [anexoMdl, setAnexoMdl] = useState('');
    const [descricaoMdl, setDescricaoMdl] = useState('');
    const [perguntaMdl, setPerguntaMdl] = useState('');
    const [valorMdl, setValorMdl] = useState('');
    const [itemMdl, setItemMdl] = useState('');
    const [itemDoisMdl, setItemDoisMdl] = useState('');
    const [itemTresMdl, setItemTreMdl] = useState('');
    const [subCategoriaMdl, setSubCategoriaMdl] = useState('');

    // ESTADOS DE VALORES
    const [lojas, setLojas] = useState('');
    const [departamentos, setDepartamentos] = useState('');
    const [cargos, setCargos] = useState('');
    const [publico, setPublico] = useState(1);
    const [tipo, setTipo] = useState();
    const [disparoEmail, setDisparoEmail] = useState(2);
    const [titulo, setTitulo] = useState('');
    const [capa, setCapa] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    // GET DE OPTIONS
    useEffect(() => {
        if(optionsLojas.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=loja&filial=true'
            }).then((response) => {
                setOptionsLojas(response.data);
            })
        }

        if(optionsDepartamentos.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=departamento'
            }).then((response) => {
                setOptionsDepartamentos(response.data);
            })
        }

        if(optionsCargos.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=cargo'
            }).then((response) => {
                setOptionsCargos(response.data);
            })
        }
    });

    // SELECIONA LOJAS
    function handleSetLojas(e) {
        setLojas(e);
    }

    // SELECIONA DEPARTAMENTOS
    function handleSetDepartamentos(e) {
        setDepartamentos(e);
    }

    // SELECIONA CARGOS
    function handleSetCargos(e) {
        setCargos(e);
    }

    // SELECIONAR PÚBLICO
    function handleCheckPublico(e) {
        setPublico(e.target.value);
    }

    // SELECIONAR TIPO
    function handleCheckTipo(e) {
        setTipo(e.target.value);
    }

    // SELECIONAR DISPARO DE E-MAIL
    function handleCheckDisparoEmail(e) {
        setDisparoEmail(e.target.value);
    }

    // SELECIONAR CAPA
    const handleSetCapa = (e) => {
        setCapa(e[0]);
    }

    return (
        <>
            <Modal show={props.show} onClick={props.handleShow}>
                <ModalHeader>
                    <ModalTitle>
                        Novo <b>Curso</b>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <CheckboxGroup
                            group="loja"
                            callback={handleSetLojas}
                            required={false}
                        />

                        <CheckboxGroup
                            group="departamento"
                            callback={handleSetDepartamentos}
                            required={false}
                        />

                        <CheckboxGroup
                            group="cargo"
                            callback={handleSetCargos}
                            required={false}
                        />

                        <InputContainer label="Público">
                            {optionsBoolean.map((item) => {
                                return (
                                    <Input
                                        key={item.value}
                                        name="publico"
                                        id={'publico_'+item.value}
                                        type="radio"
                                        label={item.label}
                                        value={item.value}
                                        checked={(publico == item.value ? true : null)}
                                        onChange={(e) => handleCheckPublico(e)}
                                    />
                                )
                            })}
                        </InputContainer>

                        <InputContainer label="Tipo">
                            {optionsTipo.map((item) => {
                                return (
                                    <Input
                                        key={item.value}
                                        name="tipo"
                                        id={'tipo_'+item.value}
                                        type="radio"
                                        label={item.label}
                                        value={item.value}
                                        onChange={(e) => handleCheckTipo(e)}
                                    />
                                )
                            })}
                        </InputContainer>

                        <InputContainer label="Disparo de E-mail">
                            {optionsBoolean.map((item) => {
                                return (
                                    <Input
                                        key={item.value}
                                        name="disparo_email"
                                        id={'disparo_email_'+item.value}
                                        type="radio"
                                        label={item.label}
                                        value={item.value}
                                        checked={(disparoEmail == item.value ? true : null)}
                                        onChange={(e) => handleCheckDisparoEmail(e)}
                                    />
                                )
                            })}
                        </InputContainer>

                        <Input
                            type="text"
                            label="Título"
                            name="titulo"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />

                        <SelectReact
                            label="Categoria"
                            name="categoria"
                            value={categoriaMdl}
                            onChange={(e) => setCategoriaMdl(e.value)}
                            options={options_categoria}
                        />

                        <SelectReact
                            label="SubCategoria"
                            name="subcategoria"
                            value={subCategoriaMdl}
                            onChange={(e) => setSubCategoriaMdl(e.value)}
                            options={options_sub_categoria}
                        />

                        <Input
                            type="file"
                            label="Capa"
                            value={capa}
                            multiple={false}
                            callback={handleSetCapa}
                        />

                        <Input
                            label="URL do vídeo"
                            name="url do video"
                            value={urlMdl}
                            onChange={(e) => setUrlMdl(e.target.value)}
                            icon="plus"
                        />
                        <Input
                            type="file"
                            label="Anexo"
                            value={anexoMdl}
                            onChange={(e) => setAnexoMdl(e.target.value)}
                        />
                        <Input
                            type="date"
                            label="Data Início"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e)}
                        />
                        <Input
                            type="date"
                            label="Data Término"
                            value={dataFim}
                            onChange={(e) => setDataFim(e)}
                        />
                        <Textarea
                            label="Descrição"
                            name="descricao"
                            value={descricaoMdl}
                            onChange={(e) => setDescricaoMdl(e.target.value)}
                        />
                        <Input
                            type="text"
                            label="Pergunta"
                            value={perguntaMdl}
                            onChange={(e) => setPerguntaMdl(e.target.value)}
                            icon="plus"
                        />
                        <SelectReact
                            label="Valor"
                            name="valor"
                            value={valorMdl}
                            onChange={(e) => setValorMdl(e.value)}
                            options={options_valor}
                        />
                        <Input
                            label="Item"
                            name="item_um"
                            value={itemMdl}
                            onChange={(e) => setItemMdl(e.target.value)}
                            icon="check"
                        />
                        <Input
                            label="Item"
                            name="item_dois"
                            value={itemDoisMdl}
                            onChange={(e) => setItemDoisMdl(e.target.value)}
                            icon="check"
                        />
                        <Input
                            label="Item"
                            name="item_tres"
                            value={itemTresMdl}
                            onChange={(e) => setItemTreMdl(e.target.value)}
                            api="check"
                            api2="plus"
                            icon="check"
                        />
                        <div className="d-flex align-items-center justify-content-end">
                            <Button type="submit" className="me-2">Salvar</Button>
                            <Button type="submit">Publicar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
};