import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Global';
import { decrypt } from '../../_assets/js/global';
import axios from 'axios';

// PÁGINAS
import Lista from './Lista';
import GerenciadorGaleria from './Gerenciador';

export default function Galeria(props){
  const params = useParams();  
  const paramsUrl = params['*'].substring(params['*'].indexOf('/')+1);
  const paramsUrlCount = (paramsUrl.match(/\//g)?paramsUrl.match(/\//g).length:0);
  var tokenUrl, tokenUrlAux;

  if(paramsUrlCount>0){
    tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
  }else{
    tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;

  // GLOBAL CONTEXT
  const { filters } = useContext(GlobalContext);

  return (
    <>
      <Navbar
        filters={filters}
      >
        <NavbarLink link={'lista/'+window.token} name="Lista" icon="analytics" active={true} />

        {((window.rs_permission_apl === 'master') && (!window.rs_id_lja || window.rs_id_lja == 0) ? 
          <NavbarLink link={'gerenciador/'+window.token} name="Gerenciador" icon="cog" />
        :'')}
      </Navbar>

      <Container>
        <Routes>
            <Route path="/:token" index element={ <Navigate to={'lista/'+window.token} replace /> }/>
            <Route path="lista/:token" element={ <Lista /> }/>
            <Route path="gerenciador/:token" element={ <GerenciadorGaleria /> }/>
        </Routes>
      </Container>
    </>
  );
}
