import { useEffect, useState } from "react";
import Button from "../../../../components/body/button";
import Form from "../../../../components/body/form";
import Input from "../../../../components/body/form/input";
import Textarea from "../../../../components/body/form/textarea";
import Gerenciador from "../../../../components/body/gerenciador";
import Icon from "../../../../components/body/icon";
import { cd } from "../../../../_assets/js/global";
import Switch from "../../../../components/body/switch";

export default function CadastroContrato({ id, callback, industria_id, data_inicio, data_fim, num_lojas, imagem_api, descricao_api, nome_api, valor_api }) {

    const [nome, setNome] = useState();
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();
    const [numLojas, setNumLojas] = useState();
    const [valor, setValor] = useState();
    const [imagem, setImagem] = useState();
    const [descricao, setDescricao] = useState();

    const [avaliacao, setAvaliacao] = useState();
    const [addJob, setAddJob] = useState(true);

    //
    const handleImage = (res) => {
        let aux
        JSON.parse(res[0]).map((item) => {
            aux = (item.id)
        })
        setImagem(aux)
    }

    //
    useEffect(() => {
        if (id) {
            setNome(nome_api);
            setDataInicio(new Date(data_inicio));
            setDataFim(new Date(data_fim));
            setNumLojas(num_lojas);
            setValor(valor_api);
            setImagem(imagem_api.replace("https://upload.fastview.com.br/", ""));
            setDescricao(descricao_api);
        } else if (!id) {
            setNome('');
            setDataInicio('');
            setDataFim('');
            setNumLojas('');
            setValor('');
            setImagem('');
            setDescricao('');
        }
    }, [])

    const data = {
        do: 'salvar',
        tipo: 'contrato',
        industria_id: industria_id,
        id: id ? id : '',
        nome: nome,
        data_inicio: cd(dataInicio),
        data_fim: cd(dataFim),
        quantidade: numLojas,
        valor: valor,
        imagem: imagem,
        descricao: descricao,
        //add_job: addJob,
        //avaliacao: avaliacao
        _: '',
    }

    function resetForm() {
        callback(false);
        setNome('');
        setDataInicio('');
        setDataFim('');
        setNumLojas('');
        setValor('');
        setImagem('');
        setDescricao('');
    }

    return (
        <Gerenciador
            title={id ? "Editar" : "Novo"}
            icon={<Icon type="reprovar" title="Fechar" onClick={() => callback(false)} />}
        >
            <Form
                api={`${window.host}/systems/trade/api/gerenciador-beta.php`}
                data={data}
                callback={resetForm}
                method={'get'}
                formData={true}
            >
                <Input
                    name="nome"
                    type="text"
                    label="Nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                />
                <Input
                    name="data_inicio"
                    type="date"
                    label="Data início"
                    value={dataInicio}
                    onChange={(e) => setDataInicio(e)}
                />
                <Input
                    name="data_fim"
                    type="date"
                    label="Data fim"
                    value={dataFim}
                    onChange={(e) => setDataFim(e)}
                />
                <Input
                    name="qtd_loja"
                    type="text"
                    label="Qtd. Lojas"
                    value={numLojas}
                    onChange={(e) => setNumLojas(e.target.value)}
                    required={false}
                />
                <Input
                    name="valor"
                    type="text"
                    label="Valor"
                    value={valor}
                    onChange={(e) => setValor(e.target.value)}
                    required={false}
                />
                <Input
                    name="avaliacao"
                    type="text"
                    label="Avaliação"
                    value={avaliacao}
                    onChange={(e) => setAvaliacao(e.target.value)}
                />
                <Input
                    name="modelo_base"
                    type="file"
                    label="Modelo base"
                    value={imagem}
                    callback={handleImage}
                    multiple={true}
                />
                <Textarea
                    name="descricao"
                    label="Descrição"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    required={false}
                />
                <Input
                    label="Adicionar ao Jobs"
                    icon={<Switch checked={addJob} onChange={() => setAddJob(!addJob)} />}
                    readonly={true}
                //  onChange={(e) => (e)}
                />
                <Button type='submit'>Enviar</Button>
            </Form>
        </Gerenciador>
    )
}