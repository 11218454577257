import { useContext, useEffect, useState } from "react";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import { cd, subDays } from "../../../_assets/js/global";
import Icon from "../../../components/body/icon";
import ModalComunicado from "./modalComunicado";
import { GlobalContext } from "../../../context/Global";
import ModalLidos from "./modalLidos";

export default function Comunicados({ filters, icons, chamados, fases, visitas }) {
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    //ESTADOS
    const [comunicados, setComunicados] = useState([]);
    const [nome, setNome] = useState('');
    const [lojas, setLojas] = useState('');
    const [usuarios, setUsuarios] = useState('');
    const [descricao, setDescricao] = useState('');
    const [lidos, setLidos] = useState('');
    // const [aberturaInicio, setAberturaInicio] = useState(subDays(new Date(window.currentDateWithoutHour), 60));
    // const [aberturaFim, setAberturaFim] = useState('');
    //ESTADOS MODAL COMUNICADO   
    const [show, setShow] = useState(false);
    const [idJob, setIdJob] = useState('');
    const [nameJob, setNameJob] = useState('');
    const [txtJob, setTxtJob] = useState('');
    const [fileJob, setFileJob] = useState('');
    //ESTADOS MODAL LIDOS
    const [showLidos, setShowLidos] = useState(false);
    const [loteLidos, setLoteLidos] = useState('');
    const [tituloLidos, setTituloLidos] = useState('');
    const [aplLidos, setAplLidos] = useState([]);
    //MODAL
    const handleShow = (id, job, txt, file) => (
        setShow(!show),
        setIdJob(id),
        setNameJob(job),
        setTxtJob(txt),
        setFileJob(file)
    );
    const handleClose = (e) => {
        setShow(e);
    }

    //MODAL %LIDOS%
    const handleShowLidos = (lote, titulo, apl) => {
        setShowLidos(!showLidos);
        setLoteLidos(lote);
        setTituloLidos(titulo);
        setAplLidos(apl);
    }
    const handleCloseLidos = (e) => {
        setShowLidos(e)
    }
    //FILTRA TITULO/Nome
    const handleTitulo = (e) => {
        setNome(e.target.value)
    }
    //FILTRO LOJAS
    const handleLojas = (e) => {
        setLojas(e);
    }
    //FILTRO USUÁRIOS
    const handleUsuarios = (e) => {
        setUsuarios(e);
    }
    //FILTRO DESCRIÇÃO
    const handleDescricao = (e) => {
        setDescricao(e.target.value);
    }
    //FILTRO PORCENTAGEM
    const handleLidos = (e) => {
        setLidos(e)
    }
    //LISTA ITENS
    const handleSetItems = (e) => {
        // console.log(e)
        setComunicados(e);
        // setLoading(false)
    }

    //CALCULAR PORCENTAGEM

    function porcentagem(num, total) {
        let result = (num / total) * 100;
        return `${result}%`
    }

    // -> Título - Lojas - Usuários - Descrição - %Lidos - Ações
    const thead = [
        { enabled: true, label: "Título", id: "job", name: "job", onChange: handleTitulo, },
        { enabled: true, label: "Lojas", id: "lojas", name: "lojas", onChange: handleLojas, api: window.host + "/api/sql.php?do=select&component=loja&filial=true", export: true },
        { enabled: true, label: "Usuários", id: "usuarios", name: "usuarios", onChange: handleUsuarios, api: window.host + "/api/sql.php?do=select&component=usuario", export: true },
        { enabled: true, label: "Descrição", id: "descricao", name: "descricao", onChange: handleDescricao, export: true },
        { enabled: true, label: "% Lidos", onChange: handleLidos, name: "% Lidos", filter: false, export: false }, // PASSANDO EXPORT FALSE POIS PRECISA CALCULAR A PORCENTAGEM NA EXPORTAÇÃO
        { enabled: true, label: "Ações", export: false },
    ];

    //TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    });

    // URL API TABLE
    const url = window.host_madnezz + "/systems/integration-react/api/list.php?do=get_list";

    // PARAMS API TABLE
    const params = {
        filter_type: '5',
        filter_description: descricao,
        filter_title: nome,
        filter_id_user: usuarios,
        filter_id_store: lojas,
        id_apl: 229,
        type: 'report',
        limit: 50
    };

    //BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: "Comunicados",
        filters: params,
        // key: 'data',
        orientation: 'L',
        custom: [
            {
                name: '% Lidos',
                keys: ['qtd_visualizado', 'qtd_total'],
                type: '%'
            }
        ]
    };
   

    // MANDA FILTROS PARA O COMPONENTE PAI
    useEffect(() => {
        if (filters) {
            filters(<></>);
        }

        if (icons) {
            icons(
                <Icon type="pdf" api={{ body: body }} />
            );
        }
    }, [comunicados]);
    // console.log(comunicados)
    return (
        <Row>
            <ModalComunicado
                show={show}
                handleShow={handleShow}
                onHide={handleClose}
                id={idJob}
                job={nameJob}
                text={txtJob}
                file={fileJob}
            />
            <ModalLidos
                show={showLidos}
                handleShow={handleShowLidos}
                onHide={handleCloseLidos}
                lote={loteLidos}
                apl={aplLidos}
                titulo={tituloLidos}
            />
            <Col>
                <Table
                    id="comunicados"
                    api={url}
                    params={params}
                    //key_aux_2={'data'}
                    onLoad={handleSetItems}
                    thead={thead}
                >
                    <Tbody>
                        {(
                            comunicados.length > 0 ?
                                comunicados.map((item) => {
                                    return (
                                        <Tr key={item.id_job}>
                                            <Td>
                                                {item.job}
                                            </Td>

                                            <Td title={item?.lojas}>
                                                {(item.lojas ? item.lojas.replaceAll(',', ', ') : '')}
                                            </Td>

                                            <Td title={item?.usuarios}>
                                                {(item.usuarios ? item.usuarios.replaceAll(',', ', ') : '')}
                                            </Td>

                                            <Td>
                                                {(item.descricao ? item.descricao.replace(/(<([^>]+)>)/gi, "") : '')}
                                            </Td>

                                            <Td onClick={() => handleShowLidos(item.lote, item.job, item.id_apl)} cursor="pointer">
                                                ({item?.qtd_visualizado}/{item?.qtd_total}) ({porcentagem(item?.qtd_visualizado, item?.qtd_total)})
                                            </Td>

                                            <Td align="center" width={1}>
                                                <Icon type="view" onClick={() => handleShow(item.id, item.job, item.descricao, item.anexos)} />
                                            </Td>
                                        </Tr>
                                    )
                                })
                                : <></>
                        )}
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )
}