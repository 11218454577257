import { useEffect, useState } from "react";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import ChartCustom from "../../../components/body/chart";
import Icon from "../../../components/body/icon";
import Loja from "./Loja";
import { get_date, cd } from "../../../_assets/js/global";
import SelectReact from "../../../components/body/select";

export default function Lista({ filters, icons }) {

    //ESTADOS FILTRO
    const [api, setApi] = useState([
        {
            id: 1,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'JOAO PEDRO COSTA PEREIRA DEPPA',
            mGeral: '3.937,55',
            mDiaria: '189,1',
            real: '165,9',
            realizado: '1.978,89',
            realPorcento: '50,26%',
            falta: '1.958,66',
            prMensalPorcento: '81%',
            prMensalReais: '3.214,66'
        },
        {
            id: 2,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'DANIEL WILLIAN VENTURA',
            mGeral: '1.500,00',
            mDiaria: '57,9',
            real: '67',
            realizado: '1.159,00',
            realPorcento: '77,27%',
            falta: '341',
            prMensalPorcento: '129',
            prMensalReais: '1.936,25'
        },{
            id: 3,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'JOAO PEDRO COSTA PEREIRA DEPPA',
            mGeral: '3.937,55',
            mDiaria: '189,1',
            real: '165,9',
            realizado: '1.978,89',
            realPorcento: '50,26%',
            falta: '1.958,66',
            prMensalPorcento: '81%',
            prMensalReais: '3.214,66'
        },{
            id: 4,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'DANIEL WILLIAN VENTURA',
            mGeral: '1.500,00',
            mDiaria: '57,9',
            real: '67',
            realizado: '1.159,00',
            realPorcento: '77,27%',
            falta: '341',
            prMensalPorcento: '129',
            prMensalReais: '1.936,25'
        },{
            id: 5,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'JOAO PEDRO COSTA PEREIRA DEPPA',
            mGeral: '3.937,55',
            mDiaria: '189,1',
            real: '165,9',
            realizado: '1.978,89',
            realPorcento: '50,26%',
            falta: '1.958,66',
            prMensalPorcento: '81%',
            prMensalReais: '3.214,66'
        },{
            id: 6,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'DANIEL WILLIAN VENTURA',
            mGeral: '1.500,00',
            mDiaria: '57,9',
            real: '67',
            realizado: '1.159,00',
            realPorcento: '77,27%',
            falta: '341',
            prMensalPorcento: '129',
            prMensalReais: '1.936,25'
        },{
            id: 7,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'JOAO PEDRO COSTA PEREIRA DEPPA',
            mGeral: '3.937,55',
            mDiaria: '189,1',
            real: '165,9',
            realizado: '1.978,89',
            realPorcento: '50,26%',
            falta: '1.958,66',
            prMensalPorcento: '81%',
            prMensalReais: '3.214,66'
        },{
            id: 8,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'DANIEL WILLIAN VENTURA',
            mGeral: '1.500,00',
            mDiaria: '57,9',
            real: '67',
            realizado: '1.159,00',
            realPorcento: '77,27%',
            falta: '341',
            prMensalPorcento: '129',
            prMensalReais: '1.936,25'
        },{
            id: 19,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'JOAO PEDRO COSTA PEREIRA DEPPA',
            mGeral: '3.937,55',
            mDiaria: '189,1',
            real: '165,9',
            realizado: '1.978,89',
            realPorcento: '50,26%',
            falta: '1.958,66',
            prMensalPorcento: '81%',
            prMensalReais: '3.214,66'
        },{
            id: 10,
            data: '18/out',
            loja: 'CAMPINA GRANDE (PR)',
            consultor: 'DANIEL WILLIAN VENTURA',
            mGeral: '1.500,00',
            mDiaria: '57,9',
            real: '67',
            realizado: '1.159,00',
            realPorcento: '77,27%',
            falta: '341',
            prMensalPorcento: '129',
            prMensalReais: '1.936,25'
        },
    ]);

    const [dia, setDia] = useState('');
    const [loja, setLoja] = useState('');
    const [vendedor, setVendedor] = useState('');

    // ESTADOS
    const [items, setItems] = useState([]);
    const [itemsChart, setItemsChart] = useState([]);
    const [loadingChart, setLoadingChart] = useState(true);

    // ESTADOS FILTROS       
    const [filterMonth, setFilterMonth] = useState(window.currentMonth);
    const [filterYear, setFilterYear] = useState(window.currentYear);
    const [periodStart, setPeriodStart] = useState(new Date(window.currentYear, (window.currentMonth - 1), 1));
    const [periodEnd, setPeriodEnd] = useState(new Date(window.currentYear, (window.currentMonth - 1), get_date('last_day', (window.currentYear + '-' + (window.currentMonth - 1) + '-01'), 'date_sql')));


    // OPTIONS MESES
    const optionsMonths = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' }
    ]

    // OPTIONS ANO
    var optionsYear = [];
    for (var i = 0; i < 5; i++) {
        optionsYear.push(
            { value: window.currentYear - i, label: window.currentYear - i }
        )
    }

    // FILTRO DE MÊS
    const handleFilterMonth = (e) => {
        setFilterMonth(e);
        setPeriodStart(new Date(filterYear, (e - 1), 1));
        setPeriodEnd(new Date(filterYear, (e - 1), get_date('last_day', (filterYear + '-' + e + '-01'), 'date_sql')));
    }

    // FILTRO DE ANO
    const handleFilterYear = (e) => {
        setFilterYear(e);
        setPeriodStart(new Date(e, (filterMonth - 1), 1));
        setPeriodEnd(new Date(e, (filterMonth - 1), get_date('last_day', (e + '-' + (filterMonth - 1) + '-01'), 'date_sql')));
    }



    // LOOP DE DATAS
    function dateLoop(date_start, date_end, items) {
        const di_aux = date_start.split('/');
        const df_aux = date_end.split('/');
        const data_inicio = new Date(di_aux[2], (di_aux[1] - 1), di_aux[0]);
        const data_fim = new Date(df_aux[2], (df_aux[1] - 1), df_aux[0]);

        let res = [];

        for (let day = data_inicio; day <= data_fim; day.setDate(day.getDate() + 1)) {
            const day_num = day.getDate();
            const day_format = day.toLocaleDateString('pt-BR');
            let weekday_short = day.toLocaleDateString('pt-BR', { weekday: 'short' });
            weekday_short = weekday_short.charAt(0).toUpperCase() + weekday_short.slice(1);
            let weekday_long = day.toLocaleDateString('pt-BR', { weekday: 'long' });
            weekday_long = weekday_long.charAt(0).toUpperCase() + weekday_long.slice(1);
            let realizadas = items.filter((elem) => elem.dia == day_num)[0]?.qtd_finalizado;
            let planejadas = items.filter((elem) => elem.dia == day_num)[0]?.qtd_total;

            res.push([
                day_format.slice(0, 5),
                Math.floor(Math.random(200,300) * 10000),
                Math.floor(Math.random(100,150) * 9000)
            ]);
        }

        return res;
    }

    // GET INFO GRÁFICO  --> quando tiver api
    // useEffect(() => {
    //     handleSetFilter(true);

    //     setLoadingChart(true);
    //     axios({
    //         method: 'get',
    //         url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Dashboard&do=getVisit&filter_type=year_month_day',
    //         params: {
    //             filter_date_start: get_date('date_sql', cd(periodStart), 'date'),
    //             filter_date_end: get_date('date_sql', cd(periodEnd), 'date')
    //         }
    //     }).then((response) => {
    //         setLoadingChart(false);
    //         setItemsChart(response.data.data);
    //     });
    // },[periodStart, periodEnd]);

    useEffect(() => {
        if (filters) {
            filters(
                <>
                    <SelectReact
                        options={optionsMonths}
                        placeholder="Mês"
                        name="filter_month"
                        defaultValue={''}
                        value={filterMonth}
                        onChange={(e) => handleFilterMonth(e.value)}
                    />

                    <SelectReact
                        placeholder="Ano"
                        options={optionsYear}
                        value={filterYear}
                        onChange={(e) => handleFilterYear(e.value)}
                    />
                </>
            )
        }

    }, [filterMonth, filterYear])


    return (
        <>

            <Row>
                <Col lg={12}>
                    <ChartCustom
                        title="Vendas Por Dia"
                        type="ComboChart"
                        headers={["Mês", "Venda", "Meta"]}
                        colors={['bdc3cb', '10459e', 'f2383a']}
                        height={300}
                        body={{
                            content: dateLoop(cd(periodStart), cd(periodEnd), itemsChart)
                        }}
                        series={{ 1: { type: "line", lineWidth: 2 }, 2: { type: "line", lineWidth: 0 } }}
                    // loading={loadingChart}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12} >
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>
                                    Dia
                                </Th>
                                <Th>
                                    Meta Geral
                                </Th>
                                <Th>
                                    Meta Diaria
                                </Th>
                                <Th>
                                    Real
                                </Th>
                                <Th>
                                    Realizados
                                </Th>
                                <Th>
                                    Real %
                                </Th>
                                <Th>
                                    Falta
                                </Th>
                                <Th>
                                    Pr Mensal %
                                </Th>
                                <Th>
                                    Pr Mensal R$
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(
                                api?.map((item) => {
                                    return (
                                        <>
                                            <Tr>
                                                <Td>
                                                    {item.data}  <Icon type={dia === item.id ? "minus" : "plus"} className="text-secondary" onClick={() => setDia(dia ? '' : item.id)} />
                                                </Td>
                                                <Td>
                                                    {item.mGeral}
                                                </Td>
                                                <Td>
                                                    {item.mDiaria}
                                                </Td>
                                                <Td>
                                                    {item.real}
                                                </Td>
                                                <Td>
                                                    {item.realizado}
                                                </Td>
                                                <Td>
                                                    {item.realPorcento}
                                                </Td>
                                                <Td>
                                                    {item.falta}
                                                </Td>
                                                <Td>
                                                    {item.prMensalPorcento}
                                                </Td>
                                                <Td>
                                                    {item.prMensalReais}
                                                </Td>
                                            </Tr>
                                            {(
                                                dia === item.id ?
                                                    <Loja loja={loja} loja_name={item.loja} vendedor_name={item.consultor} setLoja={setLoja} vendedor={vendedor} setVendedor={setVendedor} />
                                                : <></>
                                            )}
                                        </>
                                    )
                                })
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}