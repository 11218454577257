import { useState } from "react";
import Form from "../../../../../../components/body/form";
import Textarea from "../../../../../../components/body/form/textarea";
import Modal from "../../../../../../components/body/modal";
import ModalBody from "../../../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../../components/body/modal/modalHeader/modalTitle";
import "./style.scss"
import Button from "../../../../../../components/body/button";
import axios from "axios";

export default function ModalMotivo(props) {
    // ESTADOS
    const [motivo, setMotivo] = useState('');
    const [statusButton, setStatusButton] = useState('');

    //VARIAVEL FECHAR MODAL
    function handleCloseModal(submit){
        if(submit === false){
            if(window.confirm((props?.vistoria ? 'A vistoria ainda não foi realizada, é necessário digitar um motivo, deseja sair sem realizar?' : 'O documento ainda não foi avaliado, é necessário digitar um motivo, deseja sair sem avaliar?'))){
                if(props?.callback){
                    props?.callback(false);
                }
                props?.onHide(false);
                setTimeout(() => {
                    setMotivo('');
                },500);
            }
        }else{
            if(props?.callback){
                props?.callback(true);
            }
            if(props?.vistoria){
                props?.onHide((props?.status == 2 ? ' Reprovou' : ' Aprovou com ressalva') + ' a vistoria ' + props?.job?.subcategoria+'. Motivo: '+motivo);
            }else{
                props?.onHide((props?.status == 2 ? ' Reprovou' : ' Aprovou com ressalva') + ' o documento ' + props?.job?.subcategoria+'. Motivo: '+motivo);
            }
            setTimeout(() => {
                setMotivo('');
            },500);

            // // BUSCA ARQUIVOS ANEXADOS PELO LOJISTA
            // axios({
            //     method: 'get',
            //     url: window.host_madnezz+'/systems/integration-react/api/list.php?do=get_msg',
            //     params: {
            //         id: props?.job?.id_job_status,
            //         filter_log_ativ: 'Anexou arquivo' // NÃO MUDAR
            //     }
            // }).then((response) => {
            //     if(response.data[0].id){
            //         // DELETA OS ARQUIVOS ANEXADOS PELO LOJISTA
            //         axios({
            //             method: 'post',
            //             url: window.host_madnezz+'/systems/integration-react/api/list.php?do=set_msg_active',
            //             data: {
            //                 id_mov: props?.job?.id_job_status,
            //                 id_log: response.data[0].id,
            //                 active: 0
            //             },
            //             headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            //         }).then(() => {            
            //             // RESPONSE
            //         });  
            //     }     
            // });
        }
    }

    let msg, title_communication;
    if(props?.vistoria){
        msg = (props?.status == 2 ? ' Reprovou' : ' Aprovou com ressalva') + ' a vistoria. Motivo: '+motivo;
    }else{
        msg = (props?.status == 2 ? ' Reprovou' : ' Aprovou com ressalva') + ' o documento. Motivo: '+motivo;
    }

    if(props?.status == 1){
        if(props?.vistoria){
            title_communication = 'Vistoria aprovada';
        }else{
            title_communication = 'Documento aprovado';
        }
    }else if(props?.status == 2){
        if(props?.vistoria){
            title_communication = 'Vistoria reprovada';
        }else{
            title_communication = 'Documento reprovado';
        }
    }else if(props?.status == 4){
        if(props?.vistoria){
            title_communication = 'Vistoria aprovada com ressalva';
        }else{
            title_communication = 'Documento aprovado com ressalva';
        }
    }

    const data = {
        ativ_desc: (props?.vistoria ? 'Realizou uma vistoria' : 'Avaliou um documento'),
        id_job: props?.job?.id_job,
        id_mov: props?.job?.id_job_status,
        status: props?.status,
        msg: msg,
        data_aux: '',
        tipo: '',
        tipo_fase: '',
        title_communication: title_communication,
        msg_title_communication: props?.job?.subcategoria,
        msg_msg_communication: motivo,
        reopen: (props?.status == 2 ? true : false)
    }

    let modalTitle;
    if(props?.status == 2){
        modalTitle = 'Reprovar';
    }else if(props?.status == (props?.vistoria ? 5 : 4)){
        modalTitle = 'Aprovar com ressalva';
    }

    // STATUS BOTÃO FORM
    const handleSetButtonState = (e) => {
        setStatusButton(e);
    }

    return (
        <>
            <Modal show={props?.show} handleShow={props?.handleShow} onHide={() => handleCloseModal(false)}>
                <ModalHeader>
                    <ModalTitle>
                        {modalTitle} - <b>{props?.job?.subcategoria}</b>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host_madnezz+"/systems/integration-react/api/list.php?do="+(props?.vistoria ? 'set_status' : 'set_statusSupervisor')}
                        data={data}
                        toast={(props?.vistoria ? 'Vistoria realizada com sucesso' : 'Documento avaliado com sucesso')}
                        callback={() => handleCloseModal(true)}
                        status={handleSetButtonState}
                    >
                        <Textarea
                            name="motivo"
                            type="text"
                            label="Motivo"
                            value={motivo}
                            onChange={(e) => setMotivo(e.target.value)}
                        />
                        
                        <Button
                            type="submit"
                            status={statusButton}
                        >
                            Enviar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}