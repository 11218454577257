import { useContext, useState, useEffect } from "react";
import { addDays, cd, get_date, subDays } from "../../../_assets/js/global";
import { JobsContext } from "../../../context/Jobs";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Card from "../Grafico/components/Card";
import Icon from "../../../components/body/icon";
import Chart from '../../../components/body/chart';
import FilterCheckbox from "../../../components/body/filterCheckbox";
import SelectReact from "../../../components/body/select";
import axios from "axios";
import Input from "../../../components/body/form/input";

export default function Grafico2({ icons, filters }) {

    // CONTEXT JOBS
    const { optionsEmpreendimentos, handleSetFilterEmpreendimento, filterEmpreendimento, optionsUsuario } = useContext(JobsContext);

    // -> HOJE
    var date = new Date();

    // ESTADOS
    const [loja, setLoja] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [dataInicial, setDataInicial] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [dataFinal, setDataFinal] = useState(new Date());
    const [filterMonth, setFilterMonth] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    const [filterYear, setFilterYear] = useState(window.currentYear);

    //ESTADOS CARDS
    const [cardJobs, setCardJobs] = useState('');
    const [cardChamados, setCardChamados] = useState('');
    const [cardComunicados, setCardComunicados] = useState('');
    const [cardChecklist, setCardChecklist] = useState('');

    // ESTADOS DOS ÚLTIMOS 6 MESES
    const [monthJobs, setMonthJobs] = useState([]);
    const [monthChamados, setMonthChamados] = useState([]);
    const [monthComunicados, setMonthComunicados] = useState([]);
    const [monthChecklist, setMonthChecklist] = useState([]);
    const [monthUsuarios, setMonthUsuarios] = useState([]);
    const [loadingMonth, setLoadingMonth] = useState(true);

    // FILTRO DE ANO
    const handleMes = (e) => {
        setFilterMonth(e);
        setDataInicial(get_date('date_sql', cd(e)));
        setDataFinal(get_date('date_sql', cd(e), 'date_add_day', 28));
    }

    //FUNÇÃO QUE PEGA INFO DOS CARDS, RECEBE POR PARAMETRO O TIPO E SETSTATE
    function fetchCards(type, filter, setState) {
        axios.get(`${window.host}/systems/integration-react/api/request.php`, {
            params: {
                do: type,
                type: 'Dashboard',
                filter_type: filter,
                filter_date_start: dataInicial ? dataInicial : '',
                filter_date_end: dataFinal ? dataFinal : ''
            }
        }).then((response) => {
            setState(response?.data?.data[0]);
        }).catch((error) => {
            console.log("ERRO => ", error.message);
        });
    }
    //FUNÇÃO QUE PEGA INFO DOS CARDS, RECEBE POR PARAMETRO O TIPO E SETSTATE
    function fetchCharts(type, filter, setState) {
        axios.get(`${window.host}/systems/integration-react/api/request.php`, {
            params: {
                do: type,
                type: 'Dashboard',
                filter_type: filter,
                filter_date_start: dataInicial ? dataInicial : '',
                filter_date_end: dataFinal ? dataFinal : ''
            }
        }).then((response) => {
            const sortedData = response?.data?.data.sort((a, b) => a.dia - b.dia);
            setState(sortedData);
            setLoadingMonth(false)
        }).catch((error) => {
            console.log("ERRO => ", error.message);
        });
    }
    //CHAMAR A FUNÇÃO AO INICIAR A TELA
    useEffect(() => {
        fetchCards('getJobs', 'year_month', setCardJobs);
        fetchCards('getChamados', 'year_month', setCardChamados);
        fetchCards('getChecklist', 'year_month', setCardChecklist);
        fetchCards('getCommunication', 'year_month', setCardComunicados);
        fetchCharts('getJobs', 'day', setMonthJobs);
    }, [filterMonth]);

    // GET ÚLTIMOS 6 MESES
    function get_months(system) {
        setLoadingMonth(true);

        let do_aux;

        if (system === 'jobs') {
            do_aux = 'getJob';
        } else if (system === 'chamados') {
            do_aux = 'getCalled';
        } else if (system === 'comunicados') {
            do_aux = 'getCommunication';
        } else if (system === 'checklist') {
            do_aux = 'getChecklist';
        } else if (system === 'usuarios') {
            do_aux = 'getUserAdmOnline';
        }

        const endDate = new Date(dataInicial);

        const endOfMonth = new Date(
            endDate.getUTCFullYear(),
            endDate.getUTCMonth() + 1,
            0
        );
        // axios({
        //     url: window.host_madnezz + '/systems/integration-react/api/request.php?type=Dashboard',
        //     params: {
        //         do: do_aux,
        //         filter_type: 'year_month',
        //         filter_date_start: (dataInicial ? get_date('date_sql', cd(dataInicial), 'date_sub_month', 5) : ''),
        //         filter_date_end: (dataFinal ? get_date('date_sql', cd(dataFinal)) : ''),
        //         filter_id_emp: filterEmpreendimento,
        //         filter_supervisor: supervisor,
        //         filter_id_store: loja,
        //         filter_id_user: usuario,
        //     }
        // }).then((response) => {
        //     if (response?.data?.data[0]) {
        //         if (system === 'jobs') {
        //             //  setMonthJobs(response.data.data);
        //         } else
        //             if (system === 'chamados') {
        //                 setMonthChamados(response.data.data);
        //             } else if (system === 'comunicados') {
        //                 setMonthComunicados(response.data.data);
        //             } else if (system === 'checklist') {
        //                 setMonthChecklist(response.data.data);
        //             } else if (system === 'usuarios') {
        //                 setMonthUsuarios(response?.data?.data);
        //             }
        //     }
        //     setLoadingMonth(false);
        // });
    }


    useEffect(() => {
        if (icons) {
            icons(<Icon type="print" />)
        }
        if (filters) {
            filters(
                <>
                    <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={filterMonth}
                        onChange={(e) => handleMes(e)}
                    />
                </>
            );
        }
    }, [filterEmpreendimento, optionsEmpreendimentos, filterMonth, filterYear, supervisor, loja]);

    function porcentagem(num, total) {
        let result = (num / total) * 100;
        // Verifica se o resultado é NaN
        if (isNaN(result)) {
            return '0%';
        }
        return `${result.toFixed(0)}%`;
    }

    return (
        <>
            <Row>
                {/* JOBS */}
                <Col lg={3} >
                    <Card
                        title={'Jobs'}
                        background={'#2cb59c'}
                        icon={<Icon type={'check'} />}
                        info={[
                            { label: ' Adesão Jobs', number: porcentagem(cardJobs?.qtd_feito, cardJobs?.qtd_total) },
                            { label: ' Atrasados', number: cardJobs?.qtd_atrasado },
                            { label: ' Não tem', number: cardJobs?.qtd_nao_tem }
                        ]}
                    />
                </Col>
                {/* CHAMADOS */}
                <Col lg={3} >
                    <Card
                        title={'Chamados'}
                        background={'#10459e'}
                        icon={<Icon type={'check'} />}
                        info={[
                            { label: ' Adesão Chamados', number: porcentagem(cardChamados?.qtd_finalizado, cardChamados?.qtd_total) },
                            { label: ' Em fila', number: cardChamados?.qtd_pendente }
                        ]}
                    />
                </Col>
                {/* CHECKLIST */}
                <Col lg={3} >
                    <Card
                        title={'Checklist'}
                        background={'#ff772a'}
                        icon={<Icon type={'check'} />}
                        info={[
                            { label: 'Adesão Checklist', number: porcentagem(cardChecklist?.qtd_aprovado, cardChecklist.qtd_total) },
                            { label: 'Atrasados', number: (cardChecklist?.qtd_total - cardChecklist?.qtd_aprovado) },
                            { label: 'Tempo médio de resposta', number: cardChecklist?.media_leitura },
                        ]}
                    />
                </Col>
                {/* COMUNICADOS */}
                <Col lg={3} >
                    <Card
                        title={'Comunicados'}
                        background={'#714ae1'}
                        icon={<Icon type={'check'} />}
                        info={[
                            { label: 'Total de Comunicados', number: cardComunicados?.qtd_total },
                            { label: 'Atrasados', number: cardComunicados?.qtd_atrasados },
                            { label: 'Média de leitura', number: porcentagem(cardComunicados?.qtd_total, cardComunicados?.qtd_lido) }
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                {/* média mensal - checklist */}
                <Col lg={4} >
                    <Chart
                        id="teste1"
                        title="Média Mensal - Checklist"
                        type="ComboChart"
                        // headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                        headers={['Dias', 'Notas do Checklist', 'Média de Inconformidades']}
                        body={{
                            type: "custom",
                            content: [
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 21), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 14), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 7), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 0), 'date_sql'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                // [('Média'), (monthChecklist[0]?.nota_checklist ? monthChecklist[0]?.nota_checklist : 0), (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],

                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 21), 'date_sql'), 64, 85],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 14), 'date_sql'), 80, 95],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 7), 'date_sql'), 30, 75],
                                [get_date('day', get_date('date_sql', cd(date), 'date_sub_day', 0), 'date_sql'), 58, 80],
                                [('Média'), 55, 85],

                            ]
                        }}
                        colors={['#0396d8', '#ff772a']}
                        //height={(window.isMobile ? 400 : 553)}
                        height={(window.isMobile ? 400 : 570)}
                    // loading={loadingMonth}
                    />
                </Col>
                {/* evolução diária jobs */}
                <Col lg={8} >
                    <Chart
                        id="teste2"
                        title="Evolução diária - Jobs"
                        type="LineChart"
                        curveType={false}
                        headers={['Mês', 'Concluídos', 'Concluídos com atraso', 'Atrasados', 'Não tem']}
                        body={{
                            type: "custom",
                            content: [
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 31), 'date_sql'), (monthJobs[0]?.qtd_feito ? monthJobs[0]?.qtd_feito : 0), (monthJobs[0]?.qtd_feito_com_atraso ? monthJobs[0]?.qtd_feito_com_atraso : 0), (monthJobs[0]?.qtd_atrasado ? monthJobs[0]?.qtd_atrasado : 0), (monthJobs[0]?.qtd_nao_tem ? monthJobs[0]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 30), 'date_sql'), (monthJobs[1]?.qtd_feito ? monthJobs[1]?.qtd_feito : 0), (monthJobs[1]?.qtd_feito_com_atraso ? monthJobs[1]?.qtd_feito_com_atraso : 0), (monthJobs[1]?.qtd_atrasado ? monthJobs[1]?.qtd_atrasado : 0), (monthJobs[1]?.qtd_nao_tem ? monthJobs[1]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 29), 'date_sql'), (monthJobs[2]?.qtd_feito ? monthJobs[2]?.qtd_feito : 0), (monthJobs[2]?.qtd_feito_com_atraso ? monthJobs[2]?.qtd_feito_com_atraso : 0), (monthJobs[2]?.qtd_atrasado ? monthJobs[2]?.qtd_atrasado : 0), (monthJobs[2]?.qtd_nao_tem ? monthJobs[2]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 28), 'date_sql'), (monthJobs[3]?.qtd_feito ? monthJobs[3]?.qtd_feito : 0), (monthJobs[3]?.qtd_feito_com_atraso ? monthJobs[3]?.qtd_feito_com_atraso : 0), (monthJobs[3]?.qtd_atrasado ? monthJobs[3]?.qtd_atrasado : 0), (monthJobs[3]?.qtd_nao_tem ? monthJobs[3]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 27), 'date_sql'), (monthJobs[4]?.qtd_feito ? monthJobs[4]?.qtd_feito : 0), (monthJobs[4]?.qtd_feito_com_atraso ? monthJobs[4]?.qtd_feito_com_atraso : 0), (monthJobs[4]?.qtd_atrasado ? monthJobs[4]?.qtd_atrasado : 0), (monthJobs[4]?.qtd_nao_tem ? monthJobs[4]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 26), 'date_sql'), (monthJobs[5]?.qtd_feito ? monthJobs[5]?.qtd_feito : 0), (monthJobs[5]?.qtd_feito_com_atraso ? monthJobs[5]?.qtd_feito_com_atraso : 0), (monthJobs[5]?.qtd_atrasado ? monthJobs[5]?.qtd_atrasado : 0), (monthJobs[5]?.qtd_nao_tem ? monthJobs[5]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 25), 'date_sql'), (monthJobs[6]?.qtd_feito ? monthJobs[6]?.qtd_feito : 0), (monthJobs[6]?.qtd_feito_com_atraso ? monthJobs[6]?.qtd_feito_com_atraso : 0), (monthJobs[6]?.qtd_atrasado ? monthJobs[6]?.qtd_atrasado : 0), (monthJobs[6]?.qtd_nao_tem ? monthJobs[6]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 24), 'date_sql'), (monthJobs[7]?.qtd_feito ? monthJobs[7]?.qtd_feito : 0), (monthJobs[7]?.qtd_feito_com_atraso ? monthJobs[7]?.qtd_feito_com_atraso : 0), (monthJobs[7]?.qtd_atrasado ? monthJobs[7]?.qtd_atrasado : 0), (monthJobs[7]?.qtd_nao_tem ? monthJobs[7]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 23), 'date_sql'), (monthJobs[8]?.qtd_feito ? monthJobs[8]?.qtd_feito : 0), (monthJobs[8]?.qtd_feito_com_atraso ? monthJobs[8]?.qtd_feito_com_atraso : 0), (monthJobs[8]?.qtd_atrasado ? monthJobs[8]?.qtd_atrasado : 0), (monthJobs[8]?.qtd_nao_tem ? monthJobs[8]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 22), 'date_sql'), (monthJobs[9]?.qtd_feito ? monthJobs[9]?.qtd_feito : 0), (monthJobs[9]?.qtd_feito_com_atraso ? monthJobs[9]?.qtd_feito_com_atraso : 0), (monthJobs[9]?.qtd_atrasado ? monthJobs[9]?.qtd_atrasado : 0), (monthJobs[9]?.qtd_nao_tem ? monthJobs[9]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 21), 'date_sql'), (monthJobs[10]?.qtd_feito ? monthJobs[10]?.qtd_feito : 0), (monthJobs[10]?.qtd_feito_com_atraso ? monthJobs[10]?.qtd_feito_com_atraso : 0), (monthJobs[10]?.qtd_atrasado ? monthJobs[10]?.qtd_atrasado : 0), (monthJobs[10]?.qtd_nao_tem ? monthJobs[10]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 20), 'date_sql'), (monthJobs[11]?.qtd_feito ? monthJobs[11]?.qtd_feito : 0), (monthJobs[11]?.qtd_feito_com_atraso ? monthJobs[11]?.qtd_feito_com_atraso : 0), (monthJobs[11]?.qtd_atrasado ? monthJobs[11]?.qtd_atrasado : 0), (monthJobs[11]?.qtd_nao_tem ? monthJobs[11]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 19), 'date_sql'), (monthJobs[12]?.qtd_feito ? monthJobs[12]?.qtd_feito : 0), (monthJobs[12]?.qtd_feito_com_atraso ? monthJobs[12]?.qtd_feito_com_atraso : 0), (monthJobs[12]?.qtd_atrasado ? monthJobs[12]?.qtd_atrasado : 0), (monthJobs[12]?.qtd_nao_tem ? monthJobs[12]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 18), 'date_sql'), (monthJobs[13]?.qtd_feito ? monthJobs[13]?.qtd_feito : 0), (monthJobs[13]?.qtd_feito_com_atraso ? monthJobs[13]?.qtd_feito_com_atraso : 0), (monthJobs[13]?.qtd_atrasado ? monthJobs[13]?.qtd_atrasado : 0), (monthJobs[13]?.qtd_nao_tem ? monthJobs[13]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 17), 'date_sql'), (monthJobs[14]?.qtd_feito ? monthJobs[14]?.qtd_feito : 0), (monthJobs[14]?.qtd_feito_com_atraso ? monthJobs[14]?.qtd_feito_com_atraso : 0), (monthJobs[14]?.qtd_atrasado ? monthJobs[14]?.qtd_atrasado : 0), (monthJobs[14]?.qtd_nao_tem ? monthJobs[14]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 16), 'date_sql'), (monthJobs[15]?.qtd_feito ? monthJobs[15]?.qtd_feito : 0), (monthJobs[15]?.qtd_feito_com_atraso ? monthJobs[15]?.qtd_feito_com_atraso : 0), (monthJobs[15]?.qtd_atrasado ? monthJobs[15]?.qtd_atrasado : 0), (monthJobs[15]?.qtd_nao_tem ? monthJobs[15]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 15), 'date_sql'), (monthJobs[16]?.qtd_feito ? monthJobs[16]?.qtd_feito : 0), (monthJobs[16]?.qtd_feito_com_atraso ? monthJobs[16]?.qtd_feito_com_atraso : 0), (monthJobs[16]?.qtd_atrasado ? monthJobs[16]?.qtd_atrasado : 0), (monthJobs[16]?.qtd_nao_tem ? monthJobs[16]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 14), 'date_sql'), (monthJobs[17]?.qtd_feito ? monthJobs[17]?.qtd_feito : 0), (monthJobs[17]?.qtd_feito_com_atraso ? monthJobs[17]?.qtd_feito_com_atraso : 0), (monthJobs[17]?.qtd_atrasado ? monthJobs[17]?.qtd_atrasado : 0), (monthJobs[17]?.qtd_nao_tem ? monthJobs[17]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 13), 'date_sql'), (monthJobs[18]?.qtd_feito ? monthJobs[18]?.qtd_feito : 0), (monthJobs[18]?.qtd_feito_com_atraso ? monthJobs[18]?.qtd_feito_com_atraso : 0), (monthJobs[18]?.qtd_atrasado ? monthJobs[18]?.qtd_atrasado : 0), (monthJobs[18]?.qtd_nao_tem ? monthJobs[18]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 12), 'date_sql'), (monthJobs[19]?.qtd_feito ? monthJobs[19]?.qtd_feito : 0), (monthJobs[19]?.qtd_feito_com_atraso ? monthJobs[19]?.qtd_feito_com_atraso : 0), (monthJobs[19]?.qtd_atrasado ? monthJobs[19]?.qtd_atrasado : 0), (monthJobs[19]?.qtd_nao_tem ? monthJobs[19]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 11), 'date_sql'), (monthJobs[20]?.qtd_feito ? monthJobs[20]?.qtd_feito : 0), (monthJobs[20]?.qtd_feito_com_atraso ? monthJobs[20]?.qtd_feito_com_atraso : 0), (monthJobs[20]?.qtd_atrasado ? monthJobs[20]?.qtd_atrasado : 0), (monthJobs[20]?.qtd_nao_tem ? monthJobs[20]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 10), 'date_sql'), (monthJobs[21]?.qtd_feito ? monthJobs[21]?.qtd_feito : 0), (monthJobs[21]?.qtd_feito_com_atraso ? monthJobs[21]?.qtd_feito_com_atraso : 0), (monthJobs[21]?.qtd_atrasado ? monthJobs[21]?.qtd_atrasado : 0), (monthJobs[21]?.qtd_nao_tem ? monthJobs[21]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 9), 'date_sql'), (monthJobs[22]?.qtd_feito ? monthJobs[22]?.qtd_feito : 0), (monthJobs[22]?.qtd_feito_com_atraso ? monthJobs[22]?.qtd_feito_com_atraso : 0), (monthJobs[22]?.qtd_atrasado ? monthJobs[22]?.qtd_atrasado : 0), (monthJobs[22]?.qtd_nao_tem ? monthJobs[22]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 8), 'date_sql'), (monthJobs[23]?.qtd_feito ? monthJobs[23]?.qtd_feito : 0), (monthJobs[23]?.qtd_feito_com_atraso ? monthJobs[23]?.qtd_feito_com_atraso : 0), (monthJobs[23]?.qtd_atrasado ? monthJobs[23]?.qtd_atrasado : 0), (monthJobs[23]?.qtd_nao_tem ? monthJobs[23]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 7), 'date_sql'), (monthJobs[24]?.qtd_feito ? monthJobs[24]?.qtd_feito : 0), (monthJobs[24]?.qtd_feito_com_atraso ? monthJobs[24]?.qtd_feito_com_atraso : 0), (monthJobs[24]?.qtd_atrasado ? monthJobs[24]?.qtd_atrasado : 0), (monthJobs[24]?.qtd_nao_tem ? monthJobs[24]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 6), 'date_sql'), (monthJobs[25]?.qtd_feito ? monthJobs[25]?.qtd_feito : 0), (monthJobs[25]?.qtd_feito_com_atraso ? monthJobs[25]?.qtd_feito_com_atraso : 0), (monthJobs[25]?.qtd_atrasado ? monthJobs[25]?.qtd_atrasado : 0), (monthJobs[25]?.qtd_nao_tem ? monthJobs[25]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 5), 'date_sql'), (monthJobs[26]?.qtd_feito ? monthJobs[26]?.qtd_feito : 0), (monthJobs[26]?.qtd_feito_com_atraso ? monthJobs[26]?.qtd_feito_com_atraso : 0), (monthJobs[26]?.qtd_atrasado ? monthJobs[26]?.qtd_atrasado : 0), (monthJobs[26]?.qtd_nao_tem ? monthJobs[26]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 4), 'date_sql'), (monthJobs[27]?.qtd_feito ? monthJobs[27]?.qtd_feito : 0), (monthJobs[27]?.qtd_feito_com_atraso ? monthJobs[27]?.qtd_feito_com_atraso : 0), (monthJobs[27]?.qtd_atrasado ? monthJobs[27]?.qtd_atrasado : 0), (monthJobs[27]?.qtd_nao_tem ? monthJobs[27]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 3), 'date_sql'), (monthJobs[28]?.qtd_feito ? monthJobs[28]?.qtd_feito : 0), (monthJobs[28]?.qtd_feito_com_atraso ? monthJobs[28]?.qtd_feito_com_atraso : 0), (monthJobs[28]?.qtd_atrasado ? monthJobs[28]?.qtd_atrasado : 0), (monthJobs[28]?.qtd_nao_tem ? monthJobs[28]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 2), 'date_sql'), (monthJobs[29]?.qtd_feito ? monthJobs[29]?.qtd_feito : 0), (monthJobs[29]?.qtd_feito_com_atraso ? monthJobs[29]?.qtd_feito_com_atraso : 0), (monthJobs[29]?.qtd_atrasado ? monthJobs[29]?.qtd_atrasado : 0), (monthJobs[29]?.qtd_nao_tem ? monthJobs[29]?.qtd_nao_tem : 0)],
                                [get_date('day', get_date('date_sql', cd(dataInicial), 'date_sub_day', 1), 'date_sql'), (monthJobs[30]?.qtd_feito ? monthJobs[30]?.qtd_feito : 0), (monthJobs[30]?.qtd_feito_com_atraso ? monthJobs[30]?.qtd_feito_com_atraso : 0), (monthJobs[30]?.qtd_atrasado ? monthJobs[30]?.qtd_atrasado : 0), (monthJobs[30]?.qtd_nao_tem ? monthJobs[30]?.qtd_nao_tem : 0)],
                            ]
                        }}
                        height={(window.isMobile ? 400 : 570)}
                        className="flex-1"
                        loading={loadingMonth}
                    />
                </Col>

                <Row>
                    {/* principais inconformidades - checklist  */}
                    <Col lg={6} >
                        <Chart
                            id="teste1"
                            title="Principais inconformidades - Checklist"
                            type="ComboChart"
                            // headers={['Mês', 'Jobs no prazo', 'Chamados abertos', 'Comunicados enviados', 'Checklists conformes', 'Usuários ativos']}
                            headers={['Título', '']}
                            body={{
                                type: "custom",
                                content: [
                                    // ['Livro', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Câmeras', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Antenas', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Panfletos', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    // ['Atendimento', (monthChecklist[0]?.media ? monthChecklist[0]?.media : 0)],
                                    ['Livro de ponto', 5],
                                    ['Câmeras de segurança', 14],
                                    ['Antenas de segurança', 33],
                                    ['Panfletos no expositor', 7],
                                    ['Atendimentos perfumistas', 12],
                                    ['Agendamento de treinamentos', 26],
                                    ['Limpeza da loja', 27]
                                ]
                            }}
                            //height={(window.isMobile ? 400 : 553)}
                            colors={['#db2f30']}
                            height={450}
                        // loading={loadingMonth}
                        />
                    </Col>
                    {/* adesão jobs */}
                    <Col lg={3}>
                        <Chart
                            enabled={true}
                            title="Adesão Jobs"
                            subtitle="Regional"
                            type='PieChart'
                            headers={['Titulo', 'Valor']}
                            body={{
                                type: "custom",
                                content: [['Concluídos', cardJobs?.qtd_feito],
                                ['Concluídos com atraso', cardJobs?.qtd_feito_com_atraso],
                                ['Atrasados', cardJobs?.qtd_atrasado],
                                ['Não tem', cardJobs?.qtd_nao_tem]]
                            }}
                            colors={['#10459e', '#f4c008', '#f2383a', '#bdc3cb']}
                        />
                    </Col>
                    {/* adesão visitas */}
                    <Col lg={3}>
                        <Chart
                            enabled={true}
                            title="Adesão Visitas"
                            subtitle="Regional"
                            type='PieChart'
                            headers={['Titulo', 'Valor']}
                            body={{
                                type: "custom",
                                content: [['Concluídos', cardJobs?.qtd_feito],
                                ['Concluídos com atraso', cardJobs?.qtd_feito_com_atraso],
                                ['Atrasados', cardJobs?.qtd_atrasado],
                                ['Não tem', cardJobs?.qtd_nao_tem]]
                            }}
                            colors={['#10459e', '#f4c008', '#f2383a', '#bdc3cb']}
                        />
                    </Col>
                </Row>
            </Row>
        </>
    )
}