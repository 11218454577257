import { useEffect, useState, useContext } from 'react';

import { GlobalContext } from "../../../../context/Global";
import { JobsContext } from '../../../../context/Jobs';
import axios from 'axios';
import { cd, cdh, subDays } from '../../../../_assets/js/global';
import Row from '../../../../components/body/row';
import Col from '../../../../components/body/col';
import Table from '../../../../components/body/table';
import Tr from '../../../../components/body/table/tr';
import Tbody from '../../../../components/body/table/tbody';
import Td from '../../../../components/body/table/tbody/td';
import Icon from '../../../../components/body/icon';
import PageError from '../../../../components/body/pageError';
import ModalListaJob from './modal';
import { calcDistance } from '../../../../_assets/js/global';
import Tippy from '@tippyjs/react';

export default function Lista(props) {
    // GLOBAL CONTEXT
    const { handleSetFilter, loadingCalendar, handleSetFixFilterModule, handleSetFirstLoad, handleSetFilterModule, filterModule, handleRefreshChat } = useContext(GlobalContext);

    // JOBS CONTEXT
    const { optionsLoja, filterEmpreendimento, optionsSystems } = useContext(JobsContext);

    // VARIÁVEIS
    var fase;
    var messageFiles = [];
    var anexosValues = [];

    // FILTROS
    global.filters = '';

    // ESTADOS
    const [jobs, setJobs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [idJobStatus, setIdJobStatus] = useState('');
    const [messages, setMessages] = useState([]);
    const [searchMessages, setSearchMessages] = useState(false);
    const [anexos, setAnexos] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState('');
    const [modalFiles, setModalFiles] = useState('');
    const [modalSistema, setModalSistema] = useState('');
    // checklist
    const [modalMov, setModalMov] = useState('');
    const [modalData, setModalData] = useState('');
    const [modalDadoAux, setModalDadoAux] = useState('');
    const [modalStatus, setModalStatus] = useState('');
    var integration_type = undefined
    var integration_type_1 = undefined

    if (modalMov?.length > 0) {
        var aux = JSON.parse(modalMov)
        integration_type = aux.job_system_integration_type
        integration_type_1 = aux.job_system_integration_type_1
    }

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
        loadingCalendar(true);
        handleSetFixFilterModule(false);
        handleSetFirstLoad(true);
    }, []);

    // CHECKBOXS DE FASES
    const [optionsPhases, setOptionsPhases] = useState([]);

    // VALUE FILTROS
    const [status, setStatus] = useState([]);
    const [empreendimento, setEmpreendimento] = useState([]);
    const [cliente, setCliente] = useState([]);
    const [loja, setLoja] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [loja_cad, setLojaCad] = useState([]);
    const [usuario_cad, setUsuarioCad] = useState([]);
    const [protocolo, setProtocolo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [grupo, setGrupo] = useState('');
    const [integration, setIntegration] = useState([]);
    const [modulo, setModulo] = useState([]);
    const [aberto, setAberto] = useState('');
    const [descricao, setDescricao] = useState('');
    const [aberturaInicio, setAberturaInicio] = useState(subDays(new Date(window.currentDateWithoutHour), 60));
    const [aberturaFim, setAberturaFim] = useState('');
    const [finalizacaoInicio, setFinalizacaoInicio] = useState('');
    const [finalizacaoFim, setFinalizacaoFim] = useState('');
    const [sistemas, setSistemas] = useState('');
    const [frequencias, setFrequencias] = useState('');
    const [horalimite, setHoraLimite] = useState('');
    const [categorias, setCategorias] = useState('');
    const [subcategorias, setSubcategorias] = useState('');
    const [avaliacao, setAvaliacao] = useState('');
    const [fases, setFases] = useState([]);
    const [fasesAux, setFasesAux] = useState([]);
    const [optionsModule, setOptionsModule] = useState([]);
    const [pageError, setPageError] = useState(false);
    const [statusSupervisor, setStatusSupervisor] = useState('');
    const [urgente, setUrgente] = useState([]);
    const [filterChange, setFilterChange] = useState();

    // PEGA ID DO MÓDULO
    useEffect(() => {
        if (optionsModule == 0 && (props.chamados || props.fases || props.visitas)) {
            axios.get(window.host_madnezz + "/systems/integration-react/api/list.php?do=get_module").then((response) => {
                if (response.data.length > 0) {
                    setOptionsModule(response.data);
                    if (props.visitas) {
                        handleSetFilterModule(global.modulo.visitas);
                    } else {
                        if (window.rs_sistema_id != 238) { // SE O SISTEMA ID FOR DIFERENTE DO "CHAMADOS EMPRESA REACT" TROCA
                            handleSetFilterModule((response.data[0] ? response.data[0].value : 0)); // SELECIONA O PRIMEIRO (CASO TENHA) COMO PADRÃO AO CARREGAR A PÁGINA
                        } else { // SE O SISTEMA ID FOR DO "CHAMADOS EMPRESA REACT" CRAVA O ID DO MÓDULO
                            handleSetFilterModule(2361);
                        }
                    }
                } else {
                    setPageError(true);
                }
            });
        }
    }, []);

    // FILTRO DE PROTOCOLO
    function handleProtocolo(e) {
        setProtocolo(e.target.value);
    }

    // FILTRO DE TÍTULO
    function handleTitulo(e) {
        setTitulo(e.target.value);
    }

    // FILTRO DE GRUPO
    function handleGrupo(e) {
        setGrupo(e.target.value);
    }

    // FILTRO DE DIAS DE ABERTURA
    function handleAberto(e) {
        setAberto(e.target.value);
    }

    // FILTRO DE DESCRIÇÃO
    function handleDescricao(e) {
        setDescricao(e.target.value);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX EMPREENDIMENTO
    function handleEmpreendimentos(e) {
        setEmpreendimento(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX CLIENTE
    function handleClientes(e) {
        setCliente(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX STATUS
    function handleStatus(e) {
        setStatus(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX LOJA
    function handleLojas(e) {
        setLoja(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX USUÁRIO
    function handleUsuarios(e) {
        setUsuario(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX LOJA CADASTRO
    function handleLojasCad(e) {
        setLojaCad(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX USUÁRIO CADASTRO
    function handleUsuariosCad(e) {
        setUsuarioCad(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX MÓDULO
    function handleModulos(e) {
        setModulo(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX INTEGRAÇÕES
    function handleIntegrations(e) {
        setIntegration(e);
    }

    // SETA DATA INÍCIO DA ABERTURA
    const handleSetAberturaInicio = (e) => {
        setAberturaInicio(e);
        handleSetFilter(true);
    }

    // SETA DATA FIM DA ABERTURA
    const handleSetAberturaFim = (e) => {
        setAberturaFim(e);
        handleSetFilter(true);
    }

    // SETA DATA INÍCIO DA FINALIZAÇÃO
    const handleSetFinalizacaoInicio = (e) => {
        setFinalizacaoInicio(e);
        handleSetFilter(true);
    }

    // SETA DATA FIM DA FINALIZAÇÃO
    const handleSetFinalizacaoFim = (e) => {
        setFinalizacaoFim(e);
        handleSetFilter(true);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX FREQUÊNCIA
    function handleCheckSistema(e) {
        setSistemas(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX FREQUÊNCIA
    function handleCheckFrequencia(e) {
        setFrequencias(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX HORA LIMITE
    function handleCheckHourLimit(e) {
        setHoraLimite(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX CATEGORIA
    const handleCheckCategoria = (e) => {
        setCategorias(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX FASES
    const handleCheckFase = (e) => {
        if (e.includes('6')) {
            setStatus([6]);
        }

        if (e.includes('-1')) {
            let fases_aux = fases;
            setFasesAux(fases_aux.push(optionsPhases.filter((elem) => elem.label === 'Pós-venda')[0].value.toString()));
        } else {
            setFases(fases.filter((elem) => elem != optionsPhases.filter((elem) => elem.label === 'Pós-venda')[0].value));
            setFasesAux([]);
        }

        setFases(e.filter((elem) => (elem !== '6' && elem !== '-1')));
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX SUBCATEGORIA
    const handleCheckSubcategoria = (e) => {
        setSubcategorias(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX AVALIAÇÃO
    const handleCheckAvaliacao = (e) => {
        setAvaliacao(e);
    }

    // FUNÇÃO DE EXECUÇÃO DO CHECKBOX STATUS SUPERVISOR
    const handleCheckStatusSupervisor = (e) => {
        setStatusSupervisor(e);
    }


    // FILTRO DE EXECUÇÃO DO CHECKBOX URGENTE
    const handleSetUrgente = (e) => {
        setUrgente(e);
    }

    // FILTRO DE EXECUÇÃO DO CHECKBOX URGENTE
    const handleSetChange = (e) => {
        setFilterChange(e);
    }
    // LISTA ITENS
    const handleSetItems = (e) => {
        setJobs(e);
    }

    // AÇÕES AO ABRIR MODAL
    const handleShowModal = (id_job_status, title, description, files, sistema, mov, data, aux, status) => {
        setShowModal(true);
        setIdJobStatus(id_job_status);
        setModalTitle(title);
        setModalDescription(description);
        setAnexos((files ? files : []));
        getMessage(id_job_status);
        setModalSistema(sistema);
        setModalMov(mov);
        setModalData(data);
        setModalDadoAux(aux);
        setModalStatus(status);
    }

    // AÇÕES AO FECHAR MODAL
    const handleCloseModal = () => {
        setShowModal(false);
        setModalTitle('');
        setModalDescription('');
    }

    // GET PARA MONTAR FILTRO DE FASES
    useEffect(() => {
        axios({
            url: window.host_madnezz + '/systems/integration-react/api/list.php?do=get_phaseType'
        }).then((response) => {
            if (response.data) {
                response.data.push({ value: '6', label: 'Cancelado' });
                // response.data.push({value: '-1', label: 'Finalizado'});

                setOptionsPhases(response.data);
            }
        });
    }, []);

    // GET MESSAGES
    function getMessage(id_job_status, files) {
        setSearchMessages(true);
        anexosValues = [];
        messageFiles = [];

        axios.get(window.host_madnezz + '/systems/integration-react/api/list.php?do=get_msg&filter_id_module=' + filterModule + '&id=' + id_job_status).then((response) => {
            setMessages(response.data);
            setSearchMessages(false);
            handleRefreshChat(false);

            var divChat = document.getElementById('chat_' + id_job_status);
            if (divChat) {
                setTimeout(() => {
                    divChat.scrollTop = divChat.scrollHeight;
                }, 50);
            }

            // FILES DO JOB
            if (files && files.length > 0) {
                files.split(',').map((item, i) => {
                    anexosValues.push(item);
                });
            } else {
                anexosValues = [];
            }

            // CONCATENA ANEXOS
            if (response.data.length > 0) {
                response.data.map((mensagem, i) => {
                    if (mensagem.anexos) {
                        mensagem.anexos.split(',').map((item, i) => {
                            if (!anexos.includes(item)) {
                                anexosValues.push(item);
                            }
                        });
                    }
                });
            }

            // setAnexos(anexosValues);
        });
    }

    // FUNÇÃO PARA RECARREGAR CHAT SEMPRE QUE RECEBER NOVA MENSAGEM
    useEffect(() => {
        if (handleRefreshChat && showModal) {
            getMessage(idJobStatus, anexos, modalTitle)
        }
    }, [handleRefreshChat]);

    // CONSTRÓI AS TH'S
    const thead = [
        { enabled: true, label: 'Protocolo', id: 'id_job_status', name: 'id_job_status', mask: '999999999', onChange: handleProtocolo },
        { enabled: (props?.chamados ? true : false), label: 'Urgente', label: "Urgente", id: "mov_urgente", name: "mov_urgente", items: [{ value: 1, label: "Sim" }, { value: '0', label: "Não" }], onChange: ((e) => handleSetUrgente(e)), search: false, export: (props?.chamados ? true : false) },
        { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Tipo', id: 'type_job', name: 'type_job', onChange: ((e) => handleSetChange(e)), items: [{ value: 1, label: "Loja" }, { value: 2, label: "Usuário" }], export: (!props.chamados && !props.fases && !props.visitas ? true : false) },
        { enabled: (window.rs_id_grupo ? true : false), label: 'Empreendimento', id: 'empreendimento', name: 'empreendimento', api: window.host + '/api/sql.php?do=select&component=' + (window.rs_id_emp == 26 ? 'empreendimento' : 'grupo_empreendimento'), onChange: handleEmpreendimentos, export: (window.rs_id_grupo ? true : false) },
        { enabled: (props?.chamados ? true : false), label: 'Loja Solic.', id: 'cad_lja_nome', name: 'cad_lja_nome', api: { url: window.host + "/api/sql.php?do=select&component=loja&filial=true", params: { limit: 50 } }, onChange: handleLojasCad, export: (props?.chamados ? true : false) },
        { enabled: (props?.chamados ? true : false), label: 'Usuário Solic.', id: 'cad_usr_nome', name: 'cad_usr_nome', api: { url: window.host + "/api/sql.php?do=select&component=usuario", params: { limit: 50 } }, onChange: handleUsuariosCad, export: (props?.chamados ? true : false) },
        { enabled: true, label: (props.chamados ? 'Abertura' : 'Data início'), id: 'data_inicio_formatada', type: 'date', name: 'data_inicio_formatada', start: { value: aberturaInicio, onChange: handleSetAberturaInicio }, end: { value: aberturaFim, onChange: handleSetAberturaFim } },
        { enabled: (props?.chamados ? true : false), label: 'Aberto', id: 'dias', name: 'dias', align: 'center', mask: '9999999999', onChange: handleAberto, export: false },
        { enabled: true, label: 'Título', id: 'job', name: 'job', onChange: handleTitulo },
        { enabled: true, label: 'Descrição', id: 'descricao', name: 'descricao', onChange: handleDescricao },
        { enabled: true, label: 'Categoria', id: 'categoria', name: 'categoria', api: window.host_madnezz + '/systems/integration-react/api/registry.php?do=get_category', onChange: handleCheckCategoria },
        { enabled: true, label: 'Subcategoria', id: 'subcategoria', name: 'subcategoria', api: window.host_madnezz + '/systems/integration-react/api/registry.php?do=get_subcategory', onChange: handleCheckSubcategoria },
        { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Peso', id: 'peso_subcategoria', name: 'peso_subcategoria', export: (!props.chamados && !props.fases && !props.visitas ? true : false) },
        { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Integração', id: 'id_apl', name: 'id_apl', items: optionsSystems, onChange: handleIntegrations, export: (!props.chamados && !props.fases && !props.visitas ? true : false) },
        { enabled: (!props.chamados ? true : false), label: 'Status', id: 'status', name: 'status', items: window.optionsStatus, onChange: handleStatus, export: (!props.chamados ? true : false) },
        { enabled: (props.fases || props.chamados || props.visitas ? true : false), label: 'Módulo', id: 'modulo', name: 'modulo', api: window.host_madnezz + '/systems/integration-react/api/list.php?do=get_module&empreendimento_id=' + filterEmpreendimento, onChange: handleModulos, export: (props.fases || props.chamados || props.visitas ? true : false) },
        { enabled: (props.fases || props.chamados ? true : false), label: 'Fase', id: 'fase', name: 'fase', items: optionsPhases, obs: 'modulo', onChange: handleCheckFase, export: (props.fases || props.chamados ? true : false), export: false },
        { enabled: (!props.chamados && !props.fases ? true : false), label: 'Loja', id: 'loja', name: 'loja', api: { url: window.host + "/api/sql.php?do=select&component=loja&filial=true", params: { limit: 50 } }, onChange: handleLojas, export: (!props.chamados && !props.fases ? true : false) },
        { enabled: true, label: (props.chamados ? 'Operador' : 'Usuário'), id: 'usuario_job', name: 'usuario_job', api: { url: ((props.chamados ? (window.host_madnezz + '/systems/integration-react/api/list.php?do=get_operator&filter_id_module=' + filterModule) : (window.host + "/api/sql.php?do=select&component=usuario"))) }, params: { limit: 50 }, onChange: handleUsuarios },
        { enabled: true, label: 'Data finalização', id: 'dataHora_execucao_formatada', name: 'dataHora_execucao_formatada', type: 'date', start: { value: finalizacaoInicio, onChange: handleSetFinalizacaoInicio }, end: { value: finalizacaoFim, onChange: handleSetFinalizacaoFim } },
        { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Localização', id: 'localizacao_realizado_distancia', name: 'localizacao_realizado_distancia', export: (!props.chamados && !props.fases && !props.visitas ? true : false), filter: false },
        { enabled: (false), label: 'Localização Link', id: 'localizacao_realizado_link', name: 'localizacao_realizado_link', export: (!props.chamados && !props.fases && !props.visitas ? true : false), filter: false },
        // { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Temperatura Min.', id: 'min_temperature', name: 'min_temperature', export: (!props.chamados && !props.fases && !props.visitas ? true : false), filter: false },
        // { enabled: (!props.chamados && !props.fases && !props.visitas ? true : false), label: 'Temperatura Max.', id: 'max_temperature', name: 'max_temperature', export: (!props.chamados && !props.fases && !props.visitas ? true : false), filter: false },
        { enabled: (!props.chamados && !props.fases ? true : false), label: 'Sistema', id: 'sistema_job', name: 'sistema', api: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_system", onChange: handleCheckSistema, export: (!props.chamados && !props.fases ? true : false) },
        { enabled: (!props.chamados && !props.fases ? true : false), label: 'Frequência', id: 'frequencia', name: 'frequencia', api: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_frequency", onChange: handleCheckFrequencia, export: (!props.chamados && !props.fases ? true : false) },
        { enabled: (!props.chamados && !props.fases ? true : false), label: 'Hora limite', id: 'hora_limite_formatada', name: 'hora_limite_formatada', api: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_hour_limit", onChange: handleCheckHourLimit, export: (!props.chamados && !props.fases ? true : false) },
        { enabled: (!props.chamados ? true : false), label: 'Grupo', id: 'id_job_parent', name: 'id_job_parent', onChange: handleGrupo, export: (!props.chamados ? true : false) },
        { enabled: (props.chamados ? true : false), label: 'Avaliação', id: 'avaliacao', name: 'avaliacao', api: window.host_madnezz + '/systems/integration-react/api/list.php?do=get_assessment', onChange: handleCheckAvaliacao, export: (props.chamados ? true : false) },
        { enabled: true, label: 'Ações', id: 'acoes', name: 'acoes', export: false },
    ]

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host_madnezz + '/systems/integration-react/api/list.php?do=get_list';

    // PARAMS API TABLE
    const params = {
        filter_protocol: protocolo,
        filter_status: status,
        filter_type: '5',
        filter_date_start: (aberturaInicio ? cd(aberturaInicio) : ''),
        filter_date_end: (aberturaFim ? cd(aberturaFim) : ''),
        filter_date_start_execution: (finalizacaoInicio ? cd(finalizacaoInicio) : ''),
        filter_date_end_execution: (finalizacaoFim ? cd(finalizacaoFim) : ''),
        filter_system: sistemas,
        filter_frequency: frequencias,
        filter_category: categorias,
        filter_subcategory: subcategorias,
        filter_description: descricao,
        filter_title: titulo,
        filter_protocol_title_parent: grupo,
        filter_enterprise: empreendimento,
        filter_client: cliente,
        filter_id_user: usuario,
        filter_id_store: loja,
        filter_id_user_cad: usuario_cad,
        filter_id_store_cad: loja_cad,
        filter_days: aberto,
        filter_hour_limit: horalimite,
        // filter_id_phase: fases,
        filter_id_phase_type: (fasesAux.length > 0 ? fasesAux : fases),
        filter_avaliation: avaliacao,
        filter_id_module: modulo,
        filter_status_supervisor: statusSupervisor,
        filter_id_apl: integration,
        filter_urgent: urgente,
        filter_type_job: filterChange,
        type: 'report',
        // temperatury: (!props.chamados && !props.fases && !props.visitas ? true : false),
        limit: 50
    };

    // BODY DO EXPORTADOR
    let name_aux;

    if (props.chamados) {
        name_aux = 'Chamados';
    } else if (props.fases) {
        name_aux = 'Fases';
    } else if (props.visitas) {
        name_aux = 'Visitas';
    } else {
        name_aux = 'Jobs';
    }

    const body = {
        titles: thead_export,
        url: url,
        name: name_aux,
        filters: params,
        orientation: 'L',
        custom_values: {
            status: {
                0: 'Em andamento',
                1: 'Finalizado',
                2: 'Não tem',
                3: 'Concluído com Atraso',
                4: 'Adiado'
            }
        }
    }

    //TIRAR TAGS DO HTML 
    function removeHTMLTags(text) {
        return text?.replace(/<[^>]*>/g, '');
    }

    // MANDA OS FILTROS PRO COMPONENTE PAI
    useEffect(() => {
        if (props?.icons) {
            props.icons(
                <>
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="pdf" api={{ body: body }} />
                </>
            );
        }

        if (props?.filters) {
            props.filters('');
        }
    }, [jobs]);

    // CALCULA DISTÂNCIA
    function getDistance(mov, realizado) {
        let aux = JSON.parse(mov)
        let local = aux?.job_system_integration_type_1
        let locationStore = optionsLoja.filter((element) => element.value == local);
        if (locationStore.length > 0 && locationStore[0].localizacao) {
            let distancia_aux = null;
            let coordenada = locationStore[0].localizacao?.split(",");
            let coordenada_realizado = realizado?.split(",");
            let distance = calcDistance(coordenada[0], coordenada[1], coordenada_realizado[0], coordenada_realizado[1]);
            if (distance !== '') {
                if (distance < 1) { // SE FOR MAIOR QUE 1KM
                    distance = distance * 1000;
                    distancia_aux = Math.round(distance) + 'm';
                } else {
                    distancia_aux = Math.round(distance) + 'km';
                }
            }
            return `${distancia_aux === null ? '' : distancia_aux + " - "}`
        }
    }

    if (filterModule || (!props.chamados && !props.fases && !props.visitas)) {
        return (
            <>
                <ModalListaJob
                    show={showModal}
                    onHide={handleCloseModal}
                    handleRefreshChat={handleRefreshChat}
                    chamados={props.chamados}
                    idJobStatus={idJobStatus}
                    filterModule={filterModule}
                    anexos={anexos}
                    modalMov={modalMov}
                    modalData={modalData}
                    modalTitle={modalTitle}
                    modalStatus={modalStatus}
                    modalDadoAux={modalDadoAux}
                    modalSistema={modalSistema}
                    modalDescription={modalDescription}
                    integration_type={integration_type}
                    integration_type_1={integration_type_1}
                />
                <Row>
                    <Col lg={12}>
                        <Table
                            id="jobs"
                            api={url}
                            params={params}
                            rightFixed={true}
                            onLoad={handleSetItems}
                            thead={thead}
                        >
                            <Tbody>
                                {(jobs.length > 0 ?
                                    jobs?.map((item, i) => {
                                        var status = '';
                                        var cor = '';
                                        var background = undefined;

                                        // STATUS DO JOBS
                                        if (!props.chamados) {
                                            if (item.status == 0 && item.data_job > window.currentDate) {
                                                status = 'Em andamento';
                                                cor = '';
                                                background = 'secondary';
                                            } else if (item.status == 0 && item.data_job < window.currentDate) {
                                                status = 'Atrasado';
                                                cor = 'text-danger';
                                                background = 'danger';
                                            } else if (item.status == 1) {
                                                status = 'Finalizado';
                                                cor = 'text-primary';
                                                background = 'primary-dark';
                                            } else if (item.status == 2) {
                                                status = 'Não tem';
                                                cor = 'text-dark';
                                                background = 'dark';
                                            } else if (item.status == 3) {
                                                status = 'C/ com Atraso';
                                                cor = 'text-warning';
                                                background = 'warning';
                                            } else if (item.status == 4) {
                                                status = 'Adiado';
                                                cor = 'text-secondary';
                                                background = 'secondary';
                                            }
                                        }

                                        // STATUS DO CHAMADOS
                                        if (props.chamados) {
                                            if (item.status === 0 && !item.recebido && !item.id_usuario) {
                                                status = 'Na fila';
                                                cor = '';
                                            } else if (item.status === 0 && item.recebido && !item.id_usuario) {
                                                status = 'Recebido';
                                                cor = '';
                                            } else if (item.status === 0 && item.recebido && item.id_usuario) {
                                                status = 'Em andamento';
                                                cor = '';
                                            } else if (item.tipo_fase === 'Check') {
                                                status = 'Check';
                                                cor = '';
                                            } else if (item.tipo_fase === 'Pós-venda' && !item.avaliacao) {
                                                status = 'Pós-venda';
                                                cor = '';
                                            } else if (item.tipo_fase === 'Pós-venda' && item.avaliacao) {
                                                status = 'Finalizado';
                                                cor = '';
                                            }
                                        }

                                        // FASES
                                        if ((item.tipo_fase == 'Início' || !item.tipo_fase) && props.chamados) {
                                            if (item.id_usuario) {
                                                fase = 'Operação';
                                            } else {
                                                fase = 'Início';
                                            }
                                        } else if (item.tipo_fase == 'Pós-venda') {
                                            if (item.avaliacao) {
                                                fase = 'Finalizado';
                                            } else {
                                                fase = 'Pós-venda';
                                            }
                                        } else {
                                            fase = item.tipo_fase;
                                        }

                                        // INTEGRAÇÕES
                                        let integrations = '';
                                        item?.id_apl.split(',').map((integration, i) => {
                                            if (integration == 223) {
                                                if (!integrations.includes('Jobs')) {
                                                    integrations += 'Jobs, ';
                                                }
                                            } else if (integration == 224) {
                                                if (!integrations.includes('Chamados')) {
                                                    integrations += 'Chamados, ';
                                                }
                                            } else if (integration == 225) {
                                                if (!integrations.includes('Fases')) {
                                                    integrations += 'Fases, ';
                                                }
                                            } else if (integration == 226) {
                                                if (!integrations.includes('Visitas')) {
                                                    integrations += 'Visitas, ';
                                                }
                                            } else if (integration == 227) {
                                                if (!integrations.includes('Obras')) {
                                                    integrations += 'Obras, ';
                                                }
                                            } else if (integration == 229) {
                                                if (!integrations.includes('Comunicados')) {
                                                    integrations += 'Comunicados, ';
                                                }
                                            }
                                        });

                                        if (integrations) {
                                            integrations = integrations.slice(0, -2);
                                        }

                                        return (
                                            <>
                                                <Tr key={'lista_' + item.id_job_status + '_' + i} >

                                                    <Td title={item.id_job_status}>
                                                        {item.id_job_status}
                                                    </Td>

                                                    {(props.chamados ?
                                                        <Td>
                                                            {props.chamados && item.mov_urgente ? "Sim" : "Não"}
                                                        </Td>
                                                        : '')}


                                                    {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td>
                                                            {item?.type_job}
                                                        </Td>
                                                        : '')}

                                                    {(window.rs_id_grupo ?
                                                        <Td title={item?.empreendimento}>
                                                            {item.empreendimento}
                                                        </Td>
                                                        : '')}

                                                    {(props?.chamados ?
                                                        <Td title={item?.cad_lja_nome}>
                                                            {item?.cad_lja_nome}
                                                        </Td>
                                                        : '')}

                                                    {(props?.chamados ?
                                                        <Td title={item?.cad_usr_nome}>
                                                            {item?.cad_usr_nome}
                                                        </Td>
                                                        : '')}

                                                    <Td title={item?.data_inicio_formatada}>
                                                        {item.data_inicio_formatada}
                                                    </Td>

                                                    {(props.chamados ?
                                                        <Td align="center" title={(item.dias === 0 ? 'Hoje' : (item.dias < 0 ? 'Agendado' : (item.dias + (item.dias === 1 ? ' dia' : ' dias'))))}>
                                                            {(item.dias === 0 ? 'Hoje' : (item.dias < 0 ? 'Agendado' : (item.dias + (item.dias === 1 ? ' dia' : ' dias'))))}
                                                        </Td>
                                                        : '')}

                                                    <Td title={item?.job}>
                                                        {item.job}
                                                    </Td>

                                                    <Td title={item?.descricao}>
                                                        {removeHTMLTags(item.descricao)}
                                                    </Td>

                                                    <Td title={item?.categoria}>
                                                        {item.categoria}
                                                    </Td>

                                                    <Td title={item?.subcategoria}>
                                                        {item.subcategoria}
                                                    </Td>

                                                    {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td>
                                                            {item?.peso_subcategoria}
                                                        </Td>
                                                        : '')}

                                                    {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td title={integrations}>
                                                            {integrations}
                                                        </Td>
                                                        : '')}

                                                    {(!props.chamados ?
                                                        <Td
                                                            title={status}
                                                            className={cor}
                                                            boxed={{
                                                                background: background
                                                            }}
                                                        >
                                                            {status}
                                                        </Td>
                                                        : '')}

                                                    {(props.fases || props.chamados || props.visitas ?
                                                        <Td title={item?.modulo}>
                                                            {item?.modulo}
                                                        </Td>
                                                        : '')}

                                                    {(props.fases || props.chamados ?
                                                        <Td title={fase}>
                                                            {fase}
                                                        </Td>
                                                        : '')}
                                                    {(!props.chamados && !props.fases ?
                                                        <Td>
                                                            {item.loja}
                                                        </Td>
                                                        : '')}
                                                    <Td title={item?.usuario_job}>
                                                        {item.usuario_job}
                                                    </Td>
                                                    <Td title={item?.dataHora_execucao_formatada}>
                                                        {item.dataHora_execucao_formatada}
                                                    </Td>

                                                    {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td >
                                                            {item?.localizacao_realizado ?
                                                                <>
                                                                    <Tippy content={`Distância registrada na execução do job`}>
                                                                        <span>{item?.localizacao_realizado_distancia ? `${item?.localizacao_realizado_distancia} - ` : '' }</span>
                                                                        {/* <span> {`${getDistance(item?.mov_sistemas, item?.localizacao_realizado) !== undefined ? getDistance(item?.mov_sistemas, item?.localizacao_realizado) : ''}`}</span> */}
                                                                    </Tippy>
                                                                    <a href={'https://www.google.com/maps/place/' + item?.localizacao_realizado} target="_blank">
                                                                        Visualizar

                                                                        <Icon type="location" readonly />
                                                                    </a>
                                                                </>
                                                                : '-'
                                                            }
                                                        </Td>
                                                        : '')}

                                                    {/* {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td title={item.min_temperature ? item.min_temperature+'ºC' : ''} align="center">
                                                            {item.min_temperature ? item.min_temperature+'ºC' : ''}
                                                        </Td>
                                                    : '')}   */}

                                                    {/* {(!props.chamados && !props.fases && !props.visitas ?
                                                        <Td title={item.max_temperature ? item.max_temperature+'ºC' : ''} align="center">
                                                            {item.max_temperature ? item.max_temperature+'ºC' : ''}
                                                        </Td>
                                                    : '')}  */}

                                                    {(!props.chamados && !props.fases ?
                                                        <Td title={item?.sistema_job}>
                                                            {item.sistema_job}
                                                        </Td>
                                                        : '')}

                                                    {(!props.chamados && !props.fases ?
                                                        <Td title={item?.frequencia}>
                                                            {item.frequencia}
                                                        </Td>
                                                        : '')}

                                                    {(!props.chamados && !props.fases ?
                                                        <Td title={item?.hora_limite_formatada}>
                                                            {item.hora_limite_formatada}
                                                        </Td>
                                                        : '')}

                                                    {(!props.chamados ?
                                                        <Td title={item?.id_job_status_parent}>
                                                            {item?.job_parent}
                                                            {(item?.id_job_parent ?
                                                                <span className="text-secondary"> ({item?.id_job_status_parent})</span>
                                                                : '')}
                                                        </Td>
                                                        : '')}

                                                    {(props.chamados ?
                                                        <Td align="center" title={item?.avaliacao}>
                                                            {item.avaliacao}
                                                        </Td>
                                                        : '')}

                                                    <Td
                                                        width={1}
                                                        align="center"
                                                        className="hide_print"
                                                    >
                                                        <Icon
                                                            type="view"
                                                            onClick={() => handleShowModal(item.id_job_status, item?.job, item?.descricao, item?.anexos)}
                                                        />
                                                    </Td>
                                                </Tr>
                                            </>
                                        )
                                    })
                                    : <></>)}
                            </Tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        )
    } else {
        if (pageError) {
            return (
                <PageError
                    title="Nenhum módulo configurado"
                    text={`Tente novamente em alguns minutos.\nCaso o problema persista, entre em contato com o suporte.`}
                />
            )
        }
    }
}