import { useEffect, useState } from "react";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import SelectReact from "../../../components/body/select";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import "./style.css";
import Icon from "../../../components/body/icon";
import { groupBy } from "lodash";

import TemperaturaTable from "./tempheader";
import axios from "axios";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import { cd } from "../../../_assets/js/global";

export default function Lista({ filters, icons }) {

    //ESTADOS
    const [lista, setLista] = useState([]);
    const [rowCounter, setRowCounter] = useState();

    const [filter, setFilter] = useState(1);
    const [loja, setLoja] = useState('');
    const [categoria, setCategoria] = useState(1);
    const [nome, setNome] = useState('');
    const [date, setDate] = useState(new Date());
    const [logo, setlogo] = useState('');
    const [reload, setReload] = useState(false);
    const [tipos, setTipos] = useState();
    const [hide, setHide] = useState(true);

    //FILTROS
    const handleFilterLoja = (e) => {
        setLoja(e)
    }
    const handleFilterCategoria = (e) => {
        setCategoria(e.value)
    }
    const handleFilterNome = (e) => {
        setNome(e)
    }
    const handleFilterDate = (e) => {
        setDate(e)
    }
    //GET DATA DA TABLE
    const handleSetItems = (e) => {
        // let data = groupBy(e, 'periodo_nome')
        // setLista(data)
        setLista(e)
        setRowCounter(e)
    }

    //thead1   
    const thead = [
        { enabled: (filter === 1 ? true : false), label: 'Loja', id: 'loja', name: 'loja', api: window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false", onChange: handleFilterLoja, filter: true },
        { enabled: true, label: 'Hora', id: 'horario', name: 'horario', filter: false },
        { enabled: true, label: 'Temperatura', id: 'minimo', name: 'minimo', filter: false },

    ]

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host + '/systems/controle-temperatura/api/lista.php';

    const params = {
        do: 'get',
        data_inicio: cd(date),
        data_fim: '',
        // controle_tipo_id: loja
    }

    // BODY DO EXPORTADOR   
    const body = {
        titles: thead_export,
        url: url,
        name: "Controle de Temperatura",
        filters: params,
        orientation: 'L',
        key: 'data'
    }

    // BUSCAR LOGO EMPREENDIMENTO
    useEffect(() => {
        if (!logo || logo === undefined) {
            axios({
                method: 'get',
                url: window.host + '/api/sql.php?do=select&component=empreendimento',
                params: {
                    filter_search_id: window.rs_id_emp
                }
            }).then((response) => {
                if (response?.data[0]?.logo) {
                    setlogo(response?.data[0]?.logo);
                } else {
                    setlogo(undefined);
                }
            });
        }
    }, []);


    //GET TIPOS
    function getTipos() {
        axios.get(window.host + '/systems/controle-temperatura/api/gerenciador.php?do=getControleTipo')
            .then((response) => {
                setTipos(response.data)
            })
    }
    useEffect(() => {
        getTipos()
    }, []);

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="print" />
                    {/* <Icon type="expandir" expanded={!hide} onClick={() => { setHide(!hide) }} /> */}
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    {
                        // filter == 1 ?
                            // <FilterCheckbox
                            //     name="filter_store"
                            //     api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false"}
                            //     onChangeClose={handleFilterLoja}
                            // >
                            //     Loja
                            // </FilterCheckbox>
                            // :
                            // <SelectReact
                            //     placeholder="Categoria"
                            //     name="filter_category"
                            //     // api={window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + '' + "&filter_id_module=" + ('') + "&filter_id_apl_default=0"}
                            //     onChange={handleFilterCategoria}
                            //     value={categoria}
                            //     options={tipos}
                            // />
                    }
                    <Input
                        type="date"
                        icon={false}
                        required={false}
                        value={date}
                        onChange={(e) => handleFilterDate(e)}
                    />
                </>
            )
        }
        if (filter === 1) {
            setCategoria('')
        }

    }, [loja, nome, categoria, date, filter, hide]);

    let rows

    function count(num) {
        let rows = []
        for (let j = num + 1; j <= 31; j++) {
            rows.push(j);
        }
        return rows
    }

    if (categoria) {
        rows = count(lista?.P1?.length)
    }
    return (
        <>
            {/* <TemperaturaTable
                logo={(logo ? process.env.REACT_APP_URL_UPLOAD + '/' + logo : '')}
                categoria={"Ambiente"}
                nome={"Depósito medicamentos"}
                razao_social={"SEROMA FARMÁCIAS E PERFUMES LTDA"}
                mes={"Setembro"}
                ano={"2023"}
                temperatura={"Temperatura: 15 - 30°C"}
                umidade={"Umidade: 45 - 70%"}
    > */}
            <Row>
                <Col lg={'auto'}>
                    <Table
                        thead={thead}
                        // thead2={thead2}
                        api={url}
                        params={params}
                        onLoad={handleSetItems}
                        reload={date}
                    >
                        <Tbody>
                            {
                                lista ?
                                    lista.map((item, i) => {
                                        let hora_aux
                                        if (item.data) {
                                            hora_aux = item.data.split(" ");
                                        }
                                        return (
                                            <Tr>
                                                <Td>{item?.loja}</Td>
                                                <Td>{hora_aux[1]}</Td>
                                                <Td>{`${item.temperatura}°C`}</Td>
                                            </Tr>
                                        )
                                    })

                                    : <></>
                            }

                        </Tbody>
                    </Table>
                </Col>
            </Row>

            {/* </TemperaturaTable> */}
        </>
    )
}