import { useState, useContext } from 'react';
import './reabrir.scss';

import Modal from '../../../body/modal';
import ModalHeader from '../../../body/modal/modalHeader';
import ModalTitle from '../../../body/modal/modalHeader/modalTitle';
import ModalBody from '../../../body/modal/modalBody';
import Icon from '../../icon';
import Form from '../../form';
import Textarea from '../../form/textarea';
import Button from '../../button';

import { GlobalContext } from "../../../../context/Global";

export default function Reabrir(props){
    // CONTEXT
    const { filterModule } = useContext(GlobalContext);

    const [showModal, setShowModal] = useState(false);
    const [observacao, setObservacao] = useState('');

    const data = {
        ativ_desc:'Reabriu o '+(props.chamados ? 'chamado' : 'job'),
        id_job: props.id_job,
        id_mov: props.id_job_status,
        filter_id_module: (props?.id_modulo ? props?.id_modulo : filterModule),
        status: 3,
        msg: observacao,
        data_aux: '',
        tipo: 'start',
        tipo_fase: 'Pós-venda',
        communication: (props.chamados ? 0 : 1),
        nivel_msg: 0
    }

    // CALLBACK DO ENVIO
    const handleReabrir = () => {
        props?.callback(props?.id_job_status);
    }

    return(
        <>
            <Modal show={ showModal } onHide={() => setShowModal(false)}>
                <ModalHeader>
                    <ModalTitle>
                        Motivo
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host_madnezz+'/systems/integration-react/api/list.php?do=set_statusSupervisor'}
                        data={data}
                        toast="Job reaberto"
                        callback={handleReabrir}
                    >                            
                        <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => (setObservacao(e.target.value))} />

                        <Button
                            type="submit"
                            onClick={() => (setShowModal(false))}
                        >
                            Salvar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Icon type="reabrir" title="Reabrir" animated disabled={props?.disabled} onClick={() => setShowModal(true)} />
        </>
    )
}
