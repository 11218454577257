import { useState } from "react";
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import SelectReact from "../../../components/body/select";
import Button from "../../../components/body/button";
import axios from "axios";
import { useEffect } from "react";

export default function ModalCadastroImagem({ show, handleShow, onHide, callback, categoriaImg, subcategoriaImg }) {

    //FECHAR MODAL
    const handleClose = () => {
        onHide(false);
    };

    //ESTADOS 
    const [categoria, setCategoria] = useState('');
    const [subCategoria, setSubCategoria] = useState('');
    const [imagem, setImagem] = useState([]);
    const [imagemAux, setImagemAux] = useState([]);
    const [loading, setLoading] = useState(false);
    //ARRAY DE OBJETOS DA API QUE MOSTRAM AS OPÇÕES CATEGORIA E SUBCATEGORIA
    const [opCategoria, setOpCategoria] = useState([]);
    const [opSubCategoria, setOpSubCategoria] = useState([])

    // SETAR INPUT 
    const handleSetFile = (response) => {
        let files = [];
        JSON.parse(response[0]).map((file, i) => {
            files.push(file.id);
        });

        setImagem(response[0]);
        setImagemAux(files);
        setLoading(false);
    };

    const data = {
        empreendimento_id: window.rs_id_emp,
        categoria: categoria,
        sub_categoria: subCategoria,
        imagens: imagemAux
    }

    function resetForm() {
        handleClose();
        setImagem('');
        setImagemAux('');
        callback(true)
    };

    // RENDERIZAR E TROCAR NOME DOS OBJETOS -> CATEGORIA
    const getCategory = async () => {
        let objCategoria = [];
        await axios.get(window.host+"/systems/galeria/api/gerenciador.php?do=get_categoria", {
            params: {
                empreendimento_id: window.rs_id_emp,
                ativo: 1
            }
        }).then((response) => {
            response.data.map(item => {
                objCategoria.push({ value: item.id, label: item.nome })
            });
        })
        setOpCategoria(objCategoria)
    };

    // RENDERIZAR E TROCAR NOME DOS OBJETOS -> SUBCATEGORIA
    const getSubcategory = async () => {
        let objSubcategoria = [];
        await axios.get(window.host+"/systems/galeria/api/gerenciador.php?do=get_subcategoria", {
            params: {
                empreendimento_id: window.rs_id_emp,
                categoria_id: categoria,
                ativo: 1
            }
        }).then((response) => {
            response.data.map(item => {
                objSubcategoria.push({ value: item.id, label: item.nome })
            });
        })
        setOpSubCategoria(objSubcategoria)
    };

    //RENDERIZAR AS OPÇÕES CATEGORIA E SUBCATEGORIA
    useEffect(() => {
        getCategory();
        getSubcategory();
    }, []);

    //SE VIER DA GALERIA, MANDAR PRA CATEGORIA E SUBCATEGORIA
    useEffect(() => {
        if (categoriaImg) {
            setCategoria(categoriaImg)
        }
        if (subcategoriaImg) {
            setSubCategoria(subcategoriaImg)
        }
    }, [categoriaImg, subcategoriaImg]);

    return (
        <>
            <Modal show={show} handleShow={handleShow} onHide={handleClose}>
                <ModalHeader>
                    <ModalTitle>
                        Importar
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host+"/systems/galeria/api/galeria.php?do=post&categoria_id=" + categoria + "&subcategoria_id=" + subCategoria}
                        data={data}
                        formData={true}
                        callback={resetForm}
                        // test       
                    >
                        {(
                            categoriaImg || subcategoriaImg ?
                                <></>
                                :
                                <>
                                    <SelectReact
                                        name="categoria"
                                        label="Categoria"
                                        value={categoria}
                                        onChange={(e) => setCategoria(e.value)}
                                        options={opCategoria}
                                    />
                                    <SelectReact
                                        name="subCategoria"
                                        label="Subcategoria"
                                        value={subCategoria}
                                        onChange={(e) => setSubCategoria(e.value)}
                                        options={opSubCategoria}
                                    />
                                </>
                        )}
                        <Input
                            name="arquivo"
                            type="file"
                            label="Escolher arquivo"
                            accept="image/*"
                            value={imagem}
                            multiple={true}
                            onChange={() => window.alert('teste')}
                            callback={handleSetFile}
                        />

                        <Button
                            type="submit"
                            state={(loading ? 'loading' : '')}
                        >
                            Enviar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}