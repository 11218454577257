import { useEffect, useState, useContext } from "react"
import { GlobalContext } from "../../../context/Global";
import Col from "../../../components/body/col";
import Icon from "../../../components/body/icon";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Title from "../../../components/body/title";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import ModalTreinamento from '../ModalCadastro';
import Loader from "../../../components/body/loader";

export default function Relatorio() {
    // CONTEXT
    const { filter, handleSetFilter } = useContext(GlobalContext);

    // CONVERSOR DE DATA
    function cd(date) {
        let dateFormated =
            (new Date(date).getDate() < 10
                ? "0" + new Date(date).getDate()
                : new Date(date).getDate()) +
            "/" +
            (new Date(date).getMonth() + 1 < 10
                ? "0" + (new Date(date).getMonth() + 1)
                : new Date(date).getMonth() + 1) +
            "/" +
            new Date(date).getFullYear();
        return dateFormated;
    }

    // MODAL
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(!show);

    // ESTADOS ITENS DA TABELA
    // VALORES FILTROS
    const [status, setStatus] = useState([1]);
    const [loja, setLoja] = useState('');
    const [usuario, setUsuario] = useState('');
    const [cargo, setCargo] = useState('');
    const [treinamento, setTreinamento] = useState('');
    const [tipoDois, setTipoDois] = useState('');
    const [categoria, setCategoria] = useState('');
    const [subcategoria, setSubcategoria] = useState('');
    const [subsubcategoria, setSubsubcategoria] = useState('');
    const [subsubsubcategoria, setSubsubsubcategoria] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [tipo, setTipo] = useState(''); //TIPO DO TREINAMENTO
    const [situacao, setSituacao] = useState('');
    const [desativadoPor, setDesativadoPor] = useState('');
    const [dataDesativadoInicio, setDataDesativadoInicio] = useState('');
    const [dataDesativadoFim, setDataDesativadoFim] = useState(''); 
    const [motivoDesativacao, setMotivoDesativacao] = useState(''); 



    // Variaveis dos filtros
    const optionsStatus = [
        { value: 1, label: "Finalizado" },
        { value: 2, label: "Em andamento" },
        { value: 3, label: "Pendente" }
    ]
    const optionsTipos = [
        { value: 1, label: "Convocação" },
        { value: 2, label: "Convite" }
    ]
    const optionsSituacao = [
        { value: 1, label: "Ativo" },
        { value: 0, label: "Inativo" }
    ]

    // FILTRO STATUS
    const handleCheckStatus = (e) => {
        setStatus(e);
    }

    // FILTRO LOJA
    const handleCheckLoja = (e) => {
        setLoja(e);
    }

    // FILTRO USUÁRIO
    const handleCheckUsuario = (e) => {
        setUsuario(e);
    }

    // FILTRO CARGO
    const handleCheckCargo = (e) => {
        setCargo(e);
    }

    // FILTRO TREINAMENTO
    const handleCheckTreinamento = (e) => {
        setTreinamento(e);
    }

    // FILTRO CATEGORIA
    const handleCheckCategoria = (e) => {
        setCategoria(e);
    }

    // FILTRO SUB-CATEGORIA
    const handleCheckSubcategoria = (e) => {
        setSubcategoria(e);
    }

    // FILTRO SUB-SUB-CATEGORIA
    const handleCheckSubsubcategoria = (e) => {
        setSubsubcategoria(e);
    }
    // FILTRO SUB-SUB-SUB-CATEGORIA
    const handleCheckSubsubsubcategoria = (e) => {
        setSubsubsubcategoria(e);
    }

    // FILTRO DE DATA (INÍCIO)
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    }

    // FILTRO DE DATA (FIM)
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    }

    // FILTRO TIPO
    const handleCheckTipo = (e) => {
        setTipo(e);
    }

    const handleCheckTipoDois = (e) => {
        setTipoDois(e)
    }

    //FILTRO SITUAÇÃO
    const handleCheckSituacao = (e) => {
        setSituacao(e);
    }

    //FILTRO DESATIVADO POR:
    const handleCheckDesativadoPor = (e) => {
        setDesativadoPor(e)
    };

    // FILTRO DATA DESATIVADO (INÍCIO)
    const handleSetDataDesativadoInicio = (e) => {
        setDataDesativadoInicio(e);
        handleSetFilter(true);
    }

    // FILTRO DATA DESATIVADO (FIM)
    const handleSetDataDesativadoFim = (e) => {
        setDataDesativadoFim(e);
        handleSetFilter(true);
    }

    //FILTRO MOTIVO DESATIVAÇÃO
    const handleMotivoDesativacao = (e) => {
        setMotivoDesativacao(e)
    }

    // VAR
    var reload = true;

    //ESTADOS
    const [loading, setLoading] = useState(true);
    const [treinamentos, setTreinamentos] = useState([]);
    const [hide, setHide] = useState(true)

    const handleSetItems = (e) => {
        setTreinamentos(e);
    }

    return (
        <>
            {/* CADASTRO */}
            <ModalTreinamento show={show} handleShow={handleShow} />

            {/* LISTA */}
            <Row>
                <Col>
                    <Title
                        icon={
                            <div>
                                <Icon type="print" />
                                <Icon
                                    type="expandir"
                                    expanded={!hide}
                                    onClick={() => { setHide(!hide) }}
                                />
                            </div>
                        }
                    >
                        Relatório
                    </Title>
                    <Table
                        id="treinamentos"
                        api={window.host + "/systems/treinamento/api/relatorio.php?do=get"}
                        params={{
                            status: status,
                            loja: loja,
                            usuario: usuario,
                            cargo: cargo,
                            treinamento: treinamento,
                            tipo: tipoDois,
                            categoria: categoria,
                            subcategoria: subcategoria,
                            sub_subcategoria: subsubcategoria,
                            sub_sub_subcategoria: subsubsubcategoria,
                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                            data_fim: (dataFim ? cd(dataFim) : ''),
                            tipo_treinamento: tipo,
                            ativo: situacao,
                            usuario_desativacao: desativadoPor,
                            dd_data_inicio: (dataDesativadoInicio ? cd(dataDesativadoInicio) : ''),
                            dd_data_fim: (dataDesativadoFim ? cd(dataDesativadoFim) : ''),
                            motivo_desativacao: motivoDesativacao,
                        }}
                        onLoad={handleSetItems}
                    >
                        <Thead>
                            <Tr>
                                <Th
                                    name="status"
                                    items={optionsStatus}
                                    filtered={status}
                                    onChange={handleCheckStatus}
                                >
                                    Status
                                </Th>
                                <Th
                                    name="loja"
                                    api={window.host + '/api/sql-react.php?do=select&component=loja&np=true&filial=true'}
                                    onChange={handleCheckLoja}
                                >
                                    Loja
                                </Th>
                                <Th
                                    name="usuario"
                                    api={window.host + '/api/sql-react.php?do=select&component=usuario&np=true&filial=true'}
                                    onChange={handleCheckUsuario}
                                >
                                    Usuário
                                </Th>
                                <Th
                                    hide={hide}
                                    name="cargo"
                                    api={window.host + '/api/sql-react.php?do=select&component=cargo&np=true&filial=true'}
                                    onChange={handleCheckCargo}
                                >
                                    Cargo
                                </Th>
                                <Th
                                    name="treinamento"
                                    api={window.host + '/systems/treinamento/api/relatorio.php?do=get_treinamento'}
                                    onChange={handleCheckTreinamento}
                                >
                                    Treinamento
                                </Th>
                                <Th
                                    hide={hide}
                                    name="tipo"
                                    items={[
                                        { value: 1, label: "Curso" },
                                        { value: 2, label: "Fase" }
                                    ]}
                                    onChange={handleCheckTipoDois}
                                >
                                    Tipo
                                </Th>
                                <Th
                                    name="categoria"
                                    api={window.host + '/systems/treinamento/api/relatorio.php?do=get_categoria'}
                                    onChange={handleCheckCategoria}
                                >
                                    Categoria
                                </Th>
                                <Th
                                    hide={hide}
                                    name="subcategoria"
                                    api={window.host + '/systems/treinamento/api/relatorio.php?do=get_subcategoria'}
                                    onChange={handleCheckSubcategoria}
                                >
                                    Subcategoria
                                </Th>
                                <Th
                                    hide={hide}
                                    name="subsubcategoria"
                                    api={window.host + '/systems/treinamento/api/relatorio.php?do=get_sub_subcategoria'}
                                    onChange={handleCheckSubsubcategoria}
                                >
                                    Sub-SubCategoria
                                </Th>
                                <Th
                                    hide={hide}
                                    name="subsubsubcategoria"
                                    api={window.host + '/systems/treinamento/api/relatorio.php?do=get_sub_sub_subcategoria'}
                                    onChange={handleCheckSubsubsubcategoria}
                                >
                                    Sub-Sub-SubCategoria
                                </Th>
                                <Th
                                    type="date"
                                    start={{ value: dataInicio, onChange: handleSetDataInicio }}
                                    end={{ value: dataFim, onChange: handleSetDataFim }}
                                >
                                    Data
                                </Th>
                                <Th
                                    hide={hide}
                                    name="tipo_do_treinamento"
                                    items={optionsTipos}
                                    onChange={handleCheckTipo}
                                >
                                    Tipo do Treinamento
                                </Th>
                                <Th hide={hide}
                                    name='busca_situacao'
                                    items={optionsSituacao}
                                    onChange={handleCheckSituacao}
                                >
                                    Situação
                                </Th>
                                <Th>
                                    Pontos
                                </Th>
                                <Th
                                    hide={hide}
                                    name='busca_desativado_por'
                                    api={window.host + '/api/sql-react.php?do=select&component=usuario&np=true&filial=true'}
                                    onChange={handleCheckDesativadoPor}
                                >
                                    Desativado Por
                                </Th>
                                <Th
                                    hide={hide}
                                    type="date"
                                    name="data_desativado"
                                    start={{ value: dataDesativadoInicio, onChange: handleSetDataDesativadoInicio }}
                                    end={{ value: dataDesativadoFim, onChange: handleSetDataDesativadoFim }}
                                >
                                    Data desativado
                                </Th>
                                <Th hide={hide}
                                    name="busca_motivo_desativacao"
                                    api={window.host + '/api/sql-react.php?do=select&component=motivo_desativacao&np=true&filial=true'}
                                    onChange={handleMotivoDesativacao}
                                >
                                    Motivo Desativação
                                </Th>
                                <Th>
                                    Avaliação
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(treinamentos.length > 0 ?
                                treinamentos.map((item) => {
                                    var status;
                                    var color;
                                    if (item.status === 1) {
                                        status = "Finalizado";
                                        color = "text-success"
                                    } else if (item.status === 2) {
                                        status = "Pendente";
                                        color = "text-warning"
                                    } else if (item.status === 3) {
                                        status = "Em andamento";
                                        color = "text-danger"
                                    }
                                    return (
                                        <Tr
                                            key={item.treinamento_id}
                                            align="center"
                                        >
                                            <Td className={color}>
                                                {status}
                                            </Td>
                                            <Td>
                                                {item.loja}
                                            </Td>
                                            <Td>
                                                {item.usuario}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.cargo}
                                            </Td>
                                            <Td>
                                                {item.treinamento}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.tipo}
                                            </Td>
                                            <Td>
                                                {item.categoria}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.subcategoria}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.sub_subcategoria}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.sub_sub_subcategoria}
                                            </Td>
                                            <Td>
                                                {item.data}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.tipo_treinamento}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.ativo}
                                            </Td>
                                            <Td align="center">
                                                {item.pontos}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.usuario_desativacao}
                                            </Td>
                                            <Td hide={hide}>
                                                {item.data_desativacao}
                                            </Td>
                                            <Td hide={hide} align="center">
                                                {item.motivo_desativacao}
                                            </Td>
                                            <Td width={1} align="center">
                                                {(item.qtd_perguntas > 0 ?
                                                    <Icon type="view" />
                                                :
                                                    '-'
                                                )}
                                            </Td>
                                        </Tr>
                                    )
                                })
                            :
                                ''
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}