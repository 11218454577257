import { useState, useEffect } from "react";
import style from './lista.module.scss';

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Tr from "../../../components/body/table/tr";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Item from "./Item";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";

export default function Lista(props){
    // ESTADOS FILTROS
    const [filterEmpreendimentos, setFilterEmpreendimentos] = useState([]);
    const [filterLojas, setFilterLojas] = useState([]);
    const [filterTelefone, setFilterTelefone] = useState([]);
    const [filterLogo, setFilterLogo] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');
    const [filterConfirm, setFilterConfirm] = useState([]);

    // ESTADOS
    const [lojas, setLojas] = useState([]);

    // FILTRO EMPREENDIMENTO
    const handleFilterEmpreendimentos = (e) => {
        setFilterEmpreendimentos(e);
    }

    // FILTRO LOJAS
    const handleFilterLojas = (e) => {
        setFilterLojas(e);
    }

    // FILTRO TELEFONE
    const handleFilterTelefone = (e) => {
        setFilterTelefone(e);
    }

    // FILTRO E-MAIL
    const handleFilterEmail = (e) => {
        setFilterEmail(e?.target?.value);
    }

    // FILTRO LOGO
    const handleFilterLogo = (e) => {
        setFilterLogo(e);
    }

    // FILTRO CONFIRMAÇÃO
    const handleFilterConfirm = (e) => {
        setFilterConfirm(e);
    }

    // OPTIONS LOGO
    const optionsLogo = [
        {value: 1, label: 'Cadastrado'},
        {value: 2, label: 'Não cadastrado'}
    ]

    // OPTIONS CONFIRMAÇÃO
    const optionsConfirm = [
        {value: '-1', label: 'Confirmado'},
        {value: '0', label: 'Não confirmado'}
    ]

    // GET LIST
    const handleLojas = (e) => {
        setLojas(e);
    }

    return(
        <Row>
            <Col lg={(props?.integrated ? 12 : 'auto')}>
                <Table
                    api={window.host_madnezz+'/systems/integration-react/api/request.php'}
                    params={{
                        type: 'Table',
                        do: 'getUpdateTableReport',
                        table: 'store',
                        id_emp: filterEmpreendimentos,
                        id: filterLojas,
                        email: filterEmail ? filterEmail : undefined,
                        id_att_log: filterConfirm,
                        limit: 50
                    }}
                    className={style.table}
                    onLoad={handleLojas}
                    text_limit={false}
                >
                    {(!props?.integrated ?
                        <Thead>
                            <Tr>
                                <Th
                                    api={window.host+"/api/sql.php?do=select&component=grupo_empreendimento"}
                                    onChange={handleFilterEmpreendimentos} 
                                >
                                    Empreendimento
                                </Th>

                                <Th
                                    id="filter_store"
                                    api={{
                                        url: window.host+"/api/sql.php?do=select&component=loja&pl=true", 
                                        limit: 50
                                    }}
                                    onChange={handleFilterLojas} 
                                >
                                    Loja
                                </Th>

                                {/* <Th
                                    items={optionsTelefone}
                                    name="filter_telefone"
                                    onChange={handleFilterTelefone} 
                                    search={false}
                                >
                                    CNPJ
                                </Th>

                                <Th
                                    items={optionsTelefone}
                                    name="filter_telefone"
                                    onChange={handleFilterTelefone} 
                                    search={false}
                                >
                                    Contrato
                                </Th>

                                <Th
                                    items={optionsTelefone}
                                    name="filter_telefone"
                                    onChange={handleFilterTelefone} 
                                    search={false}
                                >
                                    LUC
                                </Th>

                                <Th
                                    items={optionsTelefone}
                                    name="filter_telefone"
                                    onChange={handleFilterTelefone} 
                                    search={false}
                                >
                                    Localização
                                </Th> */}

                                <Th
                                    name="filter_email"
                                    onChange={handleFilterEmail} 
                                >
                                    E-mail
                                </Th>

                                {/* <Th
                                    items={optionsTelefone}
                                    name="filter_telefone"
                                    onChange={handleFilterTelefone} 
                                    search={false}
                                >
                                    Telefone
                                </Th> */}
                                
                                {/* <Th
                                    items={optionsLogo}
                                    name="filter_logo"
                                    onChange={handleFilterLogo} 
                                    search={false}
                                >
                                    Logo
                                </Th> */}

                                <Th
                                    width={1}
                                    align="center"
                                    items={optionsConfirm}
                                    name="filter_confirmacao"
                                    onChange={handleFilterConfirm} 
                                    search={false}
                                    className="hide_print"
                                >
                                    Ações
                                </Th>
                            </Tr>
                        </Thead>
                    :
                        <></>
                    )}
                    <Tbody>
                        {(lojas.length > 0 ?
                            lojas.map((loja, i) => {
                                return(
                                    <Item
                                        key={'loja_'+loja.id}
                                        loja={loja}
                                    />
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )
}
