import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import Navbarlink from '../../components/header/navbar/navbarLink';
import { decrypt } from '../../_assets/js/global';
import { useState } from 'react';
import Container from '../../components/body/container';
import axios from 'axios';

// PÁGINAS
import Calendario from './Calendario';

export default function Escala(){
  const params = useParams();    

  var tokenUrl_aux = params['*'].split('/');
  var tokenUrl = tokenUrl_aux[tokenUrl_aux.length - 1];
  var decryptedReturn = JSON.parse(decrypt(tokenUrl));
  
  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_grupo = decryptedReturn.id_grupo;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  const [filters, setFilters] = useState(null);

  // DEFINE ÍCONES NAVBAR
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  // DEFINE FILTROS NAVBAR
  const handleSetFilters = (e) => {
    setFilters(e);
  }

  return (
    <>
        <Navbar
            filters={filters}
            icons={icons}
        >
            <Navbarlink link={'/systems/escala-react/calendario/'+window.token} name="Calendário" icon="analytics" />
        </Navbar>
    
        <Container>
            <Routes>
                <Route path="/:token" index={true} element={ <Navigate to={'calendario/'+window.token} replace /> } />
                <Route path="calendario/:token" element={ <Calendario icons={handleSetIcons} filters={handleSetFilters} /> } />
            </Routes>
        </Container>
    </>
  );
}
