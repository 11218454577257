import { useState, useEffect, useContext, cloneElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { cd, subDays, addDays, get_date } from "../../../_assets/js/global";
import style from './Qtd/quantidade.module.scss';

import SelectReact from "../../../components/body/select";
import Input from "../../../components/body/form/input";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Counter from '../../../components/body/counter';

import { GlobalContext } from "../../../context/Global";
import { JobsContext } from "../../../context/Jobs";
import PageError from "../../../components/body/pageError";
import Jobs from "./Jobs";
import Visitas from "./Visitas";
import Chamados from "./Chamados";
import Fases from "./Fases";
import { toast } from "react-hot-toast";
import Quantidade from "./Qtd";

export default function Calendario(props) {

  //NAVIGATE
  const navigate = useNavigate();

  // PARAMS
  const params = useParams();

  // CONTEXT GLOBAL
  const { refresh, refreshCalendar, loadingCards, handleSetCardExternal, loadingCalendar, handleSetPrevIndex, filterModule, handleSetFilterModule, handlePageError, firstLoad, handleSetFirstLoad, handleSetDisabledFilter, disabledFilter } = useContext(GlobalContext);

  // CONTEXT JOBS
  const { optionsEmpreendimentos, handleSetFilterEmpreendimento, filterEmpreendimento, optionsLoja, optionsUsuario, handleSetAutoSwiper, configuracoes } = useContext(JobsContext);

  var date = new Date();

  // DEFINE VALOR DE INÍCIO INICIAL DO CALENDÁRIO
  const periodStartInitial = () => {
    if (params['periodStart'] && params['periodStart'] != 0) {
      return new Date(get_date('date_sql', get_date('date_sql', params['periodStart'], 'date_sql_reverse'), 'date_add_day', 1));
    } else {
      if (props?.widget) {
        return new Date();
      } else {
        if (!props.chamados && !props.fases) { // SE FOR JOBS
          if (props.visitas) {
            return '';
          } else {
            if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && params['view'] != 1) {
              return new Date();
            } else {
              return new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', 15));
            }
          }
        } else { // QUALQUER OUTRO SISTEMA ALÉM DE JOBS
          return '';
        }
      }
    }
  }

  // DEFINE VALOR FINAL INICIAL DO CALENDÁRIO
  const periodEndInitial = () => {
    if (params['periodEnd'] && params['periodEnd'] != 0) {
      return new Date(get_date('date_sql', get_date('date_sql', params['periodEnd'], 'date_sql_reverse'), 'date_add_day', 1));
    } else {
      if (props?.widget) {
        return new Date();
      } else {
        if (!props.chamados && !props.fases) { // SE FOR JOBS
          if (props.visitas) {
            return '';
          } else {
            if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && params['view'] != 1) {
              return new Date();
            } else {
              return new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_add_day', 15));
            }
          }
        } else { // QUALQUER OUTRO SISTEMA ALÉM DE JOBS
          return '';
        }
      }
    }
  }

  // DEFINE TIPO DO CALENDÁRIO INICIAL
  const tipoCalendarioInitial = () => {
    if (params['view'] && params['view'] != 0) {
      return params['view'];
    } else {
      if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && !props.chamados && !props.fases && !props.visitas) {
        if (props?.widget) {
          return 1;
        } else {
          return 2;
        }
      } else {
        return 1;
      }
    }
  }

  // DEFINE SUBTIPO DO CALENDÁRIO INICIAL
  const subTipoCalendarioInitial = () => {
    if (window.rs_id_lja && window.rs_id_lja > 0) {
      return 'user'; // PROVISÓRIO ATÉ ALGUÉM RECLAMAR
    } else {
      return 'user';
    }
  }

  // DEFINE USUÁRIO INICIAL DO FILTRO
  const filterUserInitial = () => {
    if (props?.widget) {
      return window.rs_id_usr;
    } else {
      if(props.chamados){
        return '';
      }else if(props.fases){
        if(window.rs_permission_apl !== 'master'){
          return [window.rs_id_usr];
        }else{
          return '';
        }
      } else if (props.visitas) {
        return window.rs_id_usr;
      } else {
        if(tipoCalendario == 1 && (!window.rs_id_lja || window.rs_id_lja == 0) && (!params['store'] || params['store'] == 0)){
          return [window.rs_id_usr]
        }else{
          return '';
        }        
      }
    }
  }

  // DEFINE LOJA INICIAL DO FILTRO
  const filterStoreInitial = () => {
    if (params['store'] && params['store'] != 0 && (!window.rs_id_lja || window.rs_id_lja == 0)) {
      return params['store'];
    } else {
      if (props.chamados) {
        return '';
      } else if (props.visitas) {
        return '';
      } else {
        if (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker') {
          return window.rs_id_lja;
        } else {
          return '';
        }
      }
    }
  }

  // DEFINE STATUS INICIAL DO FILTRO
  const filterStatusInitial = () => {
    if(params['status']){
      return [params['status']];
    }else{
      return [];
    }
  }

  // DEFINE CATEGORIA INICIAL DO FILTRO
  const filterCategoryInitial = () => {
    if(params['category']){
      return [params['category']];
    }else{
      return [];
    }
  }

  // CALENDÁRIO
  var loading;
  const [layout, setLayout] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [jobsCols, setJobsCols] = useState([]);
  const [jobsColsAux, setJobsColsAux] = useState([]);
  const [periodStart, setPeriodStart] = useState(periodStartInitial);
  const [periodEnd, setPeriodEnd] = useState(periodEndInitial);
  const [tipoCalendario, setTipoCalendario] = useState(tipoCalendarioInitial);
  const [subTipoCalendario, setSubTipoCalendario] = useState(subTipoCalendarioInitial);
  const [lojaTipo, setLojaTipo] = useState(1);
  const [loja, setLoja] = useState(filterStoreInitial);
  const [usuario, setUsuario] = useState(filterUserInitial);
  const [optionsModule, setOptionsModule] = useState([]);
  const [changeLayout, setChangeLayout] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [page, setPage] = useState(0);
  const [reloadInternal, setReloadInternal] = useState(false);
  const [changePeriod, setChangePeriod] = useState(false);
  const [changeModule, setChangeModule] = useState(false);
  const [changeStore, setChangeStore] = useState(false);
  const [changeUser, setChangeUser] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [prepend, setPrepend] = useState(false);
  const [cardsLength, setCardsLength] = useState([]);
  const [checkStep, setCheckStep] = useState(false);

  // FILTROS
  const [filterCategory, setFilterCategory] = useState(filterCategoryInitial);
  const [filterFrequency, setFilterFrequency] = useState("");
  const [filterSubCategory, setFilterSubCategory] = useState("");
  const [filterSystem, setFilterSystem] = useState([]);
  const [filterMonth, setFilterMonth] = useState((props.visitas ? window.currentMonth : ''));
  const [filterYear, setFilterYear] = useState((props.visitas ? window.currentYear : ''));
  const [filterStatus, setFilterStatus] = useState(filterStatusInitial);
  const [filterSupervisor, setFilterSupervisor] = useState([]);
  const [filterModelo, setFilterModelo] = useState(false);
  const [filterAll, setFilterAll] = useState('');
  const [filterArchived, setFilterArchived] = useState(2);

  // BUSCA NÚMEROS DE CARDS EM CADA MÓDULO
  function get_cardsLength() {
    axios({
      method: 'get',
      url: window.host_madnezz + '/systems/integration-react/api/list.php?do=numberCardsModule'
    }).then((response) => {
      if (response.data) {
        setCardsLength(response.data);
      }
    });
  }

  // MÓDULOS QUE ABREM JUNTOS (CHAMADOS)
  const modules = [];
  if(configuracoes.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_multiple_col){    
    let key_aux;

    if(key_aux !== undefined){
      if(key_aux?.id_modulo != filterModule){
        key_aux = undefined;
      }
    }

    JSON.parse(configuracoes.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_multiple_col).map((item_conf, i_conf) => {
      optionsModule.map((item, i) => {
        if(item_conf?.id_modulo.includes(item.value) && (key_aux?.index === i_conf || key_aux === undefined) && item_conf?.id_modulo.includes(filterModule)){
          modules.push(item.value);
          key_aux = {id_modulo: item_conf?.id_modulo, index: i_conf};
        }
      });
    });
  }

  // FUNÇÃO PARA LISTAR CARDS
  function loadCalendar(loading, swiper, id_group='', reload=undefined, refresh=undefined, filter_id_module) {
    if (loading) {
      loadingCalendar(true);
      handleSetAutoSwiper(true);
    }
    if (!swiper && loading) {
      setJobs([]);
    }

    if (optionsModule.length == 0 && (props?.chamados || props?.fases || props?.visitas)) {
      axios.get(window.host_madnezz + "/systems/integration-react/api/list.php?do=get_module&empreendimento_id=" + filterEmpreendimento).then((response) => {
        if (response.data.length > 0) {
          setOptionsModule(response.data);

          if (props.chamados || props.fases || props.visitas) {
            if (props.visitas) {
              handleSetFilterModule(global.modulo.visitas);
            } else {
              if (window.rs_sistema_id != 238) { // SE O SISTEMA ID FOR DIFERENTE DO "CHAMADOS EMPRESA REACT" TROCA
                handleSetFilterModule((response.data[0] ? response.data[0].value : 0)); // SELECIONA O PRIMEIRO (CASO TENHA) COMO PADRÃO AO CARREGAR A PÁGINA
              } else { // SE O SISTEMA ID FOR DO "CHAMADOS EMPRESA REACT" CRAVA O ID DO MÓDULO
                handleSetFilterModule(2361);
              }
            }
          }
        } else {
          if (props.chamados || props.fases || props.visitas) {
            if (window.rs_sistema_id != 238) {
              setPageError(true);
            } else {
              if (props.chamados) {
                handleSetFilterModule(2361);
              } else {
                setPageError(true);
              }
            }
          }
        }
      });
    }

    if ((!props.chamados && !props.visitas && !props.fases) || (((props.chamados || props.visitas || props.fases) && filterModule))) {
      handleSetDisabledFilter(true); // DESABILITA OS FILTROS ENQUANTO CHAMA A REQUISIÇÃO

      // DEFINE A PAGE
      let page_aux;
      if (!props.chamados && !props.fases && !props.visitas) { // SE FOR SISTEMA JOBS
        if (tipoCalendario == 2 || tipoCalendario == 4) { // SE FOR TIPO LOJA (2) OU USUÁRIO (4)
          if (loading) {
            page_aux = 0;
          } else {
            if (reload) {
              page_aux = 0;
            } else {
              if (refresh) {
                page_aux = 0;
              } else {
                page_aux = page;
              }
            }
          }
        } else {
          page_aux = undefined;
        }
      } else {
        page_aux = undefined;
      }

      // DEFINE LIMIT
      let limit_aux;
      let limit = 50;
      if (!props.chamados && !props.fases && !props.visitas) { // SE FOR SISTEMA JOBS
        if (tipoCalendario == 2 || tipoCalendario == 4) { // SE FOR TIPO LOJA (2) OU USUÁRIO (4)
          if (refresh) {
            limit_aux = (page > 2 ? ((page - 1) * limit) : limit)
          } else {
            limit_aux = limit
          }
        } else {
          limit_aux = undefined;
        }
      } else {
        limit_aux = undefined;
      }

      // VERIFICA SE RECEBEU ARRAY COM ID DE MÓDULOS
      let filter_id_module_aux = [];

      if(filter_id_module){
        filter_id_module_aux = filter_id_module;
      }else{
        filter_id_module_aux = [filterModule];
      }

      // PERCORRE ID DE MÓDULOS FAZENDO REQUISIÇÃO
      setJobsColsAux([]);

      filter_id_module_aux.map((id_modulo, i) => {
        axios({
          method: 'get',
          url: window.host_madnezz + '/systems/integration-react/api/list.php',
          params: {
            do: 'get_list',
            page: page_aux,
            limit: limit_aux,
            filter_month: filterMonth,
            filter_year: filterYear,
            filter_category: filterCategory,
            filter_subcategory: filterSubCategory,
            filter_system: filterSystem,
            filter_date_start: (periodStart ? cd(periodStart) : ''),
            filter_date_end: (periodEnd ? cd(periodEnd) : ''),
            filter_id_store: loja,
            filter_frequency: filterFrequency,
            filter_id_module: filterModule,
            filter_id_user: (!props.chamados && !props.fases ? usuario : ''),
            filter_id_user_group: (props.fases ? usuario : ''),
            filter_id_group: id_group,
            filter_type: tipoCalendario,
            filter_subtype: (props.chamados || props.visitas || tipoCalendario == 3 ? subTipoCalendario : ''), // CHAMADOS, VISITAS OU PAINEL
            id_apl: props?.id_apl,
            filter_enterprise: filterEmpreendimento,
            filter_id_supervisor: filterSupervisor,
            filter_status: filterStatus,
            filter_model: (filterModelo ? 1 : undefined),
            filter_multiple_columns: filterAll,
            filter_closed_job: filterArchived == 2 ? [0] : [0,1],
          },
        }).then((response) => {
          if(filter_id_module && modules.length > 1){ // SE VIER O MÓDULO ESPEFICICADO, ADICIONA NA COLUNA CORRESPONDENTE
            setJobs(undefined);
            let label_aux = optionsModule.filter((elem) => elem.value == id_modulo)[0].label;
            let id_aux = optionsModule.filter((elem) => elem.value == id_modulo)[0].value;
            setJobsColsAux(jobsColsAux => [...jobsColsAux, {module: label_aux, id_module: id_aux, data: response.data}]);
          }else{
            setJobsCols(undefined);

            if (!props.chamados && !props.fases && !props.visitas) {
              if (tipoCalendario == 1 && !loadingCards) { // TIPO CALENDARIO (1)
                if (reload || refresh) {
                  setJobs(response.data);
                } else {
                  if (prepend) {
                    setJobs(jobs => [...response.data, ...jobs]);
                  } else {
                    setJobs(jobs => [...jobs, ...response.data]);
                  }
                }
              } else if ((tipoCalendario == 2 || tipoCalendario == 4) && page > 0 && !loading && !refresh) { // SE FOR TIPO LOJA (2) OU TIPO USUÁRIO (4) E PAGE MAIOR QUE 0 INCREMENTA OS CARDS AO INVÉS DE SUBSTITUIR
                setJobs(jobs => [...jobs, ...response.data]);
              } else {
                if (response.data.length > 0) {
                  setJobs(response.data);
                } else {
                  setJobs([]);
                }
              }
            } else {
              if (response.data.length > 0) {
                setJobs(response.data);
              } else {
                setJobs([]);
              }
            }
          }

          // SETA FALSE NO ESTADO PREPEND PARA OS PRÓXIMOS ADICIONAR À FRENTE DOS JOBS JÁ EXISTENTES
          setPrepend(false);

          if (loading || refresh) {
            if (response?.data[0]?.page) {
              setPage(response?.data[0]?.page + 1);
            } else {
              setPage(1);
            }
          } else {
            if (!reload) {
              if (response?.data[0]?.page) {
                setPage(response?.data[0]?.page + 1);
              } else {
                if (response.data.length >= limit) {
                  setPage(page + 1);
                }
              }
            }
          }

          setTimeout(() => {
            loadingCalendar(false);
          }, 500);

          // CHECA SE O USUÁRIO POSSUI CARDS EM PÓS-VENDA (CHAMADOS)
          if (response.data && props.chamados) {
            let array = response.data.length - 1;
            if (response.data[array].group[0].cards.length) {
              setCreateNew(false);
            } else {
              setCreateNew(true);
            }
          }

          // RESETA ESTADOS
          setChangePeriod(false);
          setChangeStore(false);
          setChangeUser(false);

          handleSetDisabledFilter(false); // REABILITA OS FILTROS ENQUANTO CHAMA A REQUISIÇÃO
          setLoaded(true);

          // VERIFICA SE O CHAMADOS TEM FILA DE CHECK
          let checkStep_aux = false;
          response.data.map((item, i) => {
            if(item?.tipo_fase === 'Check'){
              checkStep_aux = true;
            }
          });
          setCheckStep(checkStep_aux);
        }).catch(() => {
          // handlePageError(true);
          setLoaded(true);
        });
      });

      // BUSCA NÚMERO DE CARDS EM CADA MÓDULO
      if (props?.chamados && (window.rs_id_lja == 0 || !window.rs_id_lja) && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'master')) {
        get_cardsLength();
      }
    }

    handleSetFirstLoad(false);
  }

  useEffect(() => {
    if(jobsColsAux.length == modules.length){
      setJobsCols(jobsColsAux);
    }
  },[jobsColsAux]);

  // FUNÇÃO PARA FILTRAR SEMPRE QUE SELECIONAR ALGUM FILTRO
  useEffect(() => {
    // RESETA ESTADO DE CARD MAXIMIZADO
    handleSetCardExternal({
      enabled: false
    });
    handleSetPrevIndex('');

    if (!firstLoad) {
      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(true, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar(true);
      }

      if (tipoCalendario == 3) {
        setChangeLayout(true)
      } else {
        setChangeLayout(false);
        if (tipoCalendario != 1) {
          // swiper.slideTo(0)
        }
      }
    }
  }, [tipoCalendario, subTipoCalendario, filterEmpreendimento, filterCategory, filterSubCategory, filterFrequency, filterSystem, filterMonth, filterYear, filterStatus, filterArchived, filterSupervisor, filterAll]);

  // SEMPRE QUE ALTERAR A VIEW PASSADA POR PARAMS, ATUALIZAR O FILTRO DO TIPO
  useEffect(() => {
    if (params['view'] && !firstLoad) {
      if (!props.chamados && !props.fases && !props.visitas) {
        handleSetTipoCalendario({ value: params['view'] });
      }
    }
  }, [params['view']]);

  useEffect(() => {
    // RESETA ESTADO DE CARD MAXIMIZADO
    handleSetCardExternal({
      enabled: false
    });

    if (props.chamados || props.fases || props.visitas) {
      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(true, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar(true);
      }
    }

  }, [filterModule, changeModule]);

  useEffect(() => {
    if (changeUser) {
      // RESETA ESTADO DE CARD MAXIMIZADO
      handleSetCardExternal({
        enabled: false
      });

      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(true, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar(true);
      }
    }
  }, [usuario]);

  useEffect(() => {
    if (changeStore) {
      // RESETA ESTADO DE CARD MAXIMIZADO
      handleSetCardExternal({
        enabled: false
      });

      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(true, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar(true);
      }
    }
  }, [loja]);

  // FUNÇÃO PARA RESETAR FILTROS (NO MOMENTO SÓ UTILIZADA QUANDO TROCAMOS O FILTRO DE EMPREENDIMENTO)
  function resetFilter() {
    if (tipoCalendario == 2) { // SE FOR TIPO LOJA LIMPA O FILTRO DE LOJA
      setLoja([]);
    }

    if (tipoCalendario == 4) { // SE FOR TIPO USUÁRIO LIMPA O FILTRO DE USUÁRIO
      setUsuario([]);
    }

    if (tipoCalendario != 8) { // SE FOR DIFERENTE DE PLANO DE AÇÃO LIMPA O FILTRO DE CATEGORIA (PLANO DE AÇÃO TEM UMA CATEGORIA FIXA PRA TODOS OS EMPREENDIMENTOS)
      setFilterCategory([]);
    }

    setFilterSubCategory([]);
    setFilterFrequency([]);
    setFilterSystem([]);
  }

  // FUNÇÃO PARA FILTRAR SEMPRE QUE SELECIONAR ALGUM FILTRO
  useEffect(() => {
    if (!firstLoad && changePeriod) {
      // RESETA ESTADO DE CARD MAXIMIZADO
      handleSetCardExternal({
        enabled: false
      });

      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(true, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar(true);
      }
    }
  }, [periodStart, periodEnd, changePeriod]);

  // FUNÇÃO PARA RECARREGAR CARD ESPECÍFICO
  function refreshCard(id_job_status) {
    axios({
      method: 'get',
      url: window.host_madnezz + '/systems/integration-react/api/list.php',
      params: {
        do: 'get_select',
        id_job_status: id_job_status
      }
    }).then((response) => {
      //Atualiza a matriz com o registro novo

      if(jobsCols){
        jobsCols.filter((elem) => {
          return elem.data.filter((elem0) => {
            return elem0.group.filter((elem1) => {
              return elem1.cards.filter((elem2, idx) => {
                if (elem2.id_job_status == id_job_status) {
                  return Object.assign(elem2, response?.data[0]);
                } else {
                  return false;
                }
              }).length > 0;
            }).length > 0;
          }).length > 0;
        });
      }else{
        jobs.filter((elem) => {
          return elem.group.filter((elem1) => {
            return elem1.cards.filter((elem2, idx) => {
              if (elem2.id_job_status == id_job_status) {
                return Object.assign(elem2, response?.data[0]);
              } else {
                return false;
              }
            }).length > 0;
          }).length > 0;
        });
      }

      // RECARREGA QUANTIDADE DE CARDS (CHAMADOS)
      if (props?.chamados) {
        get_cardsLength();
      }

      setReloadInternal(true);
      setTimeout(() => {
        setReloadInternal(false);
      }, 1000);
    });
  }

  // FUNÇÃO PARA ENVIAR MENSAGEM
  function set_message(id_job_status, message) {
    axios({
      method: 'post',
      url: window.host_madnezz + '/systems/integration-react/api/list.php?do=set_msg&filter_id_module=' + filterModule,
      data: {
        ativ: 'Enviou uma mensagem',
        id_mov: id_job_status,
        ativ_desc: message,
        // anexos: anexos,
        // nivel_msg: (props?.data?.nivel_msg ? props.data.nivel_msg : '' )
      }
    }).then(() => {

    });
  }

  //OPTIONS DO SELECT TITLE
  var optionsTipos = [
    { value: 1, label: (props.chamados || props.fases ? 'Fila' : 'Calendário') }
  ];

  if (!props.chamados && !props.fases) {
    optionsTipos.push({ value: 4, label: "Usuário" });
  }

  if (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') {
    if (!props.chamados && !props.fases) {
      optionsTipos.push({ value: 7, label: "Frequência" });
      optionsTipos.push({ value: 2, label: "Loja" });
      optionsTipos.push({ value: 3, label: "Painel" });
      optionsTipos.push({ value: 8, label: "Plano de Ação" });
      optionsTipos.push({ value: 9, label: "Modelo" });
    }
  }

  const optionsSubtipos = [
    { value: 'user', label: (props?.chamados ? 'Operadores' : 'Usuários') },
    { value: 'store', label: 'Lojas' },
  ];

  const optionsLojaTipos = [
    { value: 1, label: "Loja" },
    { value: 2, label: "Usuário" },
  ];

  // OPTIONS MESES
  const optionsMonths = [
    { value: 1, label: 'Janeiro' },
    { value: 2, label: 'Fevereiro' },
    { value: 3, label: 'Março' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Maio' },
    { value: 6, label: 'Junho' },
    { value: 7, label: 'Julho' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Setembro' },
    { value: 10, label: 'Outubro' },
    { value: 11, label: 'Novembro' },
    { value: 12, label: 'Dezembro' }
  ]

  // OPTIONS ANO
  var optionsYear = [];
  for (var i = 0; i < 5; i++) {
    optionsYear.push(
      { value: window.currentYear - i, label: window.currentYear - i }
    )
  }

  // PRIMEIRA CHAMADA DOS CARDS
  useEffect(() => {
    if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
      loadCalendar((refresh?.loading === false ? false : true), true, '', refresh?.reload, undefined, modules);
    }else{
      loadCalendar((refresh?.loading === false ? false : true), true, '', refresh?.reload);
    }
  }, [refresh]);

  // CARREGA O CALENDÁRIO SEMPRE QUE O SWIPER É MODIFICADO
  useEffect(() => {
    if (!firstLoad) {
      if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
        loadCalendar(undefined, undefined, undefined, undefined, undefined, modules);
      }else{
        loadCalendar();
      }
    }
  }, [])

  // FILTRA LOJA
  const handleFilterStore = (selectedVals) => {
    setUsuario([]);
    setLoja(selectedVals);
    setChangeStore(true);
  }

  // FILTRA USUÁRIO
  const handleFilterUser = (selectedVals) => {
    setLoja([]);
    setUsuario(selectedVals);
    setChangeUser(true);
  }

  // FILTRA CATEGORIA
  const handleFilterCategory = (selectedVals) => {
    setFilterCategory(selectedVals);
  }

  // FILTRA SUBCATEGORIA
  const handleFilterSubcategory = (selectedVals) => {
    setFilterSubCategory(selectedVals);
  }

  // FILTRA FREQUÊNCIA
  const handleFilterFrequency = (selectedVals) => {
    setFilterFrequency(selectedVals);
  }

  // FILTRA STATUS
  const handleFilterStatus = (selectedVals) => {
    setFilterStatus(selectedVals);
  }

  // FILTRA SUPERVISOR
  const handleFilterSupervisor = (selectedVals) => {
    setFilterSupervisor(selectedVals);
  }

  // FILTRA SISTEMA
  const handleFilterSystem = (selectedVals) => {
    setFilterSystem(selectedVals);
  }

  // DETECTA INÍCIO DO SWIPER
  function reachBeginning() {
    // if(!props.chamados && !props.visitas && !props.fases){
    //   if(tipoCalendario==1){
    //     if(!loadingCards){
    //       setPeriodStart(new Date(subDays(periodStart, 15)));
    //       setPeriodEnd(new Date(subDays(periodStart, 1)));
    //       setPrepend(true);
    //     }
    //   }
    // }
  }

  // DETECTA FIM DO SWIPER
  const reachEnd = () => {
    if (!props.chamados && !props.visitas && !props.fases) {
      if (tipoCalendario == 1) {
        if (!loadingCards && !params['periodEnd']) {
          setPeriodStart(new Date(addDays(periodEnd, 1)));
          setPeriodEnd(new Date(addDays(periodEnd, 15)));
        }
      }
    }
  }

  // DETECTA FIM DO DA TRANSIÇÃO DO SWIPER
  const transitionEnd = () => {
    if (!props.chamados && !props.visitas && !props.fases && !params['periodEnd']) {
      loadCalendar(false, true);
    }
  }

  // FUNÇÕES AO TROCAR O TIPO DE CALENDÁRIO
  function handleSetTipoCalendario(e) {
    setTipoCalendario(e.value); // SETA O TIPO CALENDÁRIO COM O VALOR SELECIONADO

    if (e.value != 2 && e.value != 3) { // SE O CALENDARIO FOR DIFERENTE DO TIPO LOJA (2) E TIPO PAINEL (3), LIMPA O FILTRO DE SUPERVISOR
      setFilterSupervisor([]);
    }

    if (e.value == 1 || e.value == 7 || e.value == 9 || e.value == 4) { // SE O TIPO FOR CALENDARIO (1) OU FREQUÊNCIA (7) OU MODELO (9) OU USUÁRIO (4), REMOVE O FILTRO DE EMPREENDIMENTO
      handleSetFilterEmpreendimento([]);
    }

    if (e.value == 2 || e.value == 3 || e.value == 4) { // SE FOR TIPO LOJA, PAINEL OU USUÁRIO REMOVE O FILTRO DE USUÁRIO
      setUsuario([]);
    }

    if (e.value == 2 || e.value == 4) { // SE FOR TIPO LOJA OU USUÁRIO SETA O PERIODO INICIAL E FINAL NA DATA DE HOJE, E O PAGE PRA 0
      setPeriodStart(new Date());
      setPeriodEnd(new Date());
      setPage(0);
    } else {
      setPeriodEnd(new Date(date.getFullYear(), date.getMonth() + 2, 0));
    }

    if (e.value != 3) { // SE FOR DIFERENTE DE PAINEL REMOVE O FILTRO DE LOJA
      setLoja([]);
    }

    if (e.value == 3) { // SE FOR TIPO PAINEL SETA PERÍODO INICIAL E FINAL NA DATA DE HOJE
      setPeriodStart(new Date(window.currentDate));
      setPeriodEnd(new Date(window.currentDate));
      setSubTipoCalendario('store');
    }

    if (e.value == 7 || e.value == 9) { // FREQÛENCIA OU MODELO
      setPeriodStart('');
      setPeriodEnd('');
    }

    if (e.value == 8) { // PLANO DE AÇÃO
      setFilterMonth(window.currentMonth);
      setFilterYear(window.currentYear);
    } else {
      if (!props.visitas) {
        setFilterMonth('');
        setFilterYear('');
      }
    }

    if (e.value == 7 || e.value == 8) { // SE FOR TIPO FREQUÊNCIA OU PLANO DE AÇÃO REMOVE O FILTRO DE DATA INICIAL E DATA FINAL
      if ((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') || e.value == 7) {
        if (window.rs_id_lja && window.rs_id_lja > 0) { // SE TIVER LOJA ID FILTRA A LOJA DO USUÁRIO
          setLoja([window.rs_id_lja]);
        } else {
          setUsuario([window.rs_id_usr]);
        }
      } else {
        setLoja([]);
        setUsuario([]);
      }

      if (e.value == 8) { // SE FOR TIPO PLANO DE AÇÃO CRAVA A CATEGORIA "PLANO DE AÇÃO"
        setFilterCategory(['2426']);
        setFilterStatus(0);
      }
    }

    if (e.value != 8) { // REMOVE O FILTRO DE STATUS SE NÃO FOR TIPO PLANO DE AÇÃO
      setFilterCategory([]);
      setFilterStatus('');
    }

    loadingCalendar(true); // RECARREGA O CALENDÁRIO

    if (e.value == 1) { // SE FOR TIPO CALENDÁRIO SETA O USUÁRIO LOGADO, DATA DE INÍCIO DO MÊS ATUAL E DATA FINAL 1 MÊS PARA FRENTE
      if ((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker')) {
        setUsuario([]);
        setLoja([window.rs_id_lja]);
      } else {
        setUsuario([window.rs_id_usr]);
        setLoja([]);
      }

      setPeriodStart(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_sub_day', 15)));
      setPeriodEnd(new Date(get_date('date_sql', get_date('date', new Date(date.getFullYear(), date.getMonth(), 1), 'date'), 'date_add_day', 15)));
    }

    if (e.value == 9) { // SE O TIPO FOR MODELO (9) SETA O FILTERMODELO COMO TRUE E LIMPA FILTROS DE USUÁRIO / LOJA
      setFilterModelo(true);
      setUsuario([]);
      setLoja([]);
    } else {
      setFilterModelo(false);
    }

    // RESETA FILTRO DE STATUS
    setFilterStatus([]);
  }

  function handleSetSubTipoCalendario(e) {
    loadingCalendar(true);
    setSubTipoCalendario(e.value);
    setUsuario([]);
    setLoja([]);
  }

  function handleSetLojaTipo(e) {
    setLojaTipo(e.value);
    if (e.value == 1) {
      setUsuario([]);
      setLoja([window.rs_id_lja]);
      setChangeStore(true);
    } else {
      setUsuario([window.rs_id_usr]);
      setLoja([]);
      setChangeUser(true);
    }
  }

  // RECARREGA CARD
  const handleRefreshCard = (e) => {
    refreshCard(e);
  }

  // CHANGE STATUS
  const handleChangeStatus = (e) => {
    // handleSetAutoSwiper(false);

    if (e.id_group) { // CASO TENHA ID GROUP CHAMA A FUNÇÃO PARA ATUALIZAR SOMENTE O CARD ESPECÍFICO
      let message = '';

      if (e.status == 1) {
        message = window.rs_name_usr + ' finalizou um card interno';
      } else if (e.status == 2) {
        message = window.rs_name_usr + ' sinalizou um card interno como "Não tem"';
      } else if (e.status == 3) {
        message = window.rs_name_usr + ' finalizou com atraso um card interno';
      } else if (e.status == 4) {
        message = window.rs_name_usr + ' adiou um card interno';
      }

      refreshCard(e.id_group);
      set_message(e.id_job_status_parent, message);
    } else {
      if (props.chamados || props.visitas) { // SE FOR CHAMADOS OU VISITAS ONDE É NECESSÁRIO ATUALIZAR TODA A FILA
        refreshCalendar();
      } else {
        if (e.status == 1 || e.status == 2 || e.status == 3) { // SE O STATUS FOR FINALIZADO (1), NÃO TEM (2) OU FINALIZADO COM ATRASO (3) RECARREGA CARD ESPECÍFICO
          refreshCard(Number(e.id_job_status));
        } else { // SE O STATUS FOR ADIADO (4) RECARREGA TODOS OS CARDS
          refreshCalendar();
        }
      }

      // handleRefreshChat(true);
    }

    if (e.cod_status) { // VERIFICA SE RECEBEU COD DE STATUS NO RETORNO DA API
      if (e.cod_status === 2) {
        toast('Card finalizado e encaminhado ao setor responsável');
      }
    }
  }

  // FILTRO DE MÊS
  const handleFilterMonth = (e) => {
    setFilterMonth(e);
    setPeriodStart(new Date(filterYear, (e - 1), 1));
    setPeriodEnd(new Date(filterYear, (e - 1), get_date('last_day', (filterYear + '-' + e + '-01'), 'date_sql')));
  }

  // FILTRO DE ANO
  const handleFilterYear = (e) => {
    setFilterYear(e);
    setPeriodStart(new Date(e, (filterMonth - 1), 1));
    setPeriodEnd(new Date(e, (filterMonth - 1), get_date('last_day', (e + '-' + (filterMonth - 1) + '-01'), 'date_sql')));
  }

  // FILTRO DE MÓDULO
  const handleFilterModulo = (e) => {
    handleSetFilterModule(e.value);
    setChangeModule(true);
  }

  // FILTRO DE MÓDULO A PARTIR DO CALLBACK DO COMPONENTE DE QUANTIDADES
  const handleFilterModuloQtd = (e) => {
    handleSetFilterModule(e);
    setChangeModule(true);
  }

  // FUNÇÃO PARA RECARREGAR APÓS CADASTRAR/EDITAR
  const handleRefresh = () => {
    if(modules.includes(filterModule)){ // VERIFICA SE O MÓDULO SELECIONA ESTÁ NO ARRAY DE MÓDULOS QUE ABREM JUNTOS
      loadCalendar(false, false, '', false, true, modules);
    }else{
      loadCalendar(false, false, '', false, true);
    }
  }

  // MANDA ICONS PRO COMPONENTE PAI
  useEffect(() => {
    if (props?.icons) {
        props?.icons('');
    }
  },[filterMonth, filterYear]);

  // CLONA ELEMENTOS DOS FILTROS PRA MANDAR PRO ARQUIVO INDEX
  global.filters = cloneElement(
    <>
      {((props.chamados || props.fases) && window.rs_sistema_id != 238 && (window.rs_id_lja == 0 || !window.rs_id_lja) ? // SE FOR CHAMADOS, E NÃO ESTIVER NO SISTEMA "SUPORTE R" MOSTRA O FILTRO DE MÓDULO
        <SelectReact
          options={optionsModule}
          placeholder="Módulo"
          name="filter_module"
          value={filterModule}
          allowEmpty={false}
          onChange={handleFilterModulo}
        />
        : '')}

      {(!props.chamados && !props.fases && !props.visitas ?
        <SelectReact
          options={optionsTipos}
          name="filter_type"
          value={tipoCalendario}
          onChange={(e) => handleSetTipoCalendario(e)}
          allowEmpty={false}
          hide={window.rs_permission_apl !== 'lojista' && window.rs_permission_apl !== 'operador' ? false : true}
        />
        :
        ''
      )}

      {(optionsEmpreendimentos.length > 1 && ((tipoCalendario != 1 && tipoCalendario != 7 && tipoCalendario != 9 && tipoCalendario != 4) || props.visitas) ?
        <FilterCheckbox
          options={optionsEmpreendimentos}
          placeholder="Empreendimento"
          name="filter_id_emp"
          value={filterEmpreendimento}
          onChangeClose={(e) => (
            handleSetFilterEmpreendimento(e), resetFilter()
          )}
        >
          Empreendimentos
        </FilterCheckbox>
        : '')}

        {(props.chamados || props.visitas || tipoCalendario == 3 ? // CHAMADOS OU PAINEL
          <SelectReact
              options={optionsSubtipos}
              placeholder="Filtrar colunas"
              name="filter_subtype"
              value={subTipoCalendario}
              allowEmpty={false}
              onChange={(e) => handleSetSubTipoCalendario(e)}
          />
        :
          ''
        )}

      {(!props.chamados ?
        <SelectReact
          options={optionsLojaTipos}
          name="filter_store_type"
          defaultValue={1}
          value={optionsLojaTipos[lojaTipo - 1].value}
          onChange={(e) => handleSetLojaTipo(e)}
          allowEmpty={false}
          hide={window.rs_id_lja > 1 && (window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') ? false : true}
        />
        : '')}

      {((!props.chamados && !props.fases && !props?.visitas) || (subTipoCalendario !== 'user' && props?.visitas) ?
        <SelectReact
          options={optionsLoja}
          placeholder="Loja"
          name="filter_id_store"
          value={loja}
          onChange={(e) => (
            setUsuario([]), setLoja(e.value), setChangeStore(true)
          )}
          allowEmpty={false}
          hide={tipoCalendario == 1 || tipoCalendario == 7 || tipoCalendario == 8 || tipoCalendario == 9 ? false : true}
        />
      : '')}

      {((!props.chamados && !props.fases && !props?.visitas) || (subTipoCalendario !== 'store' && props?.visitas) ?
        <SelectReact
          options={optionsUsuario}
          placeholder="Usuário"
          name="filter_id_user"
          defaultValue={window.rs_id_usr}
          value={usuario}
          onChange={(e) => (
            setLoja([]), setUsuario(e.value), setChangeUser(true), setLoaded(false)
          )}
          allowEmpty={false}
          hide={tipoCalendario == 1 || tipoCalendario == 7 || tipoCalendario == 8 || tipoCalendario == 9 ? ((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') ? true : false) : true}
        />
        : '')}

      <FilterCheckbox
        id="filter_store"
        name="filter_store"
        api={{
          url: window.host_madnezz + '/systems/integration-react/api/list.php?do=headerFilter',
          params: {
            filters: [{ filter: 'store' }],
            empreendimento_id: filterEmpreendimento,
            limit: 50,
            np: true
          },
          key: 'store',
          reload: filterEmpreendimento
        }}
        onChangeClose={handleFilterStore}
        hide={(tipoCalendario == 3 && subTipoCalendario == 'store') || tipoCalendario == 2 ? false : (((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker')) || (tipoCalendario != 3 && tipoCalendario != 2) || subTipoCalendario == 'user' ? true : false)}
        value={loja}
      >
        Lojas
      </FilterCheckbox>

      <FilterCheckbox
        name="filter_user"
        options={optionsUsuario}
        onChangeClose={handleFilterUser}
        hide={(tipoCalendario == 3 && subTipoCalendario == 'user') || tipoCalendario == 4 || (props.fases && window.rs_permission_apl === 'master') ? false : (((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador') || tipoCalendario != 3 || tipoCalendario != 4 || subTipoCalendario == 'store' ? true : false))}
        value={usuario}
      >
        Usuários
      </FilterCheckbox>

      {(!props.visitas ?
        <FilterCheckbox
          name="filter_category"
          grupo={(optionsEmpreendimentos.length > 1 ? true : false)}
          api={window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + filterModule}
          onChangeClose={handleFilterCategory}
          value={filterCategory}
          hide={(tipoCalendario == 8 ? true : false)}
        >
          Categorias
        </FilterCheckbox>
        : '')}

      {(!props.visitas ?
        <FilterCheckbox
          name="filter_subcategory"
          grupo={(optionsEmpreendimentos.length > 1 ? true : false)}
          api={{
            url: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_subcategory&empreendimento_id=" + filterEmpreendimento + "&grupo_id=true",
            params: {
              filter_id_category: (tipoCalendario == 8 || !props.chamados && !props.fases && !props.visitas ? filterCategory : ''),
              filter_id_module: filterModule
            },
            reload: filterCategory
          }}
          onChangeClose={handleFilterSubcategory}
          value={filterSubCategory}
        >
          Subcategorias
        </FilterCheckbox>
        : '')}

      {(!props.chamados && !props.fases && !props.visitas ?
        <FilterCheckbox
          name="filter_frequency"
          api={window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_frequency&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + filterModule}
          onChangeClose={handleFilterFrequency}
          value={filterFrequency}
          hide={(tipoCalendario == 8 ? true : false)}
        >
          Frequência
        </FilterCheckbox>
        : '')}

      {(!props.chamados ?
        <FilterCheckbox
          name="filter_status"
          options={window.optionsStatus}
          onChangeClose={handleFilterStatus}
          value={filterStatus}
        >
          Status
        </FilterCheckbox>
        : '')}

      {(props.fases ?
        <SelectReact
          options={[
            {value: 1, label: 'Sim'},
            {value: 2, label: 'Não'}
          ]}
          label="Arquivados"
          name="filter_archived"
          defaultValue={''}
          value={filterArchived}
          allowEmpty={false}
          required={false}
          onChange={(e) => setFilterArchived(e.value)}
        />
      :'')}

      {((tipoCalendario == 2 || tipoCalendario == 3) && window.rs_permission_apl === 'master' ? // SE FOR TIPO LOJA (2) OU PAINEL (3) E NÍVEL DE ACESSO MAIOR QUE GERENTE // master
        <FilterCheckbox
          name="filter_supervisor"
          api={window.host + "/api/sql.php?do=select&component=supervisor_2&grupo_id=true&empreendimento_id=" + filterEmpreendimento}
          onChangeClose={handleFilterSupervisor}
          value={filterSupervisor}
        >
          Regional
        </FilterCheckbox>
      : '')}

      <FilterCheckbox
        options={false}
        name="filter_all"
        value={filterAll}
        onChangeClose={(e) => (
          setFilterAll(e)
        )}
      >
        Procurar
      </FilterCheckbox>

      {(!props.chamados && !props.fases && !props.visitas ?
        <>
          <Input
            type="date"
            name="period_start"
            icon={false}
            required={false}
            value={periodStart}
            onChange={(e) => (
              setPeriodStart(e), setPeriodEnd(e), setChangePeriod(true)
            )}
            hide={tipoCalendario == 2 || tipoCalendario == 4 ? false : true}
          />

          <Input
            type="period"
            name="period_end"
            required={false}
            valueStart={periodStart}
            valueEnd={periodEnd}
            onChangeStart={(e) => (setPeriodStart(e), setChangePeriod(true))}
            onChangeEnd={(e) => (setPeriodEnd(e), setChangePeriod(true))}
            hide={tipoCalendario == 2 || tipoCalendario == 4 || tipoCalendario == 7 || tipoCalendario == 8 || tipoCalendario == 9 ? true : false}
          />
        </>
        : '')}

      {(props.visitas || tipoCalendario == 8 ?
        <>
          <SelectReact
            options={optionsMonths}
            placeholder="Mês"
            name="filter_month"
            defaultValue={''}
            value={filterMonth}
            allowEmpty={false}
            onChange={(e) => (
              handleFilterMonth(e.value)
            )}
          />

          <SelectReact
            placeholder="Ano"
            options={optionsYear}
            value={filterYear}
            allowEmpty={false}
            onChange={(e) => handleFilterYear(e.value)}
          />
        </>
        : '')}
    </>
  )

  // MANDA OS FILTROS PRO COMPONENTE PAI
  useEffect(() => {
    if (props?.icons) {
      props.icons('');
    }

    if (props?.filters) {
      props.filters('');
    }
  }, []);

  // BOTÃO DE AÇÃO (JOBS)
  const handleActionButton = () => {
    navigate('/systems/job-react/calendario/1/'+window.token+'/');
    setFilterStatus([]);
    setFilterCategory([]);
  }

  // SÓ EXECUTA O CÓDIGO DO DASHBOARD DEPOIS QUE TIVER UM MÓDULO SETADO OU NO SISTEMA JOBS
  if (filterModule || (!props.chamados && !props.fases && !props.visitas)) {
    return (
      <>
        {(props.chamados ? <Counter /> : '')}

        {/* SE RECEBER A QUANTIDADE DE CARDS EM CADA MÓDULO */}
        {(cardsLength.length > 0 && props?.chamados && (window.rs_id_lja == 0 || !window.rs_id_lja) && (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') ?
          <div className={style.qtd_cards}>
            <Quantidade
              items={cardsLength}
              disabled={disabledFilter}
              callback={handleFilterModuloQtd}
              check={checkStep}
            />
          </div>
          : '')}

        <div className={(layout == 1 ? "d-block" : "d-none") + ' position-relative'} id="swiper_container">
          {(() => {
            if (props?.visitas) {
              return (
                <Visitas
                  jobs={jobs}
                  widget={props?.widget}
                  optionsModule={optionsModule}
                  refresh={handleRefresh}
                  reloadInternal={reloadInternal}
                  refreshCard={handleRefreshCard}
                  changeStatus={handleChangeStatus}
                  loaded={loaded}
                  usuario={usuario}
                  loja={loja}
                  filters={{
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    tipoCalendario: tipoCalendario,
                    subTipoCalendario: subTipoCalendario,
                    usuario: usuario,
                    loja: loja
                  }}
                />
              )
            } else if (props?.fases) {
              return (
                <Fases
                  jobs={jobs}
                  widget={props?.widget}
                  optionsModule={optionsModule}
                  refresh={handleRefresh}
                  reloadInternal={reloadInternal}
                  refreshCard={handleRefreshCard}
                  changeStatus={handleChangeStatus}
                  loaded={loaded}
                  usuario={usuario}
                  createNew={createNew}
                  filters={{
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    tipoCalendario: tipoCalendario,
                    subTipoCalendario: subTipoCalendario,
                    usuario: usuario,
                    loja: loja
                  }}
                />
              )
            } else if (props?.chamados) {
              return (
                <Chamados
                  jobs={jobs}
                  jobsCols={jobsCols}
                  widget={props?.widget}
                  optionsModule={optionsModule}
                  refresh={handleRefresh}
                  reloadInternal={reloadInternal}
                  refreshCard={handleRefreshCard}
                  changeStatus={handleChangeStatus}
                  loaded={loaded}
                  usuario={usuario}
                  createNew={createNew}
                  filters={{
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    tipoCalendario: tipoCalendario,
                    subTipoCalendario: subTipoCalendario,
                    usuario: usuario,
                    loja: loja
                  }}
                />
              )
            } else {
              return (
                <Jobs
                  jobs={jobs}
                  widget={props?.widget}
                  optionsModule={optionsModule}
                  changeLayout={changeLayout}
                  refresh={handleRefresh}
                  reloadInternal={reloadInternal}
                  refreshCard={handleRefreshCard}
                  changeStatus={handleChangeStatus}
                  reachBeginning={reachBeginning}
                  reachEnd={reachEnd}
                  transitionEnd={transitionEnd}
                  filters={{
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    tipoCalendario: tipoCalendario,
                    subTipoCalendario: subTipoCalendario,
                    usuario: usuario,
                    loja: loja,
                    modelo: filterModelo,
                    status: filterStatus
                  }}
                  actionButton={handleActionButton}
                />
              )
            }
          })()}
        </div>
      </>
    );
  } else {
    if (pageError) {
      return (
        <PageError
          title="Nenhum módulo configurado"
          text={`Tente novamente em alguns minutos.\nCaso o problema persista, entre em contato com o suporte.`}
        />
      )
    }
  }
}
