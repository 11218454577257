import { useEffect, useState } from "react";
import axios from "axios";
import SelectReact from "../../select";
import toast from "react-hot-toast";

export default function TrocaStatus({id_job, id_mov, value, menuPlacement, callback, className}){
    // ESTADOS
    const [options, setOptions] = useState([]);
    const [valueAux, setValueAux] = useState((value ? value : ''));

    //GET OPTIONS
    function get_options(){
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=Job',
            params: {
                do: 'getTable',
                tables: [
                    {table: 'cardStatus'}
                ]
            }
        }).then((response) => {
            if(response?.data?.data?.cardStatus?.data){
                setOptions(response?.data?.data?.cardStatus?.data);
            }
        });
    }

    // SE NÃO TIVER OPTIONS NO SELECT, FAZ A BUSCA NA API
    useEffect(() => {
        if(options.length == 0){
            get_options();
        }
    },[]);

    // ENVIA CALLBACK
    useEffect(() => {
        if(options.length > 0 && callback){
            callback({
                enabled: true
            })
        }
    },[options]);

    // TROCA STATUS
    const handleSetStatus = (e) => {
        setValueAux(e.value);

        axios({
            method: 'post',
            url: window.host_madnezz+'/systems/integration-react/api/list.php?do=set_cardStatusJob',
            data: {
                id_mov: id_mov,
                id_job: id_job,
                id_card_status: e.value,
                ativ_desc: 'Alterou o status'
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            toast('Status alterado');
        })
    }

    // SÓ EXIBE O SELECT SE TIVER OPTIONS PRO EMPREENDIMENTO
    if(options.length > 0){
        return(
            <div className={'mt-2 mb-2 ' + className}>
                <SelectReact
                    label="Status"
                    options={options}
                    value={valueAux}
                    required={false}
                    menuPlacement={menuPlacement}
                    onChange={handleSetStatus}
                />
            </div>
        )
    }
}
