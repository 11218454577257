import { useState, useEffect } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import SelectReact from '../../../components/body/select';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import Form from '../../../components/body/form';
import Icon from '../../../components/body/icon';
import Button from '../../../components/body/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Switch from '../../../components/body/switch';
import { scrollToCol } from '../../../_assets/js/global';

export default function Lojas({emp, callback, type, disabled}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [buttonStatus, setButtonStatus] = useState('');

    // ESTADOS DO FORM
    const [loadingForm, setLoadingForm] = useState(true);
    const [reload, setReload] = useState(false);
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [marca, setMarca] = useState('');
    const [optionsMarca, setOptionsMarca] = useState([]);
    const [regiao, setRegiao] = useState('');
    const [optionsRegiao, setOptionsRegiao] = useState([]);
    const [supervisor, setSupervisor] = useState('');
    const [optionsSupervisor, setOptionsSupervisor] = useState([]);
    const [filial, setFilial] = useState('');
    const [luc, setLuc] = useState('');
    const [email, setEmail] = useState('');
    const [localizacao, setLocalizacao] = useState('');
    const [telefone, setTelefone] = useState('');
    const [fuso, setFuso] = useState('');

    // SETA LOJA ATIVA
    function handleSetActive(id, type){
        let id_aux = (active == id ? '' : id); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO

        setActive(id_aux);
        setEdit(false);
        setPage(0);

        if(callback){
            callback({
                active: id_aux
            })
        }

        // SCROLL AUTOMÁTICO ATÉ A COLUNA DE USUÁRIOS
        scrollToCol('usuarios');
    }

    // RESETA O FILTRO E O ITEM ATIVO CASO TROQUE O GRUPO
    useEffect(() => {
        setActive(null);
        setFilter(null);
        setEdit(false);
        setPage(0);
    },[emp]);

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e)
    }

    // CALLBACK PARA SETAR O PAGE
    const handleSetPage = (e) => {
        setPage(e.page);
    }

    // RESETAR FORM
    function reset_form(){
        setId('');
        setNome('');
        setCnpj('');
        setMarca('');
        setRegiao('');
        setSupervisor('');
        setFilial('');
        setLuc('');
        setEmail('');
        setLocalizacao('');
        setTelefone('');
        setFuso('');   
    }

    // AÇÕES AO ABRIR FORM DE CADASTRO
    const handleOpenForm = (id) => {
        setActive(null);

        if(id){
            setEdit(id);
            setLoadingForm(true);

            axios({
                method: 'get',
                url: window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn',
                params: {
                    columns: [
                        {column: 'store', filter: {id: id}}
                    ]
                }
            }).then((response) => {
                setId(response?.data?.store?.data[0]?.id);
                setNome(response?.data?.store?.data[0]?.nome);    
                setCnpj(response?.data?.store?.data[0]?.cnpj);
                setMarca(response?.data?.store?.data[0]?.id_marca);
                setRegiao(response?.data?.store?.data[0]?.id_regiao);
                setSupervisor(response?.data?.store?.data[0]?.id_supervisor);
                setFilial(response?.data?.store?.data[0]?.id_filial);
                setLuc(response?.data?.store?.data[0]?.luc);
                setEmail(response?.data?.store?.data[0]?.email);
                setLocalizacao(response?.data?.store?.data[0]?.localizacao);
                setTelefone(response?.data?.store?.data[0]?.telefone);
                setFuso(response?.data?.store?.data[0]?.fuso);
                setLoadingForm(false);        
            })
        }else{
            setEdit(true);
            reset_form();
            setLoadingForm(false);
        }

        // SCROLL AUTOMÁTICO ATÉ O FORMULÁRIO DE EDIÇÃO
        scrollToCol('loja_insert');

        callback({
            edit: true
        })
    }

    // AÇÕES AO FECHAR FORM DE CADASTRO
    const handleCloseForm = () => {
        setEdit(false);
        reset_form(); 

        callback({
            edit: false
        })

        setTimeout(() => {
            setLoadingForm(true);
        },200);
    }

    // VERIFICA STATUS DO FORM
    const handleFormStatus = (e) => {
        setButtonStatus(e);
    }

    // RECARREGA LISTA AO RECEBER O RETORNO DA API DE CADASTRO
    const handleReloadForm = (e) => {
        setPage(0);
        setReload(!reload);
    }

    // ATIVAR / DESATIVAR ITEM
    const handleSetItemActive = (id, ativo) => {
        toast('Loja ' + (ativo ? 'ativada' : 'desativada'));

        axios({
            method: 'post',
            url: window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn",
            data: {
                columns: [{
                    column: 'store',
                    params: {
                        id: id,
                        ativo: ativo
                    }
                }]
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(() => {
            // handleReloadForm();
        })
    }

    // FILTRO DE INATIVOS
    const handleSetFilterInactive = () => {
        setFilterInactive(!filterInactive);
        handleReloadForm();
    }

    useEffect(() => {
        // BUSCA MARCAS
        if(optionsMarca.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=marca'
            }).then((response) => {
                setOptionsMarca(response.data);
            });
        }

        // BUSCA REGIÕES
        if(optionsRegiao.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=regiao'
            }).then((response) => {
                setOptionsRegiao(response.data);
            });
        }

        // BUSCA SUPERVISORES
        if(optionsSupervisor.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=supervisor'
            }).then((response) => {
                setOptionsSupervisor(response.data);
            });
        }
    },[edit]);

    // DATA FORM
    const data = {
        columns: [{
            column: 'store',
            params: {
                id: id,
                nome: nome,     
                cnpj: cnpj,
                id_emp: (emp ? emp : window.rs_id_emp),
                id_marca: marca,
                id_regiao: regiao,
                id_supervisor: supervisor,
                id_filial: filial,
                luc: luc,
                email: email,
                localizacao: localizacao,
                telefone: telefone,  
                fuso: fuso,              
                ativo: 1
            }
        }]
    }

    return(
        <>
            <Gerenciador 
                id="lojas"
                title="Lojas"
                search={
                    <Input
                        name="filter_lojas"
                        value={filter}
                        onChange={(e) => (setFilter(e.target.value), setPage(0))}
                    />
                }
                icon={
                    ((!window.rs_id_lja || window.rs_id_lja == 0) && (window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master') ? 
                        <Icon
                            type="new"
                            title="Nova loja"
                            onClick={() => handleOpenForm()}
                        />
                    :'')
                }
                switch={
                    <Input
                        type={'checkbox'}
                        name="lojas_inativas"
                        label="Inativas"
                        inverse={true}
                        onClick={handleSetFilterInactive}
                    />
                }
                disabled={disabled}
            >
                <Table
                    id="table_lojas"
                    api={window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn'}
                    params={{
                        columns: [
                            {column: 'store', filter: {ativo: (filterInactive ? [1,0] : [1]), id_emp: (emp ? emp : window.rs_id_emp), nome: filter, page: page}}
                        ]
                    }}
                    key_aux={'store'}
                    onLoad={handleSetItems}
                    callback={handleSetPage}
                    reload={emp + type + reload}
                    search={filter}
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.map((item, i) => {
                                return(
                                    <Tr
                                        key={'loja_'+item.id}
                                        cursor="pointer"
                                        active={(active === item.id ? true : false)}
                                    >
                                        <Td
                                            onClick={() => handleSetActive(item.id)}
                                        >
                                            {item.nome}
                                        </Td>

                                        <Td width={1} align="center">
                                            {((!window.rs_id_lja || window.rs_id_lja == 0) && (window.rs_permission_apl === 'edicao' || window.rs_permission_apl === 'master') ?
                                                <>
                                                    <Icon 
                                                        type={'edit'}
                                                        active={(edit === item.id ? true : false)}
                                                        onClick={() => handleOpenForm(item.id)}
                                                        animated
                                                    />

                                                    <Switch
                                                        type="check"
                                                        title={(item.ativo == 1 ? 'Desativar' : 'Ativar')}
                                                        checked={(item.ativo == 1 ? true : false)}
                                                        onChange={() => handleSetItemActive(item.id, (item.ativo == 1 ? 0 : 1))}
                                                        animated
                                                    />
                                                </>
                                            :'')}
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>

            {/* FORMULÁRIO DE CADASTRO/EDIÇÃO */}
            {(edit ? 
                <Gerenciador 
                    id="loja_insert"
                    title={(id ? ('Editar '+nome) : 'Nova loja')}
                    search={false}
                    icon={
                        <Icon
                            type="reprovar"
                            title="Fechar"
                            onClick={handleCloseForm}
                        />
                    }
                    disabled={disabled}
                >
                    <Form
                        api={window.host+"/systems/gerenciador/api/settings.php?do=insUpdColumn"}
                        data={data}
                        status={handleFormStatus}
                        response={handleReloadForm}
                        // toast={handleToast}
                    >
                        <Input
                            type="text"
                            name="loja_nome"
                            label="Nome"
                            value={nome}
                            loading={loadingForm}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <Input
                            type="text"
                            name="loja_cnpj"
                            label="CNPJ"
                            mask="99.999.999/0001-99"
                            value={cnpj}
                            loading={loadingForm}
                            onChange={(e) => setCnpj(e.target.value)}
                            required={false}
                        />

                        <SelectReact
                            name="loja_marca"
                            label="Marca"
                            options={optionsMarca}
                            value={marca}
                            loading={loadingForm}
                            onChange={(e) => setMarca(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="loja_regiao"
                            label="Região"
                            options={optionsRegiao}
                            value={regiao}
                            loading={loadingForm}
                            onChange={(e) => setRegiao(e.value)}
                            required={false}
                        />

                        <SelectReact
                            name="loja_supervisor"
                            label="Supervisor"
                            options={optionsSupervisor}
                            value={supervisor}
                            loading={loadingForm}
                            onChange={(e) => setSupervisor(e.value)}
                            required={false}
                        />

                        <Input
                            type="text"
                            name="loja_filial"
                            label="Filial"
                            value={filial}
                            loading={loadingForm}
                            onChange={(e) => setFilial(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="text"
                            name="loja_luc"
                            label="LUC"
                            value={luc}
                            loading={loadingForm}
                            onChange={(e) => setLuc(e.target.value)}
                        />

                        <Input
                            type="email"
                            name="loja_email"
                            label="E-mail"
                            value={email}
                            loading={loadingForm}
                            onChange={(e) => setEmail(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="text"
                            name="loja_localizacao"
                            label="Localização"
                            value={localizacao}
                            loading={loadingForm}
                            onChange={(e) => setLocalizacao(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="tel"
                            name="loja_telefone"
                            label="Telefone"
                            mask={'(99) 9999-99999'}
                            value={telefone}
                            loading={loadingForm}
                            onChange={(e) => setTelefone(e.target.value)}
                            required={false}
                        />

                        <Input
                            type="number"
                            name="loja_fuso"
                            label="Fuso horário"
                            value={fuso}
                            loading={loadingForm}
                            onChange={(e) => setFuso(e.target.value)}
                        />

                        <Button
                            type="submit"
                            loading={loadingForm}
                            status={buttonStatus}
                        >
                            Salvar
                        </Button>
                    </Form>
                </Gerenciador>
            :'')}
        </>
    )
}
