import Modal from "../../../../../components/body/modal";
import ModalBody from "../../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../components/body/modal/modalHeader/modalTitle";
import Form from "../../../../../components/body/form"
import Input from "../../../../../components/body/form/input";
import { useEffect, useState } from "react";
import Button from "../../../../../components/body/button";

export default function ModalDocumento(props) {
    // ESTADOS
    const [nome, setNome] = useState(props?.nome);
    const [obrigatorio, setObrigatorio] = useState(false);
    const [ativo, setAtivo] = useState((props?.ativo === false ? false : true));
    const [anexo, setAnexo] = useState([]);
    const [statusButton, setStatusButton] = useState('');

    //VARIAVEL FECHAR MODAL
    const handleCloseCadastro = (response, submit) => {    
        if(submit){  
            props?.onHide({
                id_job: response.id,
                novo: (props?.id ? false : true),
                obrigatorio: obrigatorio,
                reload: true
            });
        }else{
            props?.onHide(false);
        }        
    };

    // STATUS BOTÃO FORM
    const handleSetButtonState = (e) => {
        setStatusButton(e);
    }

    // SETA ANEXO
    const handleAnexo = (e) => {
        if(e[2] == 'upload'){
            setAnexo(e[0]);
        }else if(e[2] == 'remove'){
            setAnexo([]);
        }
    }

    // VALORES FORMULÁRIO
    const data = {
        id_ite: props?.categoria,
        id: (props?.id ? props.id : ''),
        name: nome,
        aux: anexo,
        required: (obrigatorio ? 1 : 0),
        active: (ativo ? 1 : 0)
    }

    // SETA TITLE DO MODAL
    let title;
    if(props?.type){
        if(props?.type == 'document'){
            title = 'Novo documento';
        }else if(props?.type == 'file'){
            title = 'Novo arquivo';
        }else if(props?.type == 'vistory_initial'){
            title = 'Nova vistoria inicial';
        }else if(props?.type == 'vistory_intermediate'){
            title = 'Nova vistoria intermediária';
        }else if(props?.type == 'vistory_final'){
            title = 'Nova vistoria final';
        }else{
            title = 'Novo documento';
        }
    }

    useEffect(() => {
        setNome(props?.nome);
        setObrigatorio(props?.obrigatorio);
        setAnexo((props?.anexo ? props?.anexo : []));
        setAtivo(1);
    },[props?.show]);

    return (
        <>
            <Modal show={props?.show} handleShow={props?.handleShow} onHide={() => handleCloseCadastro(false)} >
                <ModalHeader>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={window.host_madnezz+"/systems/integration-react/api/list.php?do=set_subcategory"}
                        data={data}
                        toast={(props?.id ? 'Editado' : 'Cadastrado') + ' com sucesso'}
                        response={(e) => handleCloseCadastro(e, true)}
                        status={handleSetButtonState}                    
                    >
                        <Input
                            type="text"
                            name="subcategoria"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <Input
                            type="file"
                            name="anexo"
                            label="Anexo"
                            multiple={false}
                            value={(anexo.length > 0 ? JSON.parse(anexo)[0].id : '')}
                            callback={handleAnexo}
                        />

                        <Input
                            type="checkbox"
                            name="obrigatorio"
                            id="obrigatorio"
                            label="Obrigatório/Fixo"
                            padding={false}
                            checked={obrigatorio}
                            onChange={(e) => setObrigatorio(e.target.checked)}
                            required={false}
                        />

                        {(props?.id ?
                            <Input
                                type="checkbox"
                                name="ativo"
                                id="ativo"
                                label="Ativo"
                                checked={ativo}
                                onChange={(e) => setAtivo(e.target.checked)}
                                required={false}
                            />
                        :'')}

                        <Button
                            type="submit"
                            disabled={(anexo.length > 0 ? false : true)}
                            title={(anexo.length > 0 ? false : 'Anexe algum documento no campo acima')}
                            status={statusButton}
                        >
                            Enviar
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}