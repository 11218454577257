import { useEffect, useState, useContext, useRef } from "react";
import { GerenciadorContext } from "../../../context/Gerenciador";
import Col from "../../../components/body/col";
import Input from "../../../components/body/form/input";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Gerenciador from "../../../components/body/gerenciador";
import Row from "../../../components/body/row";
import ObrasColunaGerenciador from "./Obras";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Global";

export default function GerenciadorObras() {
    // CONTEXT
    const {
        handleAutoScroll
    } = useContext(GerenciadorContext);

    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // RECARREGA A LISTA SEMPRE QUE ENTRA NA PÁGINA
    useEffect(() => {
        handleSetFilter(true);
    },[]);

    // GET PARAMS
    const params = useParams(); 

    // REF
    const scrollElement = useRef();

    //ESTADOS
    const [stores, setStores] = useState([]);
    const [lojaId, setLojaId] = useState('');
    const [searchStore, setSearchStore] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);

    // HANDLE SCROLL ELEMENT
    function handleScrollElement() {
        setTimeout(() => {
            if(scrollElement.current){
                scrollElement.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    function handleSetObrasCol(id) {
        setLojaId(id);
        handleAutoScroll();
        handleScrollElement();
    };

    useEffect(() => {
        // SE TIVER PASSADO ID JÁ VEM SETADO             
        if(params['loja']){
            setLojaId(params['loja']);                
        }
    },[]);

    const handleSetStores = (e) => {
        setStores(e);
        setFirstLoad(false);
    }

    useEffect(() => {
        if(!firstLoad){
            const delay = setTimeout(() => {
                handleSetFilter(true);
            }, 500);
            
            return () => clearTimeout(delay);
        }
    }, [searchStore]);

    return (
        <Row wrap={(window.isMobile ? true : false)}>
            <Col>
                <Gerenciador
                    id="lojas"
                    title="Lojas"
                    search={
                        <Input
                            type="text"
                            value={searchStore}
                            onChange={(e) => setSearchStore(e.target.value)}
                            inverse={true}
                        />
                    }
                >
                    <Table
                        api={window.host + "/api/sql.php?do=select&component=loja"}
                        params={{
                            limit: 50,
                            filter_search: searchStore
                        }}
                        onLoad={handleSetStores}
                    >
                        <Tbody>
                            {(stores.length > 0 ?
                                (stores.map((item) => {
                                    return (
                                        <Tr
                                            cursor="pointer"
                                            key={item.id}
                                            active={(lojaId == item.id ? true : false)}
                                        >
                                            <Td
                                                onClick={() => (
                                                    handleSetObrasCol(item.id)
                                                )}>
                                                {item.nome} <span className="text-secondary">({item.luc})</span>
                                            </Td>
                                        </Tr>
                                    )
                                }))
                            :
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </Gerenciador>
            </Col>

            {(lojaId ?
                <ObrasColunaGerenciador
                    loja={lojaId}
                />
            : 
                <></>
            )}
        </Row>
    )
}