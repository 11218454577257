import { useState, useEffect } from "react";
import Col from "../../../components/body/col";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Loader from "../../../components/body/loader";
import { cd } from "../../../_assets/js/global";
import { useContext } from "react";
import { GlobalContext } from "../../../context/Global";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import axios from "axios";


export default function Lista({ hide, filters, filterMonth, periodStart, periodEnd }) {
    const { handleSetFilter } = useContext(GlobalContext)

    //ESTADOS
    // const [hide, setHide] = useState(true)
    const [apiInfo, setApiInfo] = useState([]);
    const [usuario, setUsuario] = useState('');
    const [rede, setRede] = useState('');
    const [empreendimento, setEmpreendimento] = useState('');
    const [loja, setLoja] = useState('')
    const [responsavel1, setResponsavel1] = useState('');
    const [responsavel2, setResponsavel2] = useState('');
    const [responsavel3, setResponsavel3] = useState('');
    const [responsavel4, setResponsavel4] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [numero, setNumero] = useState('');
    const [diferenca, setDiferenca] = useState('');
    const [inicial1, setInicial1] = useState('');
    const [final1, setFinal1] = useState('');
    const [inicial2, setInicial2] = useState('');

    const [filterData, setFilterData] = useState('');
    const [filterMes, setFilterMes] = useState('');
    const [filterPeriodoInicio, setFilterPeriodoInicio] = useState('')
    const [filterPeriodoFim, setFilterPeriodoFim] = useState('')

    //handle event

    const handleEvent = (setState) => (e) => {
        setState(e)
    }

    //handle target

    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    }

    const handleSetUsuario = (e) => {
        setUsuario(e);
    };

    const handleSetRede = (e) => {
        setRede(e);
    };

    const handleSetEmpreendimento = (e) => {
        setEmpreendimento(e);
        handleSetFilter(true);
    };

    const handleSetLoja = (e) => {
        setLoja(e);
        handleSetFilter(true);
    };

    const handleSetResponsavel1 = (e) => {
        setResponsavel1(e.target.value);
    };
    const handleSetResponsavel2 = (e) => {
        setResponsavel2(e.target.value);
    };
    const handleSetResponsavel3 = (e) => {
        setResponsavel3(e.target.value);
    };
    const handleSetResponsavel4 = (e) => {
        setResponsavel4(e.target.value);
    };
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    };
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    };

    const handleSetNumero = (e) => {
        setNumero(e.target.value);
        handleSetFilter(true);
    };

    const handleSetDiferenca = (e) => {
        setDiferenca(e);
    };

    const handleSetInicial1 = (e) => {
        setInicial1(e);
    };

    const handleSetFinal = (e) => {
        setFinal1(e);
    };

    const handleSetInicial2 = (e) => {
        setInicial2(e);
    };

    const handleSetItems = (e) => {
        setApiInfo(e);
    };

    function getInfo() {
        axios.get(window.host+"/systems/importacao/api/lista_integracao.php?do=get_fechamento", {
            params: {
                loja: loja,
                nome: responsavel1,
                datahora1: (periodStart ? cd(periodStart) : ""),
                datahora2: (periodEnd ? cd(periodEnd) : ""),
                numero: (numero ? (numero.split(",")) : ""),
                mes: (mes ? mes[1] + "/" + mes[2] : '')
            }
        }).then((response) => {
            setApiInfo(response.data)
        })
    }

    //filtros
    useEffect(() => {
        // if (filters) {
        //     filters(
        //         <>
        //             {/* <SelectReact
        //                 value={filterData}
        //                 onChange={(e) => setFilterData(e)}
        //                 options={[
        //                     { value: 1, label: "Mês" }, { value: 2, label: "Período" }
        //                 ]}
        //             /> */}
        //         </>
        //     )
        // }
        getInfo();
    }, [filterMonth, periodStart, periodEnd, loja, numero])

    var mes = filterMonth ? cd(filterMonth).split("/") : '';

    return (
        <Row>
            <Col lg={12} >
                <Table
                    // icon={<Icon type="sad" />}
                    id="fechamento-caixa"
                    //api={"https://dev.madnezz.com.br/systems/importacao/api/lista_integracao.php?do=get_fechamento"}
                    //onLoad={handleSetItems}
                    params={{
                        loja: loja,
                        nome: responsavel1,
                        datahora1: (periodStart ? cd(periodStart) : ""),
                        datahora2: (periodEnd ? cd(periodEnd) : ""),
                        numero: (numero ? (numero.split(",")) : ""),
                        mes: (mes ? mes[1] + "/" + mes[2] : '')
                    }}
                // reload={[filterMonth, periodStart, periodEnd]}
                >
                    <Thead>
                        <Tr>
                            <Th
                            // id="filter-rede"
                            // name="filter-rede"
                            // onChange={handleSetRede}
                            >
                                Rede
                            </Th>
                            <Th
                            // id="filter-empreendimento"
                            // name="filter-empreendimento"
                            // api={window.host + '/api/sql.php?do=select&component=grupo_empreendimento&np=true'}
                            // onChange={handleSetEmpreendimento}
                            >
                                Empreendimento
                            </Th>
                            <Th
                                id="busca_loja"
                                name="busca_loja"
                                //api={window.host + '/api/sql.php?do=select&component=loja&np=true&filial=true'}
                                api={window.host+'/api/sql.php?do=select&component=loja&filial=true'}
                                onChange={handleSetLoja}
                            >
                                Loja
                            </Th>
                            <Th
                            // type="date"
                            // start={{ value: dataInicio, onChange: handleSetDataInicio }}
                            // end={{ value: dataFim, onChange: handleSetDataFim }}
                            >
                                Data
                            </Th>
                            <Th
                                id="filter-numero"
                                name="filter-numero"
                                onChange={handleSetNumero}
                            >
                                Caixa
                            </Th>
                            <Th
                            // id="diferenca"
                            // name="diferenca"
                            // onChange={handleSetDiferenca}
                            >
                                Diferença
                            </Th>
                            <Th>
                                Abertura 1
                            </Th>
                            <Th>
                                Fechamento 1
                            </Th>
                            <Th>
                                Quebra 1
                            </Th>
                            <Th
                                // id="filter-responsavel"
                                // name="filter-responsavel"
                                // //api={window.host + '/api/sql.php?do=select&component=usuario&np=true&filial=true'}
                                // onChange={handleSetResponsavel1}
                                hide={hide}
                            >
                                Responsável 1
                            </Th>
                            <Th>
                                Abertura 2
                            </Th>
                            <Th>
                                Fechamento 2
                            </Th>
                            <Th>
                                Quebra 2
                            </Th>
                            <Th
                                // id="filter-responsavel"
                                // name="filter-responsavel"
                                // //api={window.host + '/api/sql.php?do=select&component=usuario&np=true&filial=true'}
                                // onChange={handleSetResponsavel2}
                                hide={hide}
                            >
                                Responsável 2
                            </Th>
                            <Th>
                                Abertura 3
                            </Th>
                            <Th>
                                Fechamento 3
                            </Th>
                            <Th>
                                Quebra 3
                            </Th>
                            <Th
                                // id="filter-responsavel"
                                // name="filter-responsavel"
                                // //api={window.host + '/api/sql.php?do=select&component=usuario&np=true&filial=true'}
                                // onChange={handleSetResponsavel3}
                                hide={hide}
                            >
                                Responsável 3
                            </Th>
                            <Th>
                                Abertura 4
                            </Th>
                            <Th>
                                Fechamento 4
                            </Th>
                            <Th>
                                Quebra 4
                            </Th>
                            <Th
                                // id="filter-responsavel"
                                // name="filter-responsavel"
                                // //api={window.host + '/api/sql.php?do=select&component=usuario&np=true&filial=true'}
                                // onChange={handleSetResponsavel4}
                                hide={hide}
                            >
                                Responsável 4
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(apiInfo.length > 0 ?
                            apiInfo.map((item, i) => {

                                let diferenca;

                                if (i > 0) {
                                    if (apiInfo[i - 1].fechamento1 !== "-") {
                                        diferenca = (i > 0 && apiInfo[i].abertura1 == apiInfo[i - 1].fechamento1);
                                    } if (apiInfo[i - 1].fechamento2 !== "-") {
                                        diferenca = (i > 0 && apiInfo[i].abertura1 == apiInfo[i - 1].fechamento2);
                                    } if (apiInfo[i - 1].fechamento3 !== "-") {
                                        diferenca = (i > 0 && apiInfo[i].abertura1 == apiInfo[i - 1].fechamento3);
                                    } if (apiInfo[i - 1].fechamento4 !== "-") {
                                        diferenca = (i > 0 && apiInfo[i].abertura1 == apiInfo[i - 1].fechamento4);
                                    }
                                }

                                return (
                                    <Tr key={i} >
                                        <Td>
                                            {item.rede} teste
                                        </Td>
                                        <Td>
                                            {item.empreendimento}
                                        </Td>
                                        <Td>
                                            {item.loja}
                                        </Td>
                                        <Td>
                                            {item.data}
                                        </Td>
                                        <Td>
                                            {item.caixa}
                                        </Td>
                                        <Td className={i == 0 ? "" : diferenca ? "" : "text-danger"} >
                                            {i == 0 ? "Não" : diferenca ? "Não" : "Sim"}
                                        </Td>
                                        <Td >
                                            {item.abertura1}
                                        </Td>
                                        <Td>
                                            {item.fechamento1}
                                        </Td>
                                        <Td>
                                            {item.quebra1}
                                        </Td>
                                        <Td hide={hide} title={item.responsavel1}>
                                            {item.responsavel1}
                                        </Td>
                                        <Td>
                                            {item.abertura2}
                                        </Td>
                                        <Td>

                                            {item.fechamento2}
                                        </Td>
                                        <Td>
                                            {item.quebra2}
                                        </Td>
                                        <Td hide={hide} title={item.responsavel2} >
                                            {item.responsavel2}
                                        </Td>
                                        <Td>
                                            {item.abertura3}
                                        </Td>
                                        <Td>
                                            {item.fechamento3}
                                        </Td>
                                        <Td>
                                            {item.quebra3}
                                        </Td>
                                        <Td hide={hide} title={item.responsavel3} >
                                            {item.responsavel3}
                                        </Td>
                                        <Td>
                                            {item.abertura4}
                                        </Td>
                                        <Td>
                                            {item.fechamento4}
                                        </Td>
                                        <Td>
                                            {item.quebra4}
                                        </Td>
                                        <Td hide={hide} title={item.responsavel4} >
                                            {item.responsavel4}
                                        </Td>
                                    </Tr>
                                )
                            })
                            : <Tr empty={true} ></Tr>
                        )}
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )
}
