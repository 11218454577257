import { useState, useEffect } from 'react';

import Gerenciador from '../../../components/body/gerenciador';
import Input from '../../../components/body/form/input';
import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import { scrollToCol } from '../../../_assets/js/global';

export default function Sistemas({group, emp, store, type, user, user_store, callback, disabled}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(null);
    const [filter, setFilter] = useState(null);
    const [page, setPage] = useState(0);

    // SETA SISTEMA ATIVO
    function handleSetActive(id, id_apl){
        let id_aux = (active == id ? '' : id); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO
        let id_apl_aux = (active == id_apl ? '' : id_apl); // VERIFICA SE O ITEM ESTÁ ATIVO PARA MANDAR O ID VAZIO E RESETAR O FILTRO SE FOR O CASO

        setActive(id_aux);

        if(callback){
            callback({
                systems: items,
                active: id_aux,
                id_apl: id_apl
            })
        }

        // SCROLL AUTOMÁTICO ATÉ A COLUNA DE USUÁRIOS
        scrollToCol((type === 'sistemas' ? 'sistema' : 'permissao'));
    }

    // RESETA O FILTRO E O ITEM ATIVO CASO TROQUE O GRUPO, EMPREENDIMENTO, LOJA OU USUÁRIO
    useEffect(() => {
        setActive(null);
        setFilter('');
    },[group, emp, store, user]);

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e)
    }

    // CALLBACK PARA SETAR O PAGE
    const handleSetPage = (e) => {
        setPage(e.page);
    }

    return(
        <Gerenciador 
            id="sistemas"
            title="Sistemas"
            search={
                <Input
                    name="filter_sistemas"
                    value={filter}
                    onChange={(e) => (setFilter(e.target.value), setPage(0))}
                />
            }
            disabled={disabled}
        >
            <Table
                id="table_sistemas"
                api={window.host+'/systems/gerenciador/api/settings.php?do=fetchColumn'}
                params={{
                    columns: [
                        {column: 'user_permission_system', filter: {id_usuario: user, nome: filter, page: page}}
                    ]
                }}
                key_aux={'user_permission_system'}
                onLoad={handleSetItems}
                callback={handleSetPage}
                reload={user}
                search={filter}
                text_limit={(window.isMobile ? 20 : 30)}
            >
                <Tbody>
                    {(items.length > 0 ?
                        items.map((item, i) => {
                            if(!user_store || (user_store && item?.sistema_loja)){
                                return(
                                    <Tr
                                        key={'sistema_'+item.id}
                                        cursor="pointer"
                                        active={(active === item.id ? true : false)}
                                        onClick={() => handleSetActive(item.id, item?.id_apl)}
                                    >
                                        <Td>
                                            <span className={(item?.permissao_usuario === 0 ? 'text-secondary' : '')}>
                                                {item.nome}
                                            </span>
                                        </Td>
                                    </Tr>
                                )
                            }
                        })
                    :
                        <></>
                    )}
                </Tbody>
            </Table>
        </Gerenciador>
    )
}
