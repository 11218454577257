import { useState, useContext, useEffect } from "react"
import { GlobalContext } from "../../../context/Global";
import Title from "../../../components/body/title";
import SelectReact from "../../../components/body/select";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Tr from "../../../components/body/table/tr";
import Th from "../../../components/body/table/thead/th";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";

export default function Lista() {
    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // CONVERSOR DE DATA
    function cd(date) {
        let dateFormated =
            (new Date(date).getDate() < 10
                ? "0" + new Date(date).getDate()
                : new Date(date).getDate()) +
            "/" +
            (new Date(date).getMonth() + 1 < 10
                ? "0" + (new Date(date).getMonth() + 1)
                : new Date(date).getMonth() + 1) +
            "/" +
            new Date(date).getFullYear();
        return dateFormated;
    }

    //VARIAVEL QUE GUARDA INFORMAÇÕES DA API
    const [info, setInfo] = useState([]);
    const handleSetItems = (e) => {
        setInfo(e);
    }

    //MUDAR INFORMAÇÕES DA TABELA DE ACORDO COM O FILTRO DO TITULO
    //FILTROS DO TITLE
    const [tipo, setTipo] = useState('');
    const [status, setStatus] = useState();
    const [campanha, setCampanha] = useState('');
    //const [pergunta, setPergunta] = useState('');

    //FUNCTION TITLE 
    function changeTipoValue(e) {
        setTipo(e);
    }
    function changeStatusValue(e) {
        setStatus(e);
    }
    function changeCampanhaValue(e) {
        setCampanha(e);
    }

    function changeTableInfo(tipo, status, campanha) {
        setTipo(tipo);
        setStatus(status);
        setCampanha(campanha);
        handleSetFilter(true)
    }

    useEffect(() => {
        changeTableInfo(tipo, status, campanha)
    }, [tipo, status, campanha])

    //ESTATOS ITENS DAS TABELAS
    const [loja, setLoja] = useState('');
    const [cliente, setCliente] = useState('');
    const [telefone, setTelefone] = useState('');
    const [observacao, setObservacao] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    // FILTRO LOJA
    const handleLoja = (e) => {
        setLoja(e);
        handleSetFilter(true);
    }

    const handleCliente = (e) => {
        setCliente(e);
        handleSetFilter(true);
    }

    const handleTelefone = (e) => {
        setTelefone(e);
        handleSetFilter(true);
    }

    const handleObservacao = (e) => {
        setObservacao(e);
        handleSetFilter(true);
    }

    // FILTRO DE DATA (INÍCIO)
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    }

    // FILTRO DE DATA (FIM)
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    }

    const isAsd = (status === 0 && campanha === 28);
    const isDrogal = (status === 0 && campanha === 31);
    const isSuport = (status === 0 && (campanha === 32 || campanha === 33));

    return (
        <>
            <Row>
                <Col>
                    <Title
                        overflow={true}
                        display="block"
                        icon={
                            <>
                                <Icon type="print" />
                                <Icon type="excel" />
                            </>
                        }
                        filter={<>
                            <SelectReact
                                value={tipo}
                                options={[
                                    {
                                        value: 1,
                                        label: "Loja"
                                    },
                                    {
                                        value: 2,
                                        label: "Funcionário"
                                    }
                                ]}
                                onChange={(e) => changeTipoValue(e.value)}
                            />
                            <SelectReact
                                value={status}
                                options={[
                                    {
                                        value: 1,
                                        label: "Ativo"
                                    },
                                    {
                                        value: 0,
                                        label: "Inativo"
                                    }
                                ]}
                                onChange={(e) => changeStatusValue(e.value)}
                            />
                            <SelectReact
                                value={campanha}
                                options={
                                    status === 1 ?
                                        [
                                            {
                                                value: 0,
                                                label: "Campanha",
                                                id: 22,
                                            }
                                        ]
                                        :
                                        [
                                            {
                                                value: 0,
                                                label: "Campanha",
                                                id: 22,
                                            },
                                            {
                                                value: 28,
                                                label: "asd",
                                                id: 22,
                                            },
                                            {
                                                value: 30,
                                                label: "teste Drogal telefones",
                                                id: 24,
                                            },
                                            {
                                                value: 31,
                                                label: "teste Drogal telefones",
                                                id: 25,
                                            },
                                            {
                                                value: 32,
                                                label: "Suporte TI",
                                                id: 26,
                                            },
                                            {
                                                value: 33,
                                                label: "Suporte TI",
                                                id: 27,
                                            }
                                        ]
                                }
                                onChange={(e) => changeCampanhaValue(e.value)}
                            />
                        </>}
                    >
                        Relatório
                    </Title>
                    {(
                        isAsd ?
                            <Title>
                                asd
                            </Title>
                            : " "
                    )}
                    {(
                        isDrogal ?
                            <Title>
                                Ola! Qual o problema encontrado?
                            </Title>
                            : " "
                    )}
                    {(
                        isSuport ?
                            <Title>
                                Ola! Qual o problema apresentado:
                            </Title>
                            : " "
                    )}
                    <Table
                        id="nps"
                        api={window.host + "/systems/nps/api/relatorio.php?do=get&tipo=1&campanha_id=9&pergunta_id=8&token=eyJjaXBoZXJ0ZXh0IjoibGNcLzlFcXo2RERDY01xVmg3K0pQQTUzZTBtWU81R2dSNU5FU21mQ3l5Mmg4YXlyZER6MlVLODAwc1VLdG80WGxzUFQ1UjhJOWloNkRlUTUyc1ZMZGRwemFqekU2QnN0Q0pnR3lkYnBmVEVDTjg1dkFvVTFhWEtUdE11YlpVUGc0RlM4STg2aVN1K3ZmT2g5akJFSlIwVXJNWG1paFwvTmdCSGVFMm1oMnhuRENZelUrVzZOMklkcDRYclFDRDdkZkUrOHdRc3Exb0VuamxDMUdhd3dQZkZEK1BlRGQ2WkRObGNTZTYxKzVJejNYbWR0bFFldTZtWFZcLzZReCtaNjloSDZTNFpZZjlGblpkRThTcmx2Sit3eU9OdEhtXC9zYlNVdlRCVXRLc1pWZk5MSVVvMnRzWlBYZGRmZ3pIVjlHRmVSUG5WSXRENGZldlNma0lXMnluYW11SmpuVnhPM3UxakVTYTVod2daM3pYZXF3bW94eXlOV2E5UUh2b1dBRDRjSCIsIml2IjoiODM0ZGMyOWU0ZjgyMmZhZTBiOTRiNmQ1MjI0NGExMzUiLCJzYWx0IjoiNjNmZjM2NmY2ZjAzZDI1YTZjNWUyNjFlMTJiMWQwNmIzNDk5OTM4MTgwMWQ0ODMyNTNmNjMyYmY0YTI1OGM5NDY1M2NmOWYxNThkMWQ0M2U4ZjMxZGJlZGEyNjQ1ZDM3ZWQzNzhhZTYwNmI5ZWFlMWU1MjI3ZmFjYTJjYjNjZDhjNGYwOGEyZDk2YTY4MDJmMmRjMjZlMWMwNTk5MzY1NTIzZGZmMzNiZTRjNzY4ZTk3MzdhY2JjMDk3MzQzOTMxZjhiMTg2ZGU1YmEwYWVkYmJlNTdjNzljMDRiZDRmNDBkNjg4ZWE2NGNmNDUwZWQ4ZTYzYmFjODg5NmE5ODU1MWJmNjJmM2UwY2IxY2RkY2U0YTEwMGFkMTY3OGYyYzdhMWJhOGRjOGZhNGRjOGFlNDMzZWU1ZjM0NWRhMzczMzk3MzViM2Q2MDZlZWFlY2ZmMzczZGU0OWRiNDJlZTk3YWFjMzA4ZmVkY2I5YzRlYmZlYjYyNjM3MTM3MDE2OWNkMmFkODJhMzQxYzVjNzAwMDczNTM1N2E5N2EwOWM4YTM0MzE5NDhlMDM0NWFhOGQyMGRiNWJmZjFlODJkMzM1MzJiMDQ2MDNkNzFlNmZjY2NhNzI2YmYzN2IxYzQwYzZiODJjYTExZjk4NDQ4MzlmNWVmY2ZhMjljNGQwZDdjMGMiLCJpdGVyYXRpb25zIjo5OTl9"}
                        params={{
                            tipo: tipo,
                            status: status,
                            campanha_id: campanha,
                            //pergunta_id: pergunta,
                            loja: loja,
                            data_inicio: (dataInicio ? cd(dataInicio) : ''),
                            data_fim: (dataFim ? cd(dataFim) : ''),
                            cliente: cliente,
                            telefone: telefone,
                            observacao: observacao,
                        }}
                        onLoad={handleSetItems}
                    >
                        <Thead>
                            <Tr>
                                <Th
                                    name="busca_loja"
                                    api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true"}
                                    onChange={handleLoja}
                                >Loja
                                </Th>
                                <Th>
                                    Campanha
                                </Th>
                                <Th type="date"
                                    start={{ value: dataInicio, onChange: handleSetDataInicio }}
                                    end={{ value: dataFim, onChange: handleSetDataFim }}
                                >Data
                                </Th>
                                <Th
                                    name="busca_cliente"
                                    onChange={handleCliente}
                                >Cliente
                                </Th>
                                <Th
                                    name="busca_telefone"
                                    onChange={handleTelefone}
                                >Telefone
                                </Th>
                                <Th
                                    name="busca_observacao"
                                    onChange={handleObservacao}
                                >Observação
                                </Th>
                                {(
                                    isAsd ?
                                        <>
                                            <Th >1</Th>
                                            <Th >2</Th>
                                            <Th >3</Th>
                                            <Th >4</Th>
                                        </>
                                        : " "
                                )}
                                {(
                                    isDrogal || isSuport ?
                                        <>
                                            <Th >Travamento no computador</Th>
                                            <Th >Internet não funciona</Th>
                                            <Th >Computador não liga</Th>
                                            <Th >Travamento do caixa</Th>
                                            <Th >Outros</Th>
                                        </>
                                        : " "
                                )}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(info.length > 0 ?
                                info.map((item) => {
                                    return (
                                        <Tr>
                                            <Td>{item.loja}</Td>
                                            <Td>{item.campanha}</Td>
                                            <Td>{item.data}</Td>
                                            <Td>{item.cliente}</Td>
                                            <Td>{item.telefone}</Td>
                                            <Td>{item.observacao}</Td>
                                            {(
                                                isAsd ?
                                                    <>
                                                        <Td><Icon type="check" /></Td>
                                                        <Td><Icon type="check" /></Td>
                                                        <Td><Icon type="check" /></Td>
                                                        <Td><Icon type="check" /></Td>
                                                    </>
                                                    : " "
                                            )}
                                            {(
                                                isDrogal || isSuport ?
                                                    <>
                                                        <Td> - </Td>
                                                        <Td> - </Td>
                                                        <Td> - </Td>
                                                        <Td> - </Td>
                                                        <Td> - </Td>
                                                    </>
                                                    : " "
                                            )}
                                        </Tr>
                                    )
                                }
                                )
                                : " ")}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}