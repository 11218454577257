import { useState, useContext } from "react";

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import axios from "axios";
import { useEffect } from "react";
import Icon from "../../../components/body/icon";
import SelectReact from '../../../components/body/select';
import { get_date } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";

export default function Acesso({filters, icons}){
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    // VARIÁVEIS
    var date = new Date();

    // HABILITA O LOADING DA TABELA NOVAMENTE AO TROCAR AS TELAS
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // ESTADOS
    const [thead, setThead] = useState([]);
    const [items, setItems] = useState([]);
    const [usuarios, setUsuarios] = useState([]); // PROVISÓRIO

    // ESTADOS FILTROS
    const [grupo, setGrupo] = useState([]);
    const [empreendimento, setEmpreendimento] = useState(window.rs_id_emp);
    const [departamento, setDepartamento] = useState([]);
    const [responsavel, setResponsavel] = useState([]);
    const [usuario, setUsuario] = useState([]);

    // ESTADOS OPTIONS
    const [optionsGrupo, setOptionsGrupo] = useState([]);
    const [optionsEmpreendimento, setOptionsEmpreendimentos] = useState([]);    

    // SETA ITENS VINDOS DA API
    const handleSetItems = (e) => {
        setItems(e);
    }

    // USUÁRIOS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component=usuario'
        }).then((response) => {
            setUsuarios(response.data);
        });
    },[]);

    // EMPREENDIMENTO
    useEffect(() => {
        let component_aux;

        if(window.rs_id_emp == 26){
            component_aux = 'empreendimento';
        }else{
            component_aux = 'grupo_empreendimento';
        }

        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component='+component_aux
        }).then((response) => {
            setOptionsEmpreendimentos(response.data);
        });
    },[]);

    // GRUPOS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host+'/api/sql.php?do=select&component=grupo'
        }).then((response) => {
            setOptionsGrupo(response.data);
        });
    },[]);

    // ENVIA FILTROS PARA O COMPONENTE PAI
    useEffect(() => {
        if(filters){
            filters(
                <>
                    {(window.rs_id_emp == 26 ?
                        <SelectReact
                            placeholder="Grupo"
                            options={optionsGrupo}
                            onChange={(e) => handleSetGrupo(e)}
                            isRtl={true}
                            value={grupo}
                        />
                    :'')}

                    {(optionsEmpreendimento.length > 1 ?
                        <SelectReact
                            placeholder="Empreendimento"
                            options={optionsEmpreendimento}
                            onChange={(e) => handleSetEmpreendimento(e)}
                            isRtl={true}
                            value={empreendimento}
                        />
                    :'')}
                </>
            );
        }
    },[optionsEmpreendimento, empreendimento, grupo]);

    // GET THEADS
    useEffect(() => {
        axios({
            method: 'get',
            url: window.host_madnezz+'/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess'
        }).then((response) => {
            if(response?.data?.data_aux){
                setThead(response.data.data_aux);
            }
        })
    },[]);

    // FILTRO GRUPO
    const handleSetGrupo = (e) => {
        setGrupo(e.value);
        setEmpreendimento([]);
        handleSetFilter(true);
    }

    // FILTRO EMPREENDIMENTO
    const handleSetEmpreendimento = (e) => {
        setEmpreendimento(e.value);
        setGrupo([]);
        handleSetFilter(true);
    }

    // FILTRO DEPARTAMENTO
    const handleSetDepartamento = (e) => {
        setDepartamento(e);
    }

    // FILTRO RESPONSÁVEL
    const handleSetResponsavel = (e) => {
        setResponsavel(e);
    }

    // FILTRO USUÁRIO
    const handleSetUsuario = (e) => {
        setUsuario(e);
    }

    return(
        <Table
            id="acessos"
            api={window.host_madnezz+'/systems/integration-react/api/request.php?type=System&do=getUserLogSystemAccess'}     
            key_aux_2={'data'}   
            params={{
                filter_id_group: grupo,
                filter_id_emp: [empreendimento],
                filter_id_user: usuario,
                filter_id_department: departamento,
                filter_id_user_responsible_department: responsavel,
                limit: 50
            }}     
            loadAlign="left"
            onLoad={handleSetItems}
        >
            <Thead>
                <Tr>
                    <Th
                        id="filter_departamento"
                        name="filter_departamento"
                        api={{
                            url: window.host+'/api/sql.php?do=select&component=departamento',
                            params: {
                                empreendimento_id: empreendimento
                            }
                        }}
                        onChange={handleSetDepartamento}
                        reload={empreendimento+grupo}
                    >
                        Departamento
                    </Th>

                    <Th
                        id="filter_responsavel"
                        name="filter_responsavel"
                        api={{
                            url: window.host+'/api/sql.php?do=select&component=usuario&ns=false',
                            params: {
                                empreendimento_id: empreendimento
                            }
                        }}
                        reload={empreendimento+grupo}
                        onChange={handleSetResponsavel}
                    >
                        Respon. Depto.
                    </Th>

                    <Th
                        id="filter_usuario"
                        name="filter_usuario"
                        api={{
                            url: window.host+'/api/sql.php?do=select&component=usuario&ns=false',
                            params: {
                                empreendimento_id: empreendimento
                            }
                        }}
                        reload={empreendimento+grupo}
                        onChange={handleSetUsuario}
                    >
                        Usuário
                    </Th>

                    {(thead.map((item, i) => {
                        return(
                            <Th key={'th_'+item.id} minWidth={110} align="center">{item.nome}</Th>
                        )
                    }))}
                </Tr>
            </Thead>

            <Tbody>
                {(items.length > 0 ?
                    items.map((item, i) => {
                        return(
                            <Tr key={'usuario_'+item.id_usuario}>
                                <Td>{(item.departamento ? item.departamento : '-')}</Td>
                                <Td>{(item.responsavel_departamento ? item.responsavel_departamento : '-')}</Td>
                                <Td>{item.usuario}</Td>

                                {(thead.map((item_th, i_th) => {
                                    let key_aux = 'sistema_acesso_'+item?.id_usuario+'_'+i_th;

                                    if(item[item_th.id].usuario_acesso_sistema){
                                        if(item[item_th.id].dataHora_ult_acesso){
                                            return(
                                                <Td align="center" title="Último acesso" key={key_aux}>
                                                    <Icon type="check-circle" className="text-success" readonly title={false} /> 
                                                    {get_date('date', item[item_th.id].dataHora_ult_acesso, 'date_sql').slice(0,5)}
                                                </Td>
                                            )
                                        }else{
                                            return(
                                                <Td align="center" key={key_aux}>
                                                    <Icon type="check-circle" title="Sem registro de acesso" className="text-secondary" readonly />
                                                </Td>
                                            )
                                        }
                                    }else{
                                        return(
                                            <Td align="center" key={key_aux}>
                                                <Icon type="times-circle" title="Sem acesso" className="text-danger" readonly />
                                            </Td>
                                        )
                                    }
                                }))}
                            </Tr>
                        )
                    })
                :
                    <></>
                )}
            </Tbody>
        </Table>
    )
}
