import './reprovar.scss';

import { useState, useContext, useEffect } from 'react';

import Modal from '../../../body/modal';
import ModalHeader from '../../../body/modal/modalHeader';
import ModalTitle from '../../../body/modal/modalHeader/modalTitle';
import ModalBody from '../../../body/modal/modalBody';
import Icon from '../../icon';
import Form from '../../form';
import Textarea from '../../form/textarea';
import Button from '../../button';

import { GlobalContext } from "../../../../context/Global";
import { JobsContext } from '../../../../context/Jobs';
import toast from 'react-hot-toast';
import axios from 'axios';
import SelectReact from '../../select';

export default function Check({chamados, fases, visitas, id_job, id_job_status, tipo, tipo_fase, motivo, callback, modalTitle, icon, title, status, card, modules}){
    // CONTEXT GLOBAL
    const { filterModule } = useContext(GlobalContext);

    // CONTEXT JOBS
    const { configuracoes } = useContext(JobsContext);
    
    // ESTADOS
    const [showModalMotivo, setShowModalMotivo] = useState(false);
    const [showModalEncaminhamento, setShowModalEncaminhamento] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [motivoAux, setMotivoAux] = useState(motivo);
    const [formStatus, setFormStatus] = useState('');
    const [encaminharCheck, setEncaminharCheck] = useState(false);
    const [modulo, setModulo] = useState('');

    // VARIÁVEIS
    const url_api = window.host_madnezz+'/systems/integration-react/api/list.php?do=set_statusSupervisor&filter_id_module='+(card?.id_modulo ? card?.id_modulo : filterModule);
    const url_api_enc = window.host_madnezz + '/systems/integration-react/api/list.php?do=set_module&filter_id_module='+(card?.id_modulo ? card?.id_modulo : filterModule);

    // DEFINE MENSAGEM
    let ativ_desc_aux;
    if(status == 1){
        ativ_desc_aux = 'Aprovou o card';
    }else if(status == 2){
        ativ_desc_aux = 'Reprovou o card';
    }

    // DEFINE MENSAGEM DO ENCAMINHAMENTO DE MÓDULO
    let atic_desc_enc;
    let ativ_desc_enc_aux = 'Encaminhou o card de '+modules.filter((elem) => elem.value == (card?.id_modulo ? card?.id_modulo : filterModule))[0]?.label+' para '+modules.filter((elem) => elem.value == modulo)[0]?.label+'.';
    if(status == 1){ // SE FOR STATUS 2 (REPROVADO) CONCATENA COM O MOTIVO DA REPROVAÇÃO
        atic_desc_enc = 'Aprovado.\n'+ativ_desc_enc_aux
    }else if(status == 2){ // SE FOR STATUS 2 (REPROVADO) CONCATENA COM O MOTIVO DA REPROVAÇÃO
        atic_desc_enc = 'Reprovado: '+observacao+'.\n'+ativ_desc_enc_aux
    }else{
        atic_desc_enc = ativ_desc_enc_aux;
    }    

    // DEFINE ÍCONE
    let icon_aux;
    if(icon){
        icon_aux = icon;
    }else{
        if(status == 1){
            icon_aux = 'check';
        }else if(status == 2){
            icon_aux = 'times-circle';
        }
    }

    // DEFINE TITLE
    let title_aux;
    if(title){
        title_aux = title;
    }else{
        if(status == 1){
            title_aux = 'Aprovar';
        }else if(status == 2){
            title_aux = 'Reprovar';
        }
    }

    // DEFINE CONTEÚDO DO FORM DE MOTIVO
    const data = {
        ativ_desc: ativ_desc_aux,
        id_job: id_job,
        id_mov: id_job_status,
        status: status,
        msg: observacao ? observacao : undefined,
        data_aux: undefined,
        tipo: tipo,
        tipo_fase: tipo_fase ? tipo_fase : 'Check',
        communication: chamados ? 0 : 1,
        location: (global.allowLocation ? (global.lat2 + ',' + global.lon2) : undefined)
    }

    // DEFINE CONTEÚDO DO FORM DE ENCAMINHAMENTO
    const dataEncaminhamento = {
        id_job: id_job,
        id_mov: id_job_status,
        id_modulo: modulo,
        ativ_desc: atic_desc_enc,
        tipo: 'start',
        tipo_fase: 'Início',
        location: (global.allowLocation ? (global.lat2 + ',' + global.lon2) : undefined)
    }

    // FECHAR MODAL DE MOTIVO
    function handleCloseModalMotivo(){
        setShowModalMotivo(false);
        setObservacao('');
    }

    // FECHAR MODAL DE ENCAMINHAMENTO
    function handleCloseModalEncaminhamento(){
        setShowModalEncaminhamento(false);
    }

    // FUNÇÃO PARA ABRIR MODAL DE MOTIVO
    function handleShowModalMotivo(){
        setShowModalMotivo(true);
    }

    // FUNÇÃO PARA ABRIR MODAL DE ENCAMINHAMENTO
    function handleShowModalEncaminhamento(){
        setShowModalEncaminhamento(true);
    }

    // CALLBACK DO FORM DE MOTIVO
    const handleReprova = () => {
        if(callback){
            callback(id_job_status);
        }
    }

    // CALLBACK DO FORM ENCAMINHAMENTO
    const handleCallbackEncaminhamento = () => {
        if(callback){
            callback(id_job_status);
        }
    }

    // ACIONA AÇÕES DO CHECK
    const handleSetCheck = () => {
        if(encaminharCheck){
            handleShowModalEncaminhamento();
        }else{
            if(status == 1){
                axios({
                    method: 'post',
                    url: url_api,
                    data: data,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                }).then(() => {
                    toast('Card aprovado com sucesso');
                    callback(id_job_status);
                });
            }else if(status == 2){
                handleShowModalMotivo();
            }else{
                toast('Erro ao enviar, tente novamente.');
            }
        }        
    }

    // CALLBACK STATUS DO FORM
    const handleFormStatus = (e) => {
        setFormStatus(e);
    }

    // VERIFICA SE O CHECK TEM ENCAMINHAMENTO DE MÓDULO CONFIGURADO
    useEffect(() => {
        if(configuracoes.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_card){
            if(JSON.parse(configuracoes.filter((elem) => elem.conf_tipo === 'card')[0]?.conf_card)?.encaminhar_check === 1){
                setEncaminharCheck(true);
            }
        }
    },[configuracoes]);

    return(
        <>
            {/* MODAL DE MOTIVO */}
            {(motivoAux !== false ?
                <Modal show={ showModalMotivo } onHide={() => handleCloseModalMotivo()}>
                    <ModalHeader>
                        <ModalTitle>
                            {(modalTitle?modalTitle:'Motivo')}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            api={url_api}
                            data={data}
                            toast="Card reprovado"
                            callback={handleReprova}
                            status={handleFormStatus}                  
                        >                            
                            <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => (setObservacao(e.target.value), setMotivoAux(e.target.value))} />

                            <Button
                                type="submit"
                                status={formStatus}
                            >
                                Salvar
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>
            :'')}

            {/* MODAL DE ENCAMINHAMENTO */}
            <Modal show={ showModalEncaminhamento } onHide={() => handleCloseModalEncaminhamento()}>
                <ModalHeader>
                    <ModalTitle>
                        {(card?.job ? card?.job : ' - '+title_aux)}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form
                        api={(modulo ? url_api_enc : url_api)} // SE TIVER MÓDULO SELECIONADO ENVIA A URL PARA TROCA DE MÓDULO
                        data={(modulo ? dataEncaminhamento : data)} // SE TIVER MÓDULO SELECIONADO ENVIA OS CAMPOS PARA TROCA DE MÓDULO
                        callback={handleCallbackEncaminhamento}
                        status={handleFormStatus}   
                    >                            
                        {(status == 2 ?
                            <Textarea name="motivo" placeholder="Observação" value={observacao} onChange={(e) => (setObservacao(e.target.value), setMotivoAux(e.target.value))} />
                        :'')}

                        <SelectReact
                            id="encaminhar_modulo"
                            name="encaminhar_modulo"
                            label="Encaminhar ao módulo"
                            options={modules.filter((elem) => elem.value != card?.id_modulo)}
                            onChange={(e) => setModulo(e.value)}
                            required={false}
                            value={modulo}
                        />

                        <p>
                            OBS: Caso não selecione nenhum módulo, o chamado será finalizado e enviado ao solicitante.
                        </p>

                        <Button
                            type="submit"
                            status={formStatus}
                        >
                            {title_aux}
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Icon
                type={icon_aux}
                title={title_aux}
                animated
                onClick={handleSetCheck}
            />
        </>
    )
}
