import { useState, useContext } from "react";
import { TreinamentoContext } from "../../../context/Treinamento";
import style from './questionario.module.scss';

import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Button from "../components/button";
import Respostas from "./components/respostas";
import { toast } from "react-hot-toast";
import axios from "axios";
import Icon from "../../../components/body/icon";

export default function Questionario(props) {
    // CONTEXT
    const {handleSetView, cursoNome} = useContext(TreinamentoContext);

    // ESTADOS
    const [showRepostas, setShowRespostas] = useState(false);
    const [respostas, setRespostas] = useState([]);
    const [validation, setValidation] = useState(false);
    const [valorTotal, setValorTotal] = useState(0);

    // FUNÇÃO DE SUBMIT
    function handleSubmit(){
        if(validation){
            axios({
                method: 'post',
                url: window.host+'/systems/treinamento/api/lista.php',
                data: {
                    do: 'finalizaQuestionario',
                    loja_id: (window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : ''),
                    usuario_id: window.rs_id_usr,
                    curso: props.quiz.questions[0].curso_id
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                toast('Questionário finalizado com sucesso');
                props.callback(!props.reload);
            }).catch(() => {
                toast('Erro ao finalizar questionário, tente novamente mais tarde');
            });
        }else{
            toast('Responda todas as questões antes de finalizar');
        }
    }

    // SETAR RESPOSTAS
    var arrayRespostas = [];
    const handleSetResposta = (e) => {
        var pontos = 0;
        arrayRespostas.map((resposta, i) => {
            if(resposta.pergunta == e.pergunta){
                arrayRespostas.splice(i, 1);
            }
            pontos = pontos + resposta.pontos;
        });
        arrayRespostas.push(e);

        if(props.quiz.questions.length == arrayRespostas.length){ // VERIFICA SE O A QUANTIDADE DE PERGUNTAS BATE COM A QUANTIDADE DE RESPOSTAS PARA LIBERAR O BOTÃO DE FINALIZAR
            setValidation(true);
        }else{
            setValidation(false);
        }

        if(arrayRespostas[0].status == 'Finalizado'){
            setShowRespostas(true);
        }else{
            setShowRespostas(false);
        }

        setValorTotal(pontos);
    }

    // TENTAR NOVAMENTE
    const handleDoAgain = () => {
        handleSetView(1);
        props.callback(true);
    }

    // VOLTAR
    const handleBack = () => {
        handleSetView(1);
        props.callback(false);
    }

    return (
        <div className={style.container + ' ' + style.quiz}>
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <p
                        className={style.info + ' ms-0 cursor-pointer'}
                        onClick={() => handleBack()}
                    >
                        <Icon type="reabrir" className="me-1" /> Voltar
                    </p>
                </div>
                <div>
                    <p
                        className={style.info + ' me-0 cursor-pointer'}
                        onClick={() => handleDoAgain()}
                    >
                        <Icon type="reabrir2" className="me-1" /> Tentar novamente
                    </p>
                </div>
            </div>

            <div className={style.bg__white}>
                <h2 className={style.info}>
                    {cursoNome}
                </h2>

                <p className={style.info}>Questionário</p>


                {props?.quiz?.questions.map((question, indexQuestion) => {
                    return (
                        <Table
                            key={question.id}
                            className="mb-3"
                            text_limit={false}
                            fixed={false}
                        >
                            <Thead>
                                <Tr>
                                    <Th colspan={(showRepostas ? 1 : 2)} wrap={true}>
                                        {indexQuestion + 1 + ". " + question.nome}
                                    </Th>
                                    {(showRepostas ?
                                        <Th>
                                            Valor: {question.pontos}
                                        </Th>
                                    :'')}
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Respostas                                
                                    question={question}
                                    items={props.quiz.items}
                                    curso={props.curso}
                                    doAgain={props?.doAgain}
                                    reload={props?.reload}
                                    callback={handleSetResposta}
                                />
                            </Tbody>
                        </Table>
                    )
                })}

                {(!showRepostas ?
                    <div className="text-center">
                        <Button
                            onClick={() => handleSubmit()}
                        >Finalizar</Button>
                    </div>     
                :'')}

                {(showRepostas ? 
                    <p className={style.info + ' mt-5'}>
                        Valor total de acertos: {valorTotal}
                    </p>
                :'')}
            </div>
        </div>
    )
}
