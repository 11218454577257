import { useState } from 'react';

import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import { decrypt } from '../../_assets/js/global';
import axios from 'axios';

// PÁGINAS
import Acesso from './Acesso';
import Posvenda from './Posvenda';
import Contrato from './Contrato';

export default function Cockpit(){
  const params = useParams();  
  const paramsUrl = params['*'].substring(params['*'].indexOf('/')+1);
  const paramsUrlCount = (paramsUrl.match(/\//g)?paramsUrl.match(/\//g).length:0);
  var tokenUrl, tokenUrlAux;

  if(paramsUrlCount>0){
    tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
  }else{
    tokenUrl = params['*'].substring(params['*'].indexOf('/')+1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_emp = decryptedReturn.id_emp; 
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;
  window.rs_id_office = decryptedReturn.id_office;

  // FILTROS E ÍCONES
  const [filters, setFilters] = useState(null);
  const [icons, setIcons] = useState(null);

  // SETA FILTROS
  const handleSetFilters = (e) => {
    setFilters(e);
  }

  return (
    <>
      <Navbar
        filters={filters}
      >
        <NavbarLink link={'acesso/'+window.token} name="Acesso/Uso" icon="trend" active={true} />
        <NavbarLink link={'posvenda/'+window.token} name="Pós-Venda" icon="like" active={true} />

        {(!window.producao ?
          <NavbarLink link={'contrato/'+window.token} name="Contrato" icon="document" active={true} />
        :'')}
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index element={ <Navigate to={'acesso/'+window.token} replace /> }/>
          <Route path="acesso/:token" index element={ <Acesso filters={handleSetFilters} /> } />
          <Route path="posvenda/:token" index element={ <Posvenda filters={handleSetFilters} /> } />

          {(!window.producao ?
            <Route path="contrato/:token" index element={ <Contrato filters={handleSetFilters} /> } />
          :'')}
        </Routes>
      </Container>
    </>
  );
}
