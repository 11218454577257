import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ModuleCard from "../../../../components/body/home/moduleCard";
import Jobs from "../../../Jobs/Main";
import News from '../../../News/Lista';
import './style.scss';
import { useEffect, useState } from "react";

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.id });

  // ESTADOS
  const [height, setHeight] = useState('');

  // DEFINE SIZE
  let size;
  if(props.size){
    if(props?.container == 'editor'){
      size = 200;
    }else{      
      size = props.size;
    }    
  }else{
    if(props?.container == 'editor'){
      size = 200;
    }else{
      size = 'calc(16.6666% - 21px)';
    }
  }

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    flexBasis: size,
    width: size,
    height: (props?.dragOver ? height : '100%')
  };

  console.log(transform);

  var heightSize;
  if(document.getElementById('box_'+props?.id)){
    heightSize = document.getElementById('box_'+props?.id).offsetHeight;
  }

  useEffect(() => {
    if(!props.dragOver){
      setTimeout(() => {
        setHeight(document.getElementById('box_'+props?.id).offsetHeight);
      });
    }
  },[heightSize]);

  // SE FOR UM COMPONENTE, INSERE ELE AQUI
  if(props?.component && props?.container != 'editor' && !props?.dragOver){
    return(
      <div id={'box_'+props?.id} className={'component_box ' + (props?.grabCursor ? 'grab' : '')} ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {(props?.component == 'jobs' ?
          <Jobs widget={true} id_apl={223} disabled={(props?.dragEnd ? false : true)} />
        :'')}

        {(props?.component == 'news' ?
          <News widget={true} id_apl={223} disabled={(props?.dragEnd ? false : true)} />
        :'')}
      </div>
    )
  }else{ // SE NÃO FOR, INSERE APENAS O CARD DO ATALHO
    return (
      <div id={'box_'+props?.id} ref={setNodeRef} style={style} {...attributes} {...listeners}>
          {/* {props.children} */}
  
          <ModuleCard
            id={props?.id}
            link={props?.link}
            icon={props?.icon}
            title={props?.title}
            placeholder={props?.placeholder}
            button
            buttonLabel="🡥"
            large={props?.large}
            info={props?.info}
            grabCursor={props?.grabCursor}
          />
      </div>
    );
  }
}
