import { useState, useContext, useEffect } from "react";
import { cd, get_date } from "../../../_assets/js/global";
import { GlobalContext } from "../../../context/Global";
import Row from '../../../components/body/row';
import Table from '../../../components/body/table';
import Tr from '../../../components/body/table/tr';
import Icon from '../../../components/body/icon';
import Tbody from '../../../components/body/table/tbody';
import Td from '../../../components/body/table/tbody/td';
import Historico from "../Historico";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import ModalImgTrade from "./modalImg";
import axios from "axios";
import "./style.css"
import ModalAddImg from "./addImage";
import { toast } from 'react-hot-toast';
import Item from "./item";

export default function Lista({ icons }) {

    //VARIAVEL COM INFOS
    const [info, setInfo] = useState([]);

    //ESTADO EXPANDIR COLUNAS
    const [hide, setHide] = useState(true);

    //ESTADO ITENS DA TABELA
    const [contrato, setContrato] = useState('');
    const [grupo, setGrupo] = useState('');
    const [filial, setFilial] = useState('');
    const [industria, setIndustria] = useState('');
    const [produto, setProduto] = useState('');
    const [tdataInicio, setTDataInicio] = useState('');
    const [tdataFim, setTDataFim] = useState('');
    const [observacao, setObservacao] = useState('');
    const [valor, setValor] = useState('');
    const [numPonto, setNumPonto] = useState('');
    const [codFornecedor, setCodFornecedor] = useState('');
    const [codProduto, setCodProduto] = useState('');
    const [codFase, setCodFase] = useState('');
    const [checkLoja, setCheckDeLoja] = useState('');
    const [fdataInicio, setFDataInicio] = useState('');
    const [fdataFim, setFDataFim] = useState('');
    const [status, setStatus] = useState('');
    //
    const [optionsGrupo, setOptionsGrupo] = useState();
    const [optionsProduto, setOptionsProduto] = useState();
    const [reload, setReload] = useState(false);
    //ESTADOS QUE ARMAZENAM ANO E MÊS DO HANDLE SELECT REACT
    const [date, setDate] = useState();
    const [dataInicio, setDataInicio] = useState(new Date(window.currentYear + '-' + window.currentMonth + '-' + '01'));
    const [dataFim, setDataFim] = useState(new Date(window.currentYear + '-' + window.currentMonth + '-' + window.currentMonthLastDay));

    //modal
    const [show, setShow] = useState(false);
    const [nomeAux, setNomeAux] = useState();
    const [imgAux, setImgAux] = useState();
    //modal add image
    const [idAux, setIdAux] = useState();
    const [showAdd, setShowAdd] = useState(false);

    // abrir modal img
    const handleShow = (img, nome) => (
        setShow(true),
        setImgAux(img),
        setNomeAux(nome)
    )
    // fechar modal img
    const handleClose = () => setShow(false);

    //
    const handleFile = (id) => (
        setShowAdd(true),
        setIdAux(id)
    );
    //
    const handleCloseFile = () => setShowAdd(false);

    //VARIAVEIS DOS FILTROS
    const options_status = [{ value: 2, label: "Checar" }, { value: 3, label: "Avaliar" }, { value: 4, label: "Aprovado" }, { value: 5, label: "Reprovado" }]
    const options_check_loja = [{ value: 2, label: "Conforme" }, { value: 3, label: "Não conforme" }, { value: 4, label: "Não se aplica" }]

    //INFO DA API
    const handleSetItems = (e) => {
        setInfo(e);
    }
    //FILTROS
    //HANDLE EVENT DE ACORDO COM O SETSTATE
    const handleEvent = (setState) => (e) => {
        setState(e)
    }
    //HANDLE EVENT DE ACORDO COM O SETSTATE
    const handleValue = (setState) => (e) => {
        setState(e.value)
    }
    //HANDLE TARGET DE ACORDO COM O SETSTATE
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
        doReload(true);
    }
    //filtro de data
    const handleFilterData = (e) => {
        setDate(e);
        setDataInicio(cd(e));
        doReload(true);
        setDataFim(get_date('date', cd(e), 'date_add_day', 29))
    }

    //OPTIONS GRUPO
    function get_grupo() {
        let obj = [];
        axios.get(window.host + '/systems/trade/api/trade.php?do=get_grupo&page=0&limit=25&filter_search=&_=')
            .then((response) => {
                response.data.map((item, i) => {
                    obj.push({ value: item.id, label: item.nome })
                })
                setOptionsGrupo(obj)
            })
    }

    //OPTIONS GRUPO
    function get_produto() {
        let prdt = [];
        axios.get(window.host + '/systems/trade/api/trade.php?do=get_produtoTrade&page=0&limit=100&_=')
            .then((response) => {
                response.data.map((item, i) => {
                    prdt.push({ value: item.id, label: item.nome })
                })
                setOptionsProduto(prdt)
            })
    }

    useEffect(() => {
        get_grupo();
        get_produto();
    }, [])

    //THEAD
    const thead = [
        { enabled: true, label: 'Contrato', id: 'numerocontrato', name: 'numerocontrato', onChange: handleTarget(setContrato) },
        { enabled: true, label: 'Grupo', id: 'grupo', name: 'grupo', items: optionsGrupo, onChange: handleEvent(setGrupo) },
        { enabled: true, label: 'Filial', id: 'filial', name: 'filial', api: window.host + '/api/sql.php?do=select&component=loja&np=true&filial=true', onChange: handleEvent(setFilial) },
        { enabled: true, label: 'Indústria', id: 'nomefantasiafornecedor', name: 'nomefantasiafornecedor', onChange: handleTarget(setIndustria) },
        { enabled: true, label: 'Produto', id: 'descricaoprodutotrade', name: 'descricaoprodutotrade', items: optionsProduto, onChange: handleEvent(setProduto) },
        {
            enabled: true, label: 'Data', id: 'data_contrato', name: 'data_contrato', type: 'date',
            start: { value: tdataInicio, onChange: handleEvent(setTDataInicio) }, end: { value: tdataFim, onChange: handleEvent(setTDataFim) }
        },

        { enabled: hide ? false : true, label: 'Job', id: 'job', name: 'job', filter: false },
        { enabled: hide ? false : true, label: 'Job Categoria', id: 'job_categoria', name: 'job_categoria', filter: false },
        { enabled: hide ? false : true, label: 'Job Subcategoria', id: 'job_subcategoria', name: 'job_subcategoria', filter: false },
        { enabled: hide ? false : true, label: 'Job Status', id: 'job_status', name: 'job_status', filter: false },
        { enabled: hide ? false : true, label: 'Observação', id: 'obs', name: 'obs', onChange: handleTarget(setObservacao) },
        { enabled: hide ? false : true, label: 'Valor', id: 'valor', name: 'valor', onChange: handleTarget(setValor) },
        { enabled: hide ? false : true, label: 'N° do Ponto', id: 'numeroponto', name: 'numeroponto', onChange: handleTarget(setNumPonto) },
        { enabled: hide ? false : true, label: 'Cód. Fornecedor', id: 'codigofornecedor', name: 'codigofornecedor', onChange: handleTarget(setCodFornecedor) },
        { enabled: hide ? false : true, label: 'Cod. Produto', id: 'codigoprodutotrade', name: 'codigoprodutotrade', onChange: handleTarget(setCodProduto) },
        { enabled: hide ? false : true, label: 'Cod. Fase', id: 'numerofase', name: 'numerofase', onChange: handleTarget(setCodFase) },

        { enabled: true, label: 'Modelo', id: '', name: '', export: false, filter: false },
        { enabled: true, label: 'Check loja', id: '', name: '', export: false, items: options_check_loja, onChange: handleValue(setCheckDeLoja), filter: true },
        {
            enabled: true, label: 'Data Finalizada', id: 'data_foto_loja', name: 'data_foto_loja', type: "date",
            start: { value: fdataInicio, onChange: handleEvent(setFDataInicio) }, end: { value: fdataFim, onChange: handleEvent(setFDataFim) }

        },
        { enabled: true, label: 'Check ADM', id: '', name: '', export: false },
        { enabled: true, label: 'Status', id: 'status_adm_nome', name: 'status_adm_nome', items: options_status, onChange: handleEvent(setStatus) },
    ];

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host + "/systems/trade/api/trade.php?do=get_list";

    // PARAMS API TABLE
    const params = {
        tipo: "loja",
        numerocontrato: contrato,
        grupo: grupo,
        filial: filial,
        nomefantasiafornecedor: industria,
        descricaoprodutotrade: produto,
        data_inicio: (tdataInicio ? cd(tdataInicio) : ''),
        data_fim: (tdataFim ? cd(tdataFim) : ''),
        filtro_data_inicio: (tdataInicio ? cd(tdataInicio) : dataInicio ? cd(dataInicio) : ''),
        filtro_data_fim: (tdataFim ? cd(tdataFim) : dataFim ? cd(dataFim) : ''), 
        obs: observacao,
        valor: valor,
        numeroponto: numPonto,
        codigofornecedor: codFornecedor,
        codigoprodutotrade: codProduto,
        numerofase: codFase,
        check_loja: checkLoja,
        di_i: (fdataInicio ? cd(fdataInicio) : ''),
        df_f: (fdataFim ? cd(fdataFim) : ''),
        status: status
    }
    
    // BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: 'Trade',
        filters: params,
        orientation: 'L',
        and: false
    }    

    const doReload = (res) => setReload(res);

    // MANDA OS FILTROS PRO COMPONENTE PAI
    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="expandir" expanded={!hide} onClick={() => { setHide(!hide) }} />
                    <Icon type="print" />
                    <Icon type="excel" api={{ params: params, body: body }} />
                </>
            )
        }
    }, [info, hide, date])

    return (
        <Row >
            <ModalImgTrade show={show} onHide={handleClose} handleShow={handleShow} img={imgAux} nome={nomeAux} />
            <ModalAddImg show={showAdd} onHide={handleCloseFile} handleShow={handleFile} id={idAux} />
            {/* <Col> */}
            <Table
                id="trade"
                api={url}
                params={params}
                onLoad={handleSetItems}
                thead={thead}
                reload={date + tdataInicio + tdataFim + fdataFim + fdataInicio +reload}
            >
                <Tbody>
                    {(info.length > 0 ?
                        info.map((item) => {
                            var color;

                            if (item.status_adm_nome == "Avaliar") {
                                color = 'blue'
                            } else if (item.status_adm_nome == "Checar") {
                                color = 'gray'
                            } else if (item.status_adm_nome == "Aprovado") {
                                color = 'green'
                            } else if (item.status_adm_nome == "Reprovado") {
                                color = 'red'
                            }

                            let imagem_aux = item?.imgmodelo.split(',');

                            return (
                                <>
                                    <Item
                                        doReload={doReload}
                                        codigofornecedor={item?.codigofornecedor}
                                        codigoprodutotrade={item?.codigoprodutotrade}
                                        color={color}
                                        data_contrato={item?.data_contrato}
                                        data_foto_loja={item?.data_foto_loja}
                                        descricaoprodutotrade={item?.descricaoprodutotrade}
                                        filial={item?.filial}
                                        grupo={item?.grupo}
                                        handleShow={handleShow}
                                        hide={hide}
                                        imagem_aux={imagem_aux}
                                        img_loja={item?.img_loja}
                                        imgmodelo={item?.imgmodelo}
                                        job={item?.job}
                                        job_categoria={item?.job_categoria}
                                        job_status={item?.job_status}
                                        job_subcategoria={item?.job_subcategoria}
                                        nomefantasiafornecedor={item?.nomefantasiafornecedor}
                                        numerocontrato={item?.numerocontrato}
                                        numerofase={item?.numerofase}
                                        numeroponto={item?.numeroponto}
                                        obs={item?.obs}
                                        reload={reload}
                                        status_adm_nome={item?.status_adm_nome}
                                        trade_id={item?.trade_id}
                                        valor={item?.valor}
                                    />
                                </>
                            )
                        })
                        : <></>)}
                </Tbody>
            </Table>
        </Row>
    )
}