import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../context/Global";
import { get_date } from '../../../_assets/js/global.js';
import axios from "axios";

import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Dashboard from "../../../components/body/dashboard";
import Tr from '../../../components/body/table/tr';
import Th from '../../../components/body/table/thead/th';
import Td from '../../../components/body/table/tbody/td';
import CalendarTitle from "../../../components/body/calendarTitle";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import SelectReact from "../../../components/body/select";
import PageError from '../../../components/body/pageError';
import Icon from "../../../components/body/icon";
import { cd } from "../../../_assets/js/global.js";
import Input from "../../../components/body/form/input/index.jsx";
import Chart from "../../../components/body/chart/index.jsx";
export default function DashboardPage(props) {

    // CONTEXT
    const { loadingCalendar, handleSetFirstLoad, handleSetFixFilterModule, handleSetFilter, handleSetFilterModule, filterModule } = useContext(GlobalContext);

    // ESTADOS
    const [filterMonth, setFilterMonth] = useState('');
    const [monthSelected, setMonthSelected] = useState(window.currentMonth);
    const [yearSelected, setYearSelected] = useState(window.currentYear);
    //CONTRATO
    const [dashContrato1, setDashContrato1] = useState([]);
    const [dashContrato2, setDashContrato2] = useState([]);
    const [dashContrato3, setDashContrato3] = useState([]);
    const [orderContrato, setOrderContrato] = useState([{ column: 'contrato', type: 'desc' }]);
    const [orderContrato1, setOrderContrato1] = useState(true);
    const [orderContrato2, setOrderContrato2] = useState(false);
    const [orderContrato3, setOrderContrato3] = useState(false);
    const [orderContrato4, setOrderContrato4] = useState(false);
    const [contratoActive, setContratoActive] = useState('');

    //PRODUTO
    const [dashProduto1, setDashProduto1] = useState([]);
    const [dashProduto2, setDashProduto2] = useState([]);
    const [dashProduto3, setDashProduto3] = useState([]);
    const [orderProduto, setOrderProduto] = useState([{ column: 'produto', type: 'desc' }]);
    const [orderProduto1, setOrderProduto1] = useState(true);
    const [orderProduto2, setOrderProduto2] = useState(false);
    const [orderProduto3, setOrderProduto3] = useState(false);
    const [produtoActive, setProdutoActive] = useState('');
    //LOJA
    const [dashLoja1, setDashLoja1] = useState([]);
    const [dashLoja2, setDashLoja2] = useState([]);
    const [dashLoja3, setDashLoja3] = useState([]);
    const [orderLoja, setOrderLoja] = useState([{ column: 'loja', type: 'desc' }]);
    const [orderLoja1, setOrderLoja1] = useState(true);
    const [orderLoja2, setOrderLoja2] = useState(false);
    const [orderLoja3, setOrderLoja3] = useState(false);
    const [lojaActive, setLojaActive] = useState('');

    const [optionsModule, setOptionsModule] = useState([]);

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
        loadingCalendar(true);
        handleSetFixFilterModule(false);
        handleSetFirstLoad(true);
    }, []);


    // GET RESULTADOS DE ACORDO COM O STATE
    const handleSetDash = (setState) => (e) => {
        if (e.length > 0) {
            setState(e);
        } else {
            setState(
                <Tr empty={true}></Tr>
            )
        }
    }

    //
    const handleMes = (e) => {
        setFilterMonth(e);
        let arr_aux = cd(e).split("/");
        setMonthSelected(arr_aux[1]);
        setYearSelected(arr_aux[2]);
    }

    // ORDENAÇÃO CONTRATO
    function handleOrderContrato(order_1, order_2, order_3, order_4) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'contrato', type: order_1 });
            setOrderContrato1(true);
            setOrderContrato2(false);
            setOrderContrato3(false);
            setOrderContrato4(false);
        }

        if (order_2) {
            order_aux.push({ column: 'industria', type: order_2 });
            setOrderContrato1(false);
            setOrderContrato2(true);
            setOrderContrato3(false);
            setOrderContrato4(false);
        }

        if (order_3) {
            order_aux.push({ column: 'produto', type: order_3 });
            setOrderContrato1(false);
            setOrderContrato2(false);
            setOrderContrato3(true);
            setOrderContrato4(false);
        }

        if (order_4) {
            order_aux.push({ column: 'loja', type: order_4 });
            setOrderContrato1(false);
            setOrderContrato2(false);
            setOrderContrato3(false);
            setOrderContrato4(true);
        }

        setOrderContrato(order_aux);
    }

    // ORDENAÇÃO PRODUTO
    function handleOrderProduto(order_1, order_2, order_3) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'produto', type: order_1 });
            setOrderProduto1(true);
            setOrderProduto2(false);
            setOrderProduto3(false);
        }

        if (order_2) {
            order_aux.push({ column: 'contrato', type: order_2 });
            setOrderProduto1(false);
            setOrderProduto2(true);
            setOrderProduto3(false);
        }

        if (order_3) {
            order_aux.push({ column: 'loja', type: order_3 });
            setOrderProduto1(false);
            setOrderProduto2(false);
            setOrderProduto3(true);
        }

        setOrderProduto(order_aux);
    }

    // ORDENAÇÃO LOJA
    function handleOrderLoja(order_1, order_2, order_3) {
        let order_aux = [];

        if (order_1) {
            order_aux.push({ column: 'loja', type: order_1 });
            setOrderLoja1(true);
            setOrderLoja2(false);
            setOrderLoja3(false);
        }

        if (order_2) {
            order_aux.push({ column: 'contrato', type: order_2 });
            setOrderLoja1(false);
            setOrderLoja2(true);
            setOrderLoja3(false);
        }

        if (order_3) {
            order_aux.push({ column: 'produto', type: order_3 });
            setOrderLoja1(false);
            setOrderLoja2(false);
            setOrderLoja3(true);
        }

        setOrderLoja(order_aux);
    }

    useEffect(() => {
        if (props.icons) {
            props.icons(<></>)
        }
        if (props.filters) {
            props.filters(
                <>
                    {((props.chamados || props.fases) && window.rs_sistema_id != 238 ? // SE FOR CHAMADOS, E NÃO ESTIVER NO SISTEMA "CHAMADOS EMPRESA REACT" MOSTRA O FILTRO DE MÓDULO
                        <SelectReact
                            options={optionsModule}
                            placeholder="Nível"
                            name="filter_module"
                            value={filterModule}
                            onChange={(e) => (
                                handleSetFilterModule(e.value)
                            )}
                        />
                        : '')}

                    {/* <SelectReact
                        placeholder="Mês"
                        options={optionsMonths}
                        value={monthSelected}
                        onChange={(e) => setMonthSelected(e.value)}
                    />

                    <SelectReact
                        placeholder="Ano"
                        options={optionsYear}
                        value={yearSelected}
                        onChange={(e) => setYearSelected(e.value)}
                    /> */}
                    {/* <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={filterMonth}
                        onChange={(e) => handleMes(e)}
                    /> */}

                </>
            )
        }
    }, [monthSelected, yearSelected, filterMonth]);


    let data_aux0
    let data_aux0_final
    let data_aux1
    let data_aux1_final
    let data_aux2
    let data_aux2_final
    if (monthSelected) {
        data_aux0 = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 1)))));
        data_aux0_final = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 28)))));
        data_aux1 = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 1))), 'date_sub_month', 1));
        data_aux1_final = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 28))), 'date_sub_month', 1));
        data_aux2 = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 1))), 'date_sub_month', 2));
        data_aux2_final = (get_date('date', (cd(new Date(yearSelected, monthSelected - 1, 28))), 'date_sub_month', 2));
    }
    
    //EXPORTADOR DE CONTRATOS
    const url_contrato = window.host + '/systems/trade/api/dashboard.php?do=get_contrato';

    const params_contrato = {
        page: 0,
        di_i: data_aux0,
        df_f: data_aux0_final,
        reload: (monthSelected + yearSelected)
    }

    const thead_contratos = {
        numero_contrato: 'Contrato',
        industria: 'Indústria',
        qtd_produto: 'Produto',
        qtd_loja: 'Loja'
    };

    const body_contratos = {
        titles: thead_contratos,
        url: url_contrato,
        name: 'Dashboard - Contratos',
        filters: params_contrato,
        orientation: 'L',
        and: false
    } 

    //EXPORTADOR DE PRODUTOS
    const url_produto = window.host + '/systems/trade/api/dashboard.php?do=get_produto';

    const params_produto = {
        page: 0,
        di_i: data_aux0,
        df_f: data_aux0_final,
        reload: (monthSelected + yearSelected)
    }

    const thead_produtos = {
        produto: 'Produto',
        qtd_contrato: 'Contrato',
        qtd_loja: 'Loja'
    };

    const body_produtos = {
        titles: thead_produtos,
        url: url_produto,
        name: 'Dashboard - Produtos',
        filters: params_produto,
        orientation: 'L',
        and: false
    } 
    
    //EXPORTADOR DE FILIAIS
    const url_filial = window.host + '/systems/trade/api/dashboard.php?do=get_loja';

    const params_filial = {
        page: 0,
        di_i: data_aux0,
        df_f: data_aux0_final,
        reload: (monthSelected + yearSelected)
    }

    const thead_filiais = {
        loja: 'Filial',
        qtd_contrato: 'Contrato',
        qtd_produto: 'Loja'
    };

    const body_filiais = {
        titles: thead_filiais,
        url: url_filial,
        name: 'Dashboard - Filiais',
        filters: params_filial,
        orientation: 'L',
        and: false
    } 

    return (
        <>
            <Row wrap={(window.isMobile ? true : false)}>
                <Col lg={3}>
                    <Chart
                        enabled={true}
                        title="Execução dos contratos"
                        type='PieChart'
                        headers={['Titulo', 'Valor']}
                        body={{
                            type: "custom",
                            content: [["Concluído", 300], ["Concluído com Atraso", 75], ["Atrasado", 50]]
                        }}
                        colors={["#204498", "#ecc242", "#df4a43"]}
                    />
                </Col>
                <Col lg={3}>
                    <Chart
                        enabled={true}
                        title="Execução dos contratos"
                        type='PieChart'
                        headers={['Titulo', 'Valor']}
                        body={{
                            type: "custom",
                            content: [["Aprovados", 300], ["Reprovados", 75]]
                        }}
                        colors={["#204498", "#df4a43"]}
                    />
                </Col>
                <Col lg={5}>
                    <Chart
                        enabled={true}
                        title="Quantidade de contratos vigentes"
                        type="ComboChart"
                        headers={['Título', '']}
                        body={{
                            type: "custom",
                            content: [
                                ["L'Oreal", 5],
                                ['Legrand', 14],
                                ['Marca exclusiva', 33],
                                ['Minas Brasil', 7],
                                ['Oral Next', 12],
                                ['P&G', 26],
                            ]
                        }}
                        colors={["#4294d2"]}
                        height={(window.isMobile ? 400 : 450)}
                    />
                </Col>
            </Row>
            <Row wrap={(window.isMobile ? true : false)}>
                <Col>
                    <Dashboard
                        hidden_expand={true}
                        thead={
                            <Tr>
                                <Th
                                    cursor="pointer"
                                    active={orderContrato1}
                                    onClick={() => handleOrderContrato((orderContrato[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                                >
                                    Contrato
                                    {(orderContrato1 ?
                                        <Icon type={'sort-' + (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderContrato1 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    title="Indústria"
                                    cursor="pointer"
                                    active={orderContrato2}
                                    onClick={() => handleOrderContrato(undefined, (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined)}
                                >
                                    Indústr.
                                    {(orderContrato2 ?
                                        <Icon type={'sort-' + (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderContrato2 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderContrato3}
                                    onClick={() => handleOrderContrato(undefined, undefined, (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                                >
                                    Produto
                                    {(orderContrato3 ?
                                        <Icon type={'sort-' + (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderContrato3 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderContrato4}
                                    onClick={() => handleOrderContrato(undefined, undefined, undefined, (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc'))}
                                >
                                    Loja
                                    {(orderContrato4 ?
                                        <Icon type={'sort-' + (orderContrato[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderContrato4 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Contrato - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: url_contrato,
                                        params: params_contrato
                                    },
                                    tbody: (
                                        dashContrato1.length > 0 ?
                                            dashContrato1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(contratoActive === item.id ? true : false)}
                                                        onClick={() => (setContratoActive(contratoActive === item.id ? '' : item.id), setProdutoActive(''))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashContrato1)

                                },
                                col_2: {
                                    title: 'Contrato -  ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: url_contrato,
                                        params: {
                                            page: 0,
                                            di_i: data_aux1,
                                            df_f: data_aux1_final,
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody: (
                                        dashContrato1.length > 0 ?
                                            dashContrato1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(contratoActive == item.id ? true : false)}
                                                        onClick={() => (setContratoActive(contratoActive === item.id ? '' : item.id), setProdutoActive(''))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashContrato2)
                                },
                                col_3: {
                                    title: 'Contrato -  ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: url_contrato,
                                        params: {
                                            page: 0,
                                            di_i: data_aux2,
                                            df_f: data_aux2_final,
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody: (
                                        dashContrato1.length > 0 ?
                                            dashContrato1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(contratoActive == item.id ? true : false)}
                                                        onClick={() => (setContratoActive(contratoActive === item.id ? '' : item.id), setProdutoActive(''))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.numero_contrato}</Td>
                                                        <Td>{item.industria}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashContrato3)
                                }
                            }
                        }
                        excel={{
                            params_excel: params_contrato,
                            body_excel: body_contratos
                        }}
                    >
                    </Dashboard>
                </Col>

                <Col>
                    <Dashboard
                        hidden_expand={true}
                        thead={
                            <Tr>
                                <Th
                                    cursor="pointer"
                                    active={orderProduto1}
                                    onClick={() => handleOrderProduto((orderProduto[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                                >
                                    Produto
                                    {(orderProduto1 ?
                                        <Icon type={'sort-' + (orderProduto[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderProduto1 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderProduto2}
                                    onClick={() => handleOrderProduto(undefined, (orderProduto[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                                >
                                    Contrato
                                    {(orderProduto2 ?
                                        <Icon type={'sort-' + (orderProduto[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderProduto2 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderProduto3}
                                    onClick={() => handleOrderProduto(undefined, undefined, (orderProduto[0]?.type === 'desc' ? 'asc' : 'desc'))}
                                >
                                    Loja
                                    {(orderProduto3 ?
                                        <Icon type={'sort-' + (orderProduto[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderProduto3 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Produto - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: url_produto,
                                        params: {
                                            page: 0,
                                            di_i: data_aux0,
                                            df_f: data_aux0_final,
                                            filtro_contrato: contratoActive,
                                            reload: (monthSelected + yearSelected + contratoActive)
                                        }
                                    },
                                    tbody: (
                                        dashProduto1.length > 0 ?
                                            dashProduto1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(produtoActive == item.id ? true : false)}
                                                        onClick={() => (setProdutoActive(produtoActive == item.id ? '' : item.id))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashProduto1)
                                },
                                col_2: {
                                    title: 'Produto - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: url_produto,
                                        params: {
                                            page: 0,
                                            di_i: data_aux1,
                                            df_f: data_aux1_final,
                                            filtro_contrato: contratoActive,
                                            reload: (monthSelected + yearSelected + contratoActive)
                                        }
                                    },
                                    tbody: (
                                        dashProduto1.length > 0 ?
                                            dashProduto1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(produtoActive == item.id ? true : false)}
                                                        onClick={() => (setContratoActive(produtoActive == item.id ? '' : item.id))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashProduto2)
                                },
                                col_3: {
                                    title: 'Produto - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: url_produto,
                                        params: {
                                            page: 0,
                                            di_i: data_aux2,
                                            df_f: data_aux2_final,
                                            filtro_contrato: contratoActive,
                                            reload: (monthSelected + yearSelected + contratoActive)
                                        }
                                    },
                                    tbody: (
                                        dashProduto1.length > 0 ?
                                            dashProduto1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        active={(produtoActive == item.id ? true : false)}
                                                        onClick={() => (setContratoActive(produtoActive == item.id ? '' : item.id))}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashProduto3)
                                }
                            }
                        }
                        excel={{
                            params_excel: params_produto,
                            body_excel: body_produtos
                        }}
                    >
                    </Dashboard>
                </Col>

                <Col>
                    <Dashboard
                        hidden_expand={true}
                        thead={
                            <Tr>
                                <Th
                                    cursor="pointer"
                                    active={orderLoja1}
                                    onClick={() => handleOrderLoja((orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined, undefined, undefined)}
                                >
                                    Filial
                                    {(orderLoja1 ?
                                        <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja1 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderLoja2}
                                    onClick={() => handleOrderLoja(undefined, (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                                >
                                    Contrato
                                    {(orderLoja2 ?
                                        <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja2 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                                <Th
                                    align="center"
                                    cursor="pointer"
                                    active={orderLoja3}
                                    onClick={() => handleOrderLoja(undefined, (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc'), undefined)}
                                >
                                    Produto
                                    {(orderLoja3 ?
                                        <Icon type={'sort-' + (orderLoja[0]?.type === 'desc' ? 'asc' : 'desc')} className={(orderLoja3 ? 'text-primary' : '')} />
                                        : '')}
                                </Th>
                            </Tr>
                        }
                        cols={
                            {
                                col_1: {
                                    title: 'Filial - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 0)),
                                    api: {
                                        url: url_filial,
                                        params: {
                                            page: 0,
                                            di_i: data_aux0,
                                            df_f: data_aux0_final,
                                            filtro_contrato: contratoActive,
                                            filtro_produto: produtoActive,
                                            reload: (monthSelected + yearSelected + contratoActive + produtoActive)
                                        }
                                    },
                                    tbody: (
                                        dashLoja1.length > 0 ?
                                            dashLoja1.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                    //active={(contratoActive == item.id ? true : false)}
                                                    // onClick={() => (  setContratoActive(contratoActive == item.id ? '' : item.id) )}
                                                    // cursor="pointer"
                                                    >
                                                        <Td>{item.loja}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_produto}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashLoja1)
                                },
                                col_2: {
                                    title: 'Filial - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 1)),
                                    api: {
                                        url: url_filial,
                                        params: {
                                            page: 0,
                                            di_i: data_aux1,
                                            df_f: data_aux1_final,
                                            filtro_contrato: contratoActive,
                                            filtro_produto: produtoActive,
                                            reload: (monthSelected + yearSelected + contratoActive + produtoActive)
                                        }
                                    },
                                    tbody: (
                                        dashLoja2.length > 0 ?
                                            dashLoja2.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                    //active={(contratoActive == item.id ? true : false)}
                                                    //onClick={() => ( setContratoActive(contratoActive == item.id ? '' : item.id) )}
                                                    // cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashLoja2)
                                },
                                col_3: {
                                    title: 'Filial - ' + get_date('month_name', get_date('date', '01/' + monthSelected + '/' + yearSelected, 'date_sub_month', 2)),
                                    api: {
                                        url: url_filial,
                                        params: {
                                            page: 0,
                                            di_i: data_aux2,
                                            df_f: data_aux2_final,
                                            // filter_month: monthSelected,
                                            // filter_year: yearSelected,   
                                            // reload: (monthSelected + yearSelected)
                                        }
                                    },
                                    tbody: (
                                        dashLoja3.length > 0 ?
                                            dashLoja3.map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        //active={(contratoActive == item.id ? true : false)}
                                                        onClick={() => (
                                                            setContratoActive(contratoActive == item.id ? '' : item.id)
                                                            // setLojaActive(''),
                                                            // setCategoriaActive(''),
                                                            // setSubcategoriaActive(''),
                                                            // setTituloActive('')
                                                        )}
                                                        cursor="pointer"
                                                    >
                                                        <Td>{item.produto}</Td>
                                                        <Td align="center">{item.qtd_contrato}</Td>
                                                        <Td align="center">{item.qtd_loja}</Td>
                                                    </Tr>
                                                )
                                            })
                                            :
                                            <></>
                                    ),
                                    callback: handleSetDash(setDashLoja3)
                                },
                            }
                        }
                        excel={{
                            params_excel: params_filial,
                            body_excel: body_filiais
                        }}
                    >
                    </Dashboard>
                </Col>
            </Row>
        </>
    )
}
