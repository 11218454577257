import { useState } from "react";

import axios from "axios";
import { toast } from "react-hot-toast";
import Icon from "../../../../../components/body/icon";
import Td from "../../../../../components/body/table/tbody/td";
import Tr from "../../../../../components/body/table/tr";
import { useEffect } from "react";

export default function Respostas(props){
    // ESTADOS
    const [respostasSelecionadas, setRespostasSelecionadas] = useState([]);
    const [respostasCertas, setRespostasCertas] = useState([]);
    const [showRespostas, setShowRespostas] = useState(false);

    // FUNÇÃO PARA SETAR RESPOSTA
    function handleSetResposta(pergunta, item){
        axios({
            method: 'post',
            url: window.host+'/systems/treinamento/api/lista.php',
            data: {
                do: 'respondeQuestionario',
                loja_id: (window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : ''),
                usuario_id: window.rs_id_usr,
                pergunta: pergunta,
                curso: props.curso,
                item: item
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(() => {
            toast("Resposta registrada");
            props.callback({pergunta: pergunta, resposta: item});
            handleGetRespostas();
        });
    }

    // FUNÇÃO DE GET PARA RESPOSTAS
    function handleGetRespostas(){
        axios({
            method: 'post',
            url: window.host+'/systems/treinamento/api/lista.php',
            data: {
                do: 'questionarioUsuario',
                curso: props.curso,
                usuario_id: window.rs_id_usr,
                loja_id: (window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : ''),                
                data_fim: '',                
                doAgain: props?.doAgain
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if(response.data){
                if(response.data.rows_questions.length > 0){ // VERIFICA SE JÁ EXISTEM RESPOSTAS SELECIONADAS
                    let respostas = [];
                    let respostas_certas = [];

                    response.data.rows_questions.map((resposta) => {
                        respostas.push(resposta.item_id);
                        respostas_certas.push(resposta.item_certo);

                        props.callback({
                            pergunta: resposta.pergunta_id,
                            resposta: resposta.item_id,
                            resposta_certa: resposta.item_certo,
                            pontos: (resposta.item_id == resposta.item_certo ? resposta.pontos : 0),
                            status: response.data.row_user[0].status
                        });
                    });
                    setRespostasSelecionadas(respostas);
                    setRespostasCertas(respostas_certas);
                }else{
                    setRespostasSelecionadas([]);
                    setRespostasCertas([]);
                }

                if(response.data.row_user[0].status == 'Finalizado'){
                    setShowRespostas(true);
                }else{
                    setShowRespostas(false);
                }
            }
        });
    }

    // GET INICIAL DAS RESPOSTAS
    useEffect(() => {
        handleGetRespostas();
    },[props.reload]);

    // VARIAVEL PAR AIDENTIFICAR POSIÇÃO DAS RESPOSTAS
    let posicao = 0;

    return(
        <>
            {props.items.map((item, i) => {  
                if (item.pergunta_id === props.question.id) {     
                    let bgColor = '';
                    let textColor = '';

                    if(showRespostas){
                        if(respostasSelecionadas.includes(item.id)){
                            if(respostasCertas.includes(item.id)){
                                bgColor = 'bg-success';
                                textColor = 'text-white';
                            }else{
                                bgColor = 'bg-danger';
                                textColor = 'text-white';
                            }
                        }else{
                            bgColor = '';
                            textColor = '';
                        }
                    }else{
                        if(respostasSelecionadas.includes(item.id)){
                            bgColor = 'bg-primary';
                            textColor = 'text-white';
                        }else{
                            bgColor = '';
                            textColor = '';
                        }
                    }

                    return(   
                        <Tr
                            className={bgColor}
                            key={item.id}
                        >
                            <Td className={textColor}>
                                {String.fromCharCode(65 + (posicao++)) + ") " + item.nome}
                            </Td>
                            <Td
                                width={1}
                                align="end"
                            >
                                <Icon
                                    type="check"
                                    className={textColor}
                                    onClick={() => handleSetResposta(item.pergunta_id, item.id)}
                                    disabled={(showRespostas && !props.doAgain ? true : false)}
                                    title={(showRespostas && !props.doAgain ? false : 'Assinalar alternativa')}
                                />
                            </Td>
                        </Tr>
                    )
                }
            })}
        </>
    )
}