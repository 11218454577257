import { useEffect, useState } from "react";
import Row from "../../../../components/body/row";
import Gerenciador from "../../../../components/body/gerenciador";
import Input from "../../../../components/body/form/input";
import Icon from "../../../../components/body/icon";
import axios from "axios";
import Table from "../../../../components/body/table";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Switch from "../../../../components/body/switch";
import SelectReact from "../../../../components/body/select";
import CadastroContrato from "./cadContrato";
import { toast } from "react-hot-toast";

export default function GerenciadorSistema({ industria_id }) {

    //GERENCIADOR DE CONTRATO
    const [contrato, setContrato] = useState('');
    const [searchContrato, setSearchContrato] = useState();
    const [inativosContrato, setInativosContrato] = useState();
    const [editContrato, setEditContrato] = useState(false);
    const [contratoSelected, setContratoSelected] = useState();
    const [ano, setAno] = useState(window.currentYear)
    //editar contrato    
    const [contratoId, setContratoId] = useState();
    const [contratoNome, setContratoNome] = useState();
    const [contratoInicio, setContratoInicio] = useState();
    const [contratoFim, setContratoFim] = useState();
    const [contratoLojas, setContratoLojas] = useState();
    const [contratoValor, setContratoValor] = useState();
    const [contratoDescricao, setContratoDescricao] = useState();
    const [contratoImagem, setContratoImagem] = useState();
    //GERENCIADOR DE PRODUTO
    const [produto, setProduto] = useState('');
    const [searchProduto, setSearchProduto] = useState('');
    const [inativosProduto, setInativosProduto] = useState('');
    const [editProduto, setEditProduto] = useState(false);
    const [produtoId, setProdutoId] = useState('');
    const [produtoNome, setProdutoNome] = useState('');
    const [produtoSelected, setProdutoSelected] = useState();


    //OPÇÕES ANO PRO SELECT CONTRATO
    const options_ano = [
        { value: 0, label: "Ano" },
        { value: 2018, label: "2018" },
        { value: 2019, label: "2019" },
        { value: 2020, label: "2020" },
        { value: 2021, label: "2021" },
        { value: 2022, label: "2022" },
        { value: 2023, label: "2023" },
        { value: 2024, label: "2024" },
        { value: 2025, label: "2025" },
    ]

    //GERENCIADOR LOJA/FILIAL
    const [filial, setFilial] = useState();


    //PEGAR LISTA DE CONTRATO DE ACORDO COM O ID DA INDUSTRIA
    function getContrato() {
        axios.get(window.host + '/systems/trade/api/gerenciador-beta.php?', {
            params: {
                do: 'contrato',
                ativo: 1,
                ano: ano,
                industria_id: industria_id,
                _: ''
            }
        }).then((response) => {
            setContrato(response.data)
        })
    }
    useEffect(() => {
        getContrato()
    }, [industria_id, ano]);
    //
    const handleContrato = (id, nome, inicio, fim, loja, valor, imagem, descricao) => (
        setEditContrato(true),
        setContratoId(id),
        setContratoNome(nome),
        setContratoInicio(inicio),
        setContratoFim(fim),
        setContratoLojas(loja),
        setContratoValor(valor),
        setContratoImagem(imagem),
        setContratoDescricao(descricao),
        setContratoSelected('')
    )
    //
    const callback_contrato = (res) => (setEditContrato(res), getContrato());
    function updateContrato(id, status) {
        let res
    }


    //PEGAR LISTA DE PRODUTO, DE ACORDO COM O ID CONTRATO
    function getProduto() {
        axios.get(window.host + '/systems/trade/api/gerenciador-beta.php?', {
            params: {
                do: 'produto',
                ativo: 1,
                tipo: 1,
                ano: ano,
                industria_id: industria_id,
                contrato_id: contratoSelected,
                _: ''
            }
        }).then((response) => {
            setProduto(response.data)
        })
    }
    useEffect(() => {
        getProduto()
    }, [produtoSelected, ano]);

    //
    const handleProduto = (id, nome) => (
        setEditProduto(true),
        setProdutoId(id),
        setProdutoNome(nome),


        setContratoSelected('')
    )

    //ATIVOS/INATIVOS -> PASSANDO A TABELA POR PARAMETRO 
    function updateAtivos(id, status, table) {
        let res = status == 0 ? 1 : 0;
        axios({
            method: "post",
            url: window.host + "/systems/trade/api/gerenciador-beta.php",
            headers: { "Content-Type": "multipart/form-data" },
            data: {
                do: 'ativo',
                tipo: table,
                id: id,
                status: res
            },
        }).then(() => {
            if (table == "contrato") {
                getContrato();
                if (res == 0) {
                    toast("Contrato desativado com sucesso!")
                } else if (res == 1) {
                    toast("Contrato ativado com sucesso!")
                };
                //setSelected('');
            } else if (table == "produto") {
                getProduto();
                if (res == 0) {
                    toast("Produto desativado com sucesso!")
                } else if (res == 1) {
                    toast("Produto ativada com sucesso!")
                };
            };
        })
    };

    //PEGAR LISTA DE PRODUTO, DE ACORDO COM O ID CONTRATO
    function getLoja() {
        axios.get(window.host + '/systems/trade/api/gerenciador-beta.php?', {
            params: {
                do: 'loja',
                ativo: 1,
                ano: ano,
                industria_id: industria_id,
                contrato_id: contratoSelected,
                produto_id: produtoSelected,
                _: ''
            }
        }).then((response) => {
            setFilial(response.data)
        })
    }
    useEffect(() => {
        getLoja()
    }, [industria_id, produtoSelected]);

    return (
        <>
            <Gerenciador
                id="contrato"
                title={<>
                    Contrato
                    <SelectReact
                        value={ano}
                        onChange={(e) => setAno(e.value)}
                        options={options_ano}
                        required={false}
                    />
                </>}
                icon={<Icon type="new" onClick={() => handleContrato()} />}
                search={<Input placeholder="Pesquisar" value={searchContrato} onChange={(e) => setSearchContrato(e.target.value)} />}
                switch={
                    <Input
                        type="checkbox"
                        id="check_inativos_contrato"
                        label="Mostrar Inativos"
                        inverse={true}
                        onChange={(e) => (setInativosContrato(e.target.checked))}
                    />
                }
            >
                <Table>
                    {
                        contrato &&
                        contrato.filter((item) => {
                            if (!searchContrato) return true;
                            if (item.nome.toLowerCase().includes(searchContrato.toLowerCase())) {
                                return true
                            }
                        }).map((item, i) => {
                            return (
                                <Tr
                                    key={item.id}
                                    active={(contratoSelected === item.id ? true : false)}
                                    cursor='pointer'
                                >
                                    <Td

                                        onClick={() => (setContratoSelected(contratoSelected == item.id ? '' : item.id), setEditContrato(false))}
                                    >
                                        {item.nome}
                                    </Td>
                                    <Td align="end">
                                        <Icon
                                            type="edit"
                                            //animated
                                            onClick={() => handleContrato(
                                                item.id, item.nome, item.data_inicio, item.data_fim,
                                                item.quantidade, item.valor, item.imgmodelo, item.descricao
                                            )} />
                                        <Switch
                                            checked={item?.ativo === 1 ? true : false}
                                            onChange={() => updateContrato(item.id, item.ativo)}
                                        />
                                    </Td>
                                </Tr>
                            )
                        })
                    }
                </Table>
            </Gerenciador>
            {
                editContrato &&
                <CadastroContrato
                    industria_id={industria_id}
                    id={contratoId}
                    nome_api={contratoNome}
                    data_inicio={contratoInicio}
                    data_fim={contratoFim}
                    num_lojas={contratoLojas}
                    imagem_api={contratoImagem}
                    descricao_api={contratoDescricao}
                    valor_api={contratoValor}
                    callback={callback_contrato}
                />
            }

            {
                contratoSelected &&
                <>
                    <Gerenciador
                        id="produto"
                        title="Produto"
                        autoScroll={true}
                        icon={
                            <>
                                {/* <Icon type="sad" /> */}
                                <Icon type="new" />
                            </>
                        }
                        search={<Input placeholder="Pesquisar" value={searchProduto} onChange={(e) => setSearchProduto(e.target.value)} />}
                        switch={
                            <Input
                                type="checkbox"
                                id="check_inativos"
                                label="Mostrar Inativos"
                                inverse={true}
                                onChange={(e) => (setInativosProduto(e.target.checked))}
                            />
                        }
                    >
                        <Table>
                            {
                                produto &&
                                produto.filter((item) => {
                                    if (!searchProduto) return true;
                                    if (item.nome.toLowerCase().includes(searchProduto.toLowerCase())) {
                                        return true
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Tr
                                            key={item.id}
                                            active={(produtoSelected === item.id ? true : false)}
                                            cursor='pointer'
                                        >
                                            <Td
                                                onClick={() => (setProdutoSelected(produtoSelected == item.id ? '' : item.id), setEditProduto(false))}
                                            >
                                                {item.nome}
                                            </Td>
                                            <Td align="end">
                                                <Icon
                                                    type="edit"
                                                    //animated
                                                    onClick={() => handleProduto(item.id, item.nome)} />
                                                <Switch
                                                    checked={item?.ativo === 1 ? true : false}
                                                    onChange={() => updateContrato(item.id, item.ativo)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Table>

                    </Gerenciador>

                    {
                        contratoSelected && produtoSelected &&
                        <Gerenciador
                            id="produto"
                            title={`Filial (${filial?.length}/10)`}
                            autoScroll={true}
                        //icon={<> <Icon type="sad" />  <Icon type="new" /> </>}
                        // search={<Input placeholder="Pesquisar" value={searchProduto} onChange={(e) => setSearchProduto(e.target.value)} />}
                        // switch={<Input type="checkbox" id="check_inativos" label="Mostrar Inativos" inverse={true} onChange={(e) => (setInativosProduto(e.target.checked))} />}
                        >
                            <Table fullHeight={true}>
                                {
                                    filial &&
                                    filial.filter((item) => {
                                        if (!searchProduto) return true;
                                        if (item.nome.toLowerCase().includes(searchProduto.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr
                                                key={item.id}
                                            // active={true}
                                            >
                                                <Td
                                                // onClick={() => (setProdutoSelected(produtoSelected == item.id ? '' : item.id), setEditProduto(false))}
                                                >
                                                    {item.nome}
                                                </Td>
                                                {/* <Td align="end">
                                                    <Icon type="edit" onClick={() => handleProduto(item.id, item.nome)} />
                                                    <Switch
                                                        checked={item?.ativo === 1 ? true : false}
                                                        onChange={() => updateContrato(item.id, item.ativo)}
                                                    />
                                                </Td> */}
                                            </Tr>
                                        )
                                    })
                                }
                            </Table>
                        </Gerenciador>
                    }

                </>
            }
        </>
    )
}
