import { Routes, Route, useParams } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from '../../components/body/sidebar';
import SidebarLink from '../../components/body/sidebar/sidebarlink';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import { decrypt } from '../../_assets/js/global';
import { TradeProvider } from "../../context/Trade";
import axios from 'axios';

// PÁGINAS
import Lista from './Lista';
import DashboardTrade from './Dashboard';
import Fotos from './Fotos';
import GerenciadorTrade from './Gerenciador';

export default function Trade() {
  const params = useParams();
  const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
  var tokenUrl, idJob;

  if (paramsUrlCount > 0) {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    idJob = params['*'].substring(params['*'].lastIndexOf('/') + 1)
  } else {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  }

  window.idJob = idJob;
  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;

  //var decryptedReturn = JSON.parse(decrypt(tokenUrl));
 
  // window.rs_id_emp = decryptedReturn.id_emp;
  // window.rs_id_usr = decryptedReturn.id_usr;
  // window.rs_id_lja = decryptedReturn.id_lja;
  // window.rs_name_usr = decryptedReturn.name_usr;
  // window.rs_id_apl = decryptedReturn.id_apl;
  // window.rs_permission_apl = decryptedReturn.pus;

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  const [filters, setFilters] = useState(null);

  // DEFINE ÍCONES NAVBAR
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  // DEFINE FILTROS NAVBAR
  const handleSetFilters = (e) => {
    setFilters(e);
  }


  return (
    <>
      <Navbar
        icons={(icons)}
        filters={(filters)}
      >
        <NavbarLink link={'/systems/trade-react/lista/' + window.token} name="Lista" icon="relatorio" />
        <NavbarLink link={'/systems/trade-react/fotos/' + window.token} name="Supervisão" icon="camera" />
        <NavbarLink link={'/systems/trade-react/dashboard/' + window.token} name="Dashboard" icon="analytics" />
        <NavbarLink link={'/systems/trade-react/gerenciador/' + window.token} name="Gerenciador" icon="cog" />
      </Navbar>
      {/* <Sidebar>
        <SidebarLink link={'/systems/trade-react/lista/' + window.token} name="Lista" />
        <SidebarLink link={'/systems/trade-react/dashboard/' + window.token} name="Dashboard" />
      </Sidebar> */}

      <TradeProvider>
        <Container>
          <Routes>
            <Route path="/:token" index={true} element={<Lista icons={handleSetIcons} />} />
            <Route path="lista/:token" element={<Lista icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="dashboard/:token" element={<DashboardTrade icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="fotos/:token" element={<Fotos icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="gerenciador/:token" element={<GerenciadorTrade icons={handleSetIcons} filters={handleSetFilters} />} />
          </Routes>
        </Container>
      </TradeProvider>
    </>
  );
}