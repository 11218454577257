import { useEffect, useState } from "react";
import Button from "../../../../components/body/button";
import Form from "../../../../components/body/form";
import Input from "../../../../components/body/form/input";
import Gerenciador from "../../../../components/body/gerenciador";
import Icon from "../../../../components/body/icon";

export default function CadastroMotivo({ id, nome, callback }) {
    //ESTADOS INPUTS
    const [titulo, setTitulo] = useState();
    //SE VIER NOME PARA SER EDITADO
    useEffect(() => {
        if (id) {
            setTitulo(nome)
        } else if (!id) {
            setTitulo('')
        }
    }, [id]);

    //DADOS QUE SERÃO CADASTRADOS
    const data = {
        do: "salvar",
        tipo: "motivo",
        id: id ? id : 0,
        nome: titulo,
        _: ''
    }

    //LIMPAR INPUTS E FECHAR GERENCIADOR
    function resetForm() {
        callback(false);
        setTitulo('');
    }

    return (
        <Gerenciador
            title={id ? "Editar" : "Novo"}
            icon={<Icon type="reprovar" title="Fechar" onClick={() => callback(false)} />}
        >
            <Form
                api={`${window.host}/systems/trade/api/gerenciador-beta.php`}
                data={data}
                callback={resetForm}
            >
                <Input
                    label="Nome"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                />
                <Button type='submit'>Enviar</Button>
            </Form>
        </Gerenciador>
    )
}