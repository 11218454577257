import { useEffect, useState } from "react";
import Modal from "../../../../../components/body/modal";
import ModalBody from "../../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../../components/body/modal/modalHeader/modalTitle";
import Table from "../../../../../components/body/table";
import Thead from "../../../../../components/body/table/thead";
import Tr from "../../../../../components/body/table/tr";
import Th from "../../../../../components/body/table/thead/th";
import Tbody from "../../../../../components/body/table/tbody";
import Td from "../../../../../components/body/table/tbody/td";
import Icon from "../../../../../components/body/icon";
import { get_date } from "../../../../../_assets/js/global";
import axios from "axios";

export default function ModalVisitas({show, onHide, tipo, id_usuario, usuario, loja, id_loja, filter_date_start, filter_date_end, filter_status, filter_frequency, filter_system, title, subcategoria_2}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [lojas, setLojas] = useState([]);

    // FUNÇÃO PARA ESCONDER MODAL
    const handleHide = () => {
        if(onHide){
            onHide({
                show: false
            });
        }
    }

    // SETA ITENS VINDOS DA API
    const handleSetItems = (e) => {
        setItems(e);
    }

    useEffect(() => {
        if(show && lojas.length == 0){
            axios({
                method: 'get',
                url: window.host+'/api/sql.php?do=select&component=loja',
            }).then((response) => {
                if(response.data){
                    setLojas(response.data);
                }
            });
        }
    },[show]);

    return(
        <Modal show={show} onHide={handleHide} large={true}>
            <ModalHeader>
                <ModalTitle>
                    {(title ? title : 'Visitas')} - {(tipo === 1 ? loja : usuario)}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Table
                    api={window.host_madnezz + '/systems/integration-react/api/list.php?do=get_list'}
                    params={{
                        filter_type: 5,
                        type: 'report',
                        filter_system: filter_system,
                        filter_id_user: id_usuario,
                        filter_date_start: get_date('date', filter_date_start, 'date_sql'),
                        filter_date_end: get_date('date', filter_date_end, 'date_sql'),
                        filter_status: filter_status,
                        filter_system_integration_type_1: (id_loja ? id_loja : undefined),
                        filter_subcategory: subcategoria_2
                       // filter_subcategory: [4589, 4740]
                        // filter_frequency: filter_frequency
                    }}
                    border={false}
                    onLoad={handleSetItems}
                    reload={show}
                >
                    <Thead>
                        <Tr>
                            <Th>{(tipo === 1 ? 'Usuário' : 'Loja')}</Th>
                            <Th>Categoria</Th>
                            <Th>Subcategoria</Th>
                            <Th align="center">Data agendada</Th>
                            <Th align="center">Data realizada</Th>
                            <Th align="center">Local realizado</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {(items.length > 0 ?
                            items?.map((item, i) => {
                                let nome_loja = '';

                                if(item?.mov_sistemas){
                                    if(JSON.parse(item?.mov_sistemas).job_system_integration_type_1){
                                        let filial_aux = '';  

                                        if(lojas.filter((elem) => elem.id == JSON.parse(item?.mov_sistemas).job_system_integration_type_1)[0]?.externo_id){
                                            filial_aux = 'Filial '+lojas.filter((elem) => elem.id == JSON.parse(item?.mov_sistemas).job_system_integration_type_1)[0]?.externo_id+' - ';
                                        }

                                        nome_loja = filial_aux + lojas.filter((elem) => elem.id == JSON.parse(item?.mov_sistemas).job_system_integration_type_1)[0]?.nome;
                                    }                                    
                                }else{
                                    nome_loja = item?.loja;
                                }

                                return(
                                    <Tr key={'detail_'+item?.id_job_status}>
                                        {(tipo === 1 ? 
                                            <Td disableView={false}>{(item?.usuario ? item?.usuario : '-')}</Td>
                                        :'')}

                                        {(tipo === 2 ?
                                            <Td disableView={false}>{nome_loja}</Td>
                                        :'')}
                                        
                                        <Td disableView={false}>{(item?.categoria ? item?.categoria : '-')}</Td>
                                        <Td disableView={false}>{(item?.subcategoria ? item?.subcategoria : '-')}</Td>
                                        <Td disableView={false} align="center">{(item?.data_inicio_formatada ? item?.data_inicio_formatada : '-')}</Td>
                                        <Td disableView={false} align="center">{(item?.dataHora_execucao_formatada ? item?.dataHora_execucao_formatada : '-')}</Td>

                                        <Td disableView={false} align="center">
                                            {(item?.localizacao_realizado ?
                                                <a href={'https://www.google.com/maps/place/'+item?.localizacao_realizado} target="_blank">
                                                    Visualizar

                                                    <Icon type="location" readonly />
                                                </a>
                                            :'-')}
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}
