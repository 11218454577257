import { useState, useContext } from "react";
import { ChecklistContext } from "../../../context/Checklist";
import { useNavigate } from "react-router-dom";

import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import CalendarTitle from "../../../components/body/calendarTitle";
import CalendarFilter from "../../../components/body/calendarTitle/calendarFilter";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { GlobalContext } from "../../../context/Global";

export default function Filter(props) {
    const navigate = useNavigate();

    // CONTEXT
    const {
        editView,
        handleSetEditView,
        filterSupervisao,
        handleSetFilterSupervisao,
        filterDate,
        filterDateStart,
        filterDateEnd,
        filterTypeDate,
        filterDateMonth,
        handleSetFilterDate,
        handleSetFilterDateStart,
        handleSetFilterDateEnd,
        handleSetFilterTypeDate,
        handleSetFilterDateMonth,
        handleSetFilterEmpreendimento,
        handleSetFilterLoja,
        handleSetFilterStatus,
        handleSetFilterStatusSupervisor,
        handleSetPageError,
        optionsSupervisao,
        handleSetOptionsSupervisao,
        view,
        handleSetView,
        filterEmpreendimento,
        filterModule,
        handleSetFilterCategory,
        filterCategory,
        handleSetFilterSubCategory,
        filterSubCategory,
    } = useContext(ChecklistContext);

    const { handleSetFilter } = useContext(GlobalContext);

    // AJUSTE RELATÓRIO
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // ESTADOS
    const [firstLoad, setFirstLoad] = useState(true);
    const [filterVisualizacao, setFilterVisualizacao] = useState(1);
    
    // //
    // const [filterCategory, setFilterCategory] = useState('');
    // const [filterSubCategory, setFilterSubCategory] = useState('');

    // OPTIONS STATUS
    const optionsStatus = [
        { value: -1, label: 'Sem status' },
        { value: 1, label: 'Aprovado' },
        { value: 2, label: 'Reprovado' }
    ];

    // OPTIONS STATUS AVALIAÇÃO
    const optionsStatusAvaliacao = [
        { value: -1, label: 'Sem avaliação' },
        { value: 1, label: 'Aprovado' },
        { value: 2, label: 'Reprovado' }
    ];

    // OPTIONS STATUS
    const optionsVisualizacao = [
        { value: 1, label: 'Fotos' },
        { value: 2, label: 'Macro' }
    ];

    // FILTRA EMPREENDIMENTO
    const handleFilterEmp = (e) => {
        handleSetFilterEmpreendimento(e);
    }

    // FILTRA LOJA
    const handleFilterStore = (e) => {
        handleSetFilterLoja(e);
    }

    // FILTRO DE CHECKLISTS
    const handleFilterSupervisao = (e) => {
        handleSetFilterSupervisao(e);
    }

    // FILTRA STATUS
    const handleFilterStatus = (e) => {
        handleSetFilterStatus(e);
    }

    // FILTRA STATUS
    const handleFilterStatusSupervisor = (e) => {
        handleSetFilterStatusSupervisor(e);
    }

    // FILTRA CATEGORIA
    const handleFilterCategory = (e) => {
        handleSetFilterCategory(e);
    }

    // FILTRA SUBCATEGORIA
    const handleFilterSubcategory = (e) => {
        handleSetFilterSubCategory(e);
    }

    // TROCA VISUALIZAÇÃO
    const handleSetVisualizacao = (e) => {
        handleSetView(e);
        if (e == 1) {
            navigate("/systems/checklist-react/supervisao/"+window.token+"/");
        } else if (e == 2) {
            navigate("/systems/checklist-react/macro/"+window.token+"/");
        }
    }

    // GET OPTIONS SUPERVISÕES
    function getInfo() {
        // if (optionsSupervisao) {
        axios({
            method: 'get',
            url: window.host + '/systems/checklist/api/novo.php?do=get_select_checklist',
            // trocar para window.host
            // withCredentials: false,
            params: {
                filter_type_system: ['supervisao', 'antes_depois'],
                empreendimento_id: filterEmpreendimento,
                filter_id_categoria: filterCategory,
                filter_id_subcategoria: filterSubCategory
            },
            // headers: {"Access-Control-Allow-Origin": "http://localhost:3000"}
        }).then((response) => {
            if (response.data.length > 0) {
                handleSetOptionsSupervisao(response.data);
                // if (firstLoad || filterCategory || filterSubCategory) {
                // setFirstLoad(false);
                handleSetFilterSupervisao([response.data[0].value.toString()]);
                handleSetPageError(false);
                // }
            } else {
                handleSetPageError(true);
                handleSetOptionsSupervisao('');
                handleSetFilterSupervisao('')
            }
        });
        // }
    }

    // OPTIONS DO SELECT CATEGORIAS
    useEffect(() => {
        // // GET OPTIONS SUPERVISÕES
        // if (optionsSupervisao.length == 0) {
        //     await axios({
        //         method: 'get',
        //         url: 'https://dev.madnezz.com.br/systems/checklist/api/novo.php?do=get_select_checklist',
        //         // trocar para window.host
        //         // withCredentials: false,
        //         params: {
        //             filter_type_system: ['supervisao', 'antes_depois'],
        //             empreendimento_id: filterEmpreendimento
        //         },

        //     }).then((response) => {
        //         if (response.data.length > 0) {
        //             handleSetOptionsSupervisao(response.data);

        //             if (firstLoad) {
        //                 setFirstLoad(false);
        //                 handleSetFilterSupervisao(response.data[0].value);
        //             }
        //         } else {
        //             handleSetPageError(true);
        //         }
        //     });
        // }
        getInfo();
    }, [filterEmpreendimento, filterCategory, filterSubCategory]);

    return (
        <>
            <SelectReact
                placeholder="Visualização"
                options={optionsVisualizacao}
                value={view}
                allowEmpty={false}
                onChange={(e) => handleSetVisualizacao(e.value)}
            ></SelectReact>

            {(!window.rs_id_lja || window.rs_id_lja == 0 ?
                <FilterCheckbox
                    name="filter_store"
                    api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false"}
                    onChangeClose={handleFilterStore}
                >
                    Loja
                </FilterCheckbox>
                : '')}

            {(window.rs_id_grupo && window.rs_id_grupo > 0 ?
                <FilterCheckbox
                    name="filter_grupo"
                    api={window.host + "/api/sql.php?do=select&component=grupo_empreendimento&np=true&filial=true&limit=false"}
                    onChangeClose={handleFilterEmp}
                    value={filterEmpreendimento}
                >
                    Empreendimento
                </FilterCheckbox>
                : '')}

            <FilterCheckbox
                name="filter_category"
                api={window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + (filterModule == undefined ? '' : filterModule) + "&filter_id_apl_default=0"}
                onChangeClose={handleFilterCategory}
                value={filterCategory}
                reload={filterCategory}
            >
                Categorias
            </FilterCheckbox>

            <FilterCheckbox
                name="filter_subcategory"
                api={{
                    url: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_subcategory&grupo_id=true&filter_id_apl_default=0",
                    params: {
                        empreendimento_id: filterEmpreendimento,
                        filter_id_category: filterCategory,
                    },
                    reload: [filterEmpreendimento, filterCategory]
                }}
                onChangeClose={handleFilterSubcategory}
                value={filterSubCategory}
                reload={filterSubCategory}
            >
                Subcategorias
            </FilterCheckbox>

            <FilterCheckbox
                name="filter_check"
                options={optionsSupervisao}
                value={filterSupervisao}
                onChangeClose={handleFilterSupervisao}
            >
                Filtrar Supervisão
            </FilterCheckbox>

            <FilterCheckbox
                name="filter_status"
                options={optionsStatus}
                onChangeClose={handleFilterStatus}
            >
                Status
            </FilterCheckbox>

            <FilterCheckbox
                name="filter_status_supervisor"
                options={optionsStatusAvaliacao}
                onChangeClose={handleFilterStatusSupervisor}
            >
                Avaliação
            </FilterCheckbox>

            {(!props.relatorio ?
                <SelectReact
                    id="filtro_tipo_data"
                    name="filtro_tipo_data"
                    options={[
                        {id: 1, nome: 'Por Dia'},
                        {id: 2, nome: 'Por Período'}
                    ]}
                    allowEmpty={false}
                    value={filterTypeDate}
                    onChange={(e) => handleSetFilterTypeDate(e.value)}
                />
            :'')}

            {(!props.relatorio ?
                <>
                    {(filterTypeDate == 1 ?
                        <Input
                            type="date"
                            icon={false}
                            required={false}
                            value={filterDate}
                            onChange={(e) => handleSetFilterDate(e)}
                        />
                    :
                        <Input
                            type="period"
                            icon={false}
                            required={false}
                            value={filterDateMonth}
                            valueStart={filterDateStart}
                            valueEnd={filterDateEnd}
                            onChangeStart={(e) => handleSetFilterDateStart(e)}
                            onChangeEnd={(e) => handleSetFilterDateEnd(e)}
                        />
                    )}
                </>
            : '')}

            {(props.relatorio ?
                <Input
                    type="date"
                    format="mm/yyyy"
                    icon={false}
                    required={false}
                    value={filterDateMonth}
                    onChange={(e) => handleSetFilterDateMonth(e)}
                />
            : '')}
        </>
    )
}