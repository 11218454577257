import { useState } from "react";
import Td from "../../../components/body/table/tbody/td";
import Tr from "../../../components/body/table/tr";
import Icon from "../../../components/body/icon";
import Input from "../../../components/body/form/input";
import { cd, get_date } from "../../../_assets/js/global";
import Historico from "../Historico";
import axios from "axios";
import toast from "react-hot-toast";

export default function Item(props) {

    //DATA DE HOJE
    const date_now = new Date();
    //ADICIONAR ANEXO PELA TABELA
    const [anexo, setAnexo] = useState();

    // PEGAR ANEXO APÓS UPLOAD
    const handleSetAnexo = (response, id) => {

        let modelo_aux = [];
        JSON.parse(response[0]).map((item, i) => {
            modelo_aux.push(item.id);
        });

        setAnexo(response[0]);
        // if (response[2] == 'upload') {
        //     // LIMITA QUANTIDADE DE ANEXOS
        //     if (props?.pergunta?.anexo && props?.pergunta?.anexo.split('],').length >= 5) {
        //         toast('Não é possível inserir mais de 5 anexos em uma mesma pergunta');
        //     } else {
        //         setAnexo(response[0]);
        //     }
        // } else {

        axios({
            method: 'post',
            url: window.host + "/systems/trade/api/trade.php?do=modelo_incluir",
            data: {
                id: id,
                file: `${process.env.REACT_APP_URL_UPLOAD}/${modelo_aux?.toString()}`,
                data: get_date('date_sql', cd(date_now))
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(() => {
            toast('Modelo cadastrado com sucesso');
            props?.doReload(!props?.reload);
        }).catch((error)=>{toast("Erro")});

        // }
        // props.anexo(true);
    }

    function excluirFoto(id, img) {
        if (window.confirm("Deseja excluir esse modelo?")) {
            axios({
                url: `${window.host}/systems/trade/api/trade.php`,
                method: 'post',
                params: {
                    do: 'modelo_excluir'
                },
                data: {
                    id: id,
                    data: get_date('date_sql', cd(date_now)),
                    img: img
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(() => {
                toast("Modelo excluído com sucesso");
                props?.doReload(!props?.reload);
            }).catch((error)=>{toast("Erro")});
        }
    }
    
    return (
        <Tr key={props?.trade_id}>
            <Td>
                {props?.numerocontrato}
            </Td>
            <Td title={props?.grupo}>
                {props?.grupo}
            </Td>
            <Td title={props?.filial}>
                {props?.filial}
            </Td>
            <Td title={props?.nomefantasiafornecedor}>
                {props?.nomefantasiafornecedor}
            </Td>
            <Td title={props?.descricaoprodutotrade}>
                {props?.descricaoprodutotrade}
            </Td>
            <Td title={props?.data_contrato}>
                {props?.data_contrato}
            </Td>
            <Td hide={props?.hide}>
                {props?.job}
            </Td>
            <Td hide={props?.hide} align="center">
                {props?.job_categoria}
            </Td>
            <Td hide={props?.hide} align="center">
                {props?.job_subcategoria}
            </Td>
            <Td hide={props?.hide}>
                {props?.job_status}
            </Td>
            <Td hide={props?.hide} title={props?.obs}>
                {props?.obs}
            </Td>
            <Td hide={props?.hide}>
                {props?.valor}
            </Td>
            <Td hide={props?.hide}>
                {props?.numeroponto}
            </Td>
            <Td hide={props?.hide} align="center">
                {props?.codigofornecedor}
            </Td>
            <Td hide={props?.hide} align="center">
                {props?.codigoprodutotrade}
            </Td>
            <Td hide={props?.hide} align="center">
                {props?.numerofase}
            </Td>
            <Td >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                        size="lg"
                        type="file"
                        border={false}
                        multiple={false}
                        className={'mx-0'}
                        value={anexo}
                        multipleValues={true}
                        callback={(e) => handleSetAnexo(e, props?.trade_id)}
                        input={(true)}
                        animated
                    //readonly={(props.interaction === false ? true : false)}
                    />
                    {
                        props?.imagem_aux &&
                        props?.imagem_aux.map((item) => {
                            return (
                                <div className="d-flex align-items-center">
                                    <Icon
                                        type="view"
                                        onClick={() => props.handleShow(item, props?.descricaoprodutotrade)}
                                        disabled={props?.imgmodelo ? false : true}
                                        animated
                                    />
                                    <div className="mini-delete-btn-trade">
                                        <Icon
                                            type="reprovar"
                                            title="Remover modelo"
                                            //  size={{editWidth: '10px', editHeight: '10px'}}
                                            onClick={() => excluirFoto(props?.trade_id, item)}
                                            //animated
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Td>
            <Td align="center">
                {(
                    props?.job_status === "Concluído" ?

                        <>
                            <Icon
                                type="view"
                                onClick={() => props.handleShow(props?.img_loja, props?.descricaoprodutotrade)}
                                disabled={props?.img_loja ? false : true}
                                animated
                            />
                        </>
                        :
                        <Icon
                            type="view"
                            disabled={true}
                            animated
                        />
                )}
                {(
                    props?.job_status === "Concluído" ?
                        <Icon
                            type="aprovar"
                            className="text-success"
                        />
                        : " "
                )}
            </Td>
            <Td >
                {props?.data_foto_loja ? cd(props?.data_foto_loja?.slice(0,10)) : ' - '}
            </Td>
            <Td>
                <Icon
                    type="check"
                    disabled={true}
                />
                <Icon
                    type="reprovar2"
                    disabled={true}
                />
                <Historico
                    id={props?.trade_id}
                    contrato={props?.numerocontrato}
                    loja={props?.filial}
                    grupo={props?.grupo}
                    data={props?.data_contrato}
                />
            </Td>
            <Td>
                <span class="trade-background" style={{ backgroundColor: props?.color }}>{props?.status_adm_nome?.split(',')[0]}</span>
            </Td>
        </Tr>
    )
}