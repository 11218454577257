import { useState, useEffect } from "react";
import Row from "../../../components/body/row";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import Input from "../../../components/body/form/input";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import "./style.css";
import Funcionario from "./funcionario";
import TabelaMacro from "./tabela-macro";

export default function Lista({ icons, filters }) {

    //ESTADOS FILTROS -
    const [tipo, setTipo] = useState(2);
    const [legenda, setLegenda] = useState('');
    const [loja, setLoja] = useState('');
    const [funcionario, setFuncionario] = useState('');
    const [avaliacao, setAvaliacao] = useState(1);
    const [periodo, setPeriodo] = useState('');

    //OPTIONS SELECT  ---> loja e funcionario usar filtercheckbox
    const options_tipo = [
        { value: 1, label: "Individual" }, { value: 2, label: "Macro" }
    ]

    const options_legenda = [
        { value: 1, label: "EE - Excedeu Expectativas" }, { value: 2, label: "AE - Atingiu Expectativas" },
        { value: 3, label: "PM - Precisa Melhorar" }, { value: 4, label: "Insatisfatorio" }
    ]

    const options_loja = [
        { value: 1, label: "Filial 1" }, { value: 2, label: "Filial 2" }
    ]

    const options_funcionario = [
        { value: 1, label: "Fernanda Costa" }, { value: 2, label: "Amanda Sousa" }
    ]

    const options_avaliacao = [
        { value: 1, label: "Avaliação" }, { value: 2, label: "Acumulados" }
    ]

    // FILTRA LOJA
    const handleFilterLoja = (selectedVals) => {
        setLoja(selectedVals);
    }

    // FILTRA USUÁRIO
    const handleFilterFuncionario = (selectedVals) => {
        setFuncionario(selectedVals);
    }

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="print" />
                    <Icon type="pdf" />
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    <SelectReact
                        options={options_tipo}
                        placeholder
                        name="tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.value)}
                    />
                    <SelectReact
                        options={options_legenda}
                        placeholder="Legenda"
                        name="legenda"
                        value={legenda}
                        onChange={(e) => setLegenda(e.value)}
                    />
                    <FilterCheckbox
                        id="filter_store"
                        name="filter_store"
                        api={{
                            url: window.host_madnezz + '/systems/integration-react/api/list.php?do=headerFilter',
                            params: {
                                filters: [{ filter: 'store' }],
                                // empreendimento_id: filterEmpreendimento,
                                limit: 50,
                                np: true
                            },
                            key: 'store',
                            // reload: filterEmpreendimento
                        }}
                        onChangeClose={handleFilterLoja}
                        // hide={(tipoCalendario == 3 && subTipoCalendario == 'store') || tipoCalendario == 2 ? false : (((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker')) || (tipoCalendario != 3 && tipoCalendario != 2) || subTipoCalendario == 'user' ? true : false)}
                        value={loja}
                    >
                        Lojas
                    </FilterCheckbox>

                    {/* <FilterCheckbox
                        name="filter_user"
                        options={options_funcionario}
                        onChangeClose={handleFilterFuncionario}
                        hide={tipo === 1 ? false : true}
                        value={funcionario}
                    >
                        Funcionários
                    </FilterCheckbox> */}
                    {
                        tipo === 1 ?

                            <SelectReact
                                options={options_funcionario}
                                placeholder="Funcionários"
                                name="funcionario"
                                value={funcionario}
                                onChange={(e) => setFuncionario(e.value)}
                            />
                            : <></>
                    }
                    <SelectReact
                        options={options_avaliacao}
                        placeholder
                        name="avaliacao "
                        value={avaliacao}
                        onChange={(e) => setAvaliacao(e.value)}
                    />
                    <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={periodo}
                        onChange={(e) => setPeriodo(e)}
                    />

                </>
            )
        }
    }, [tipo, legenda, loja, funcionario, avaliacao, periodo])

    return (
        <Row>
            {tipo === 1 ?
                <Funcionario id={funcionario} legenda={legenda} loja={loja} avaliacao={avaliacao} periodo={periodo} />
                :
                <TabelaMacro legenda={legenda} loja={loja} avaliacao={avaliacao} periodo={periodo} />
            }
        </Row>
    )
}
