import axios from "axios";
import Modal from "../../../components/body/modal";
import ModalBody from "../../../components/body/modal/modalBody";
import ModalHeader from "../../../components/body/modal/modalHeader";
import ModalTitle from "../../../components/body/modal/modalHeader/modalTitle";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import { useEffect, useState } from "react";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import { cd } from "../../../_assets/js/global";

export default function ModalLidos({ show, handleShow, onHide, lote, titulo, apl }) {

    //CLOSE MODAL
    const handleClose = () => {
        onHide(false);

        setTimeout(() => {
            setItens([]);
        },500);
    }

    //ESTADOS
    const [itens, setItens] = useState([]);

    const handleSetItens = (e) => {
        setItens(e);
    }

    return (
        <Modal show={show} handleShow={handleShow} onHide={handleClose} large={true} >
            <ModalHeader>
                <ModalTitle>
                    {titulo && titulo}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Table
                    id="jobs-lidos"
                    api={window.host_madnezz + "/systems/integration-react/api/list.php?do=get_list"}
                    params={{
                        filter_batch: lote,
                        id_apl: 229,
                        limit: 50
                    }}
                    border={false}
                    onLoad={handleSetItens}
                    reload={show}
                >
                    <Thead>
                        <Tr>
                            <Th>
                                Loja
                            </Th>
                            <Th>
                                Usuário
                            </Th>
                            <Th>
                                Enviado
                            </Th>
                            <Th>
                                Visualizado
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(
                            itens.length > 0 ?
                                itens.map((item) => {

                                    const hora_enviado = item.cad_data.split(' ')[1].substring(0, 5);
                                    const hora_visualizado = item.data_visualizado && item.data_visualizado.split(' ')[1].substring(0, 5)

                                    return (
                                        <Tr key={'leitura_'+item.id_job}>
                                            <Td>
                                                {(item?.loja ? item?.loja : '-')}
                                            </Td>
                                            <Td>
                                                {(item?.usuario ? item?.usuario : '-')}
                                            </Td>
                                            <Td>
                                                {cd(item.cad_data)}  {hora_enviado}
                                            </Td>
                                            <Td>
                                                {item.data_visualizado && cd(item.data_visualizado)}  {hora_visualizado}
                                            </Td>
                                        </Tr>
                                    )
                                })
                            : <></>
                        )}
                    </Tbody>
                </Table>
            </ModalBody>
        </Modal >
    )
}