import Modal from "../../modal";
import ModalHeader from "../../modal/modalHeader";
import ModalBody from "../../modal/modalBody";
import Table from "../../table";
import Thead from "../../table/thead"
import Tr from "../../table/tr";
import Th from "../../table/thead/th";
import Tbody from "../../table/tbody";
import Td from "../../table/tbody/td";
import Icon from "../../icon";
import ModalTitle from "../../modal/modalHeader/modalTitle";
import { useState } from "react";
import axios from "axios"

export default function ViewModal({ nId }) {

    //ESTADOS
    const [, setReload] = useState(false);
    const [show, setShow] = useState(false);

    // FECHA MODAL
    const handleCloseModalView = (e) => {
        setShow(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
    }

    //ESTADO QUE GUARDA INFORMAÇÕES DA API
    const [newId, setNewId] = useState([]);
    //PEGAR INFORMAÇÕES DA API E SALVAR NO ESTADO newId
    function getViews() {
        setShow(true)
        axios({
            url: window.host + "/homes/api/home3.php?do=listaVisualizacao",
            method: "post",
            data: { id: nId },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setNewId(response.data)
        })
    }

    //PERCORRE O OBJETO DA API E SOMA A QUANTIDADE DE COMENTÁRIOS DE CADA USUÁRIO QUE VISUALIZOU
    const reducer = (a, b) => { return a.qtd_comentarios += b.qtd_comentarios, a; }
    var qtdComentarios = newId.reduce(reducer, { qtd_comentarios: 0 })

    //PERCORRE O OBJETO DA API E SOMA OS LIKES 
    var qtdCurtidas = 0;
    for (var { curtida: like } of newId) { // único jeito que eu consegui desestruturar sem dar cannot read undefined
        function contaLike(like) {
            if (like.includes('0')) {
                return false
            }
            return true;
        }
        if (contaLike(like)) {
            qtdCurtidas += 1
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleCloseModalView} >
                <ModalHeader>
                    <ModalTitle>
                        Visualização
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Data</Th>
                                <Th>Nome</Th>
                                <Th>Curtidas</Th>
                                <Th>Comentários</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(
                                newId ?
                                    newId.map((item) => {
                                        return (
                                            <Tr key={item.id}>
                                                <Td >{item.data}</Td>
                                                <Td >{item.usuario}</Td>
                                                <Td>
                                                    {(
                                                        item.curtida > 0 ? "Sim" : "Não"
                                                    )}
                                                </Td>
                                                <Td>{item.qtd_comentarios}</Td>
                                            </Tr>
                                        )
                                    })
                                    : <Tr empty={true}></Tr>
                            )}
                        </Tbody>
                        <tfoot>
                            <Tr>
                                <Th><strong>Total</strong></Th>
                                <Td>{(newId.length > 0 ? newId.length : "0")}</Td>
                                <Td>{(newId.length > 0 ? qtdCurtidas : "0")}</Td>
                                <Td>{(newId.length > 0 ? qtdComentarios.qtd_comentarios : "0")}</Td>
                            </Tr>
                        </tfoot>
                    </Table>
                </ModalBody>
            </Modal>
            <Icon type="view" animated className="detailSpan" onClick={() => getViews()} />
        </>
    )
}