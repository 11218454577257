import { useEffect, useState } from "react";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Tr from "../../../components/body/table/tr";
import Container from "../../../components/body/container";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";
import Td from "../../../components/body/table/tbody/td";
import Tfoot from "../../../components/body/table/tfoot";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import axios from "axios";
import Loader from "../../../components/body/loader";
import { isArray } from "lodash";
import qs from 'qs';

export default function ReguaCompetencias({icons, filters}){
    // ESTADOS DE FILTROS
    const [filterEmpreendimento, setFilterEmpreendimento] = useState(null);
    const [filterAno, setFilterAno] = useState(new Date().getFullYear());
    const [filterChecklist, setFilterChecklist] = useState(6454);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // DEFINE VARIÁVEIS DOS FILTROS E ÍCONES DA NAVBAR
    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="excel" />
                    <Icon type="pdf" />
                </>
            );
        }

        if (filters) {
            filters(
                <>
                    {/* <SelectReact
                        id="filtro_checklist"
                        name="filtro_checklist"
                        api={{
                            url: window.host+'/systems/checklist/api/lista.php?do=checklist',
                            params: {
                                limit: 100
                            }
                        }}
                        value={filterChecklist}
                        placeholder="Checklist"
                        isRtl={true}
                        onChange={(e) => setFilterChecklist(e.value)}
                    /> */}

                    <SelectReact
                        id="filtro_ano"
                        name="filtro_ano"
                        placeholder="Ano"
                        options={window.optionsYear}
                        value={filterAno}
                        onChange={(e) => handleFilterAno(e.value)}
                    />
                </>
            );
        }
    }, [filterAno, filterChecklist]);

    // TOTAIS FAKES
    var total_1 = 0;
    var total_2 = 0;
    var total_3 = 0;
    var total_4 = 0;

    // FILTRO EMPREENDIMENTO
    const handleFilterEmpreendimento = (e) => {
        setFilterEmpreendimento(e);
    }

    // FILTRO DE ANO
    const handleFilterAno = (e) => {
        setFilterAno(e)
    }

    // GET RELATÓRIO
    const getRelatorios = () => {
        setLoading(true)
        setData(null)
        axios({
            method: 'GET',
            url: window.host + '/systems/checklist/api/lista.php',
            params: {
                do: 'get_relatorios_trimestral',
                usuario_id: window.rs_id_usr,
                empreendimentos: filterEmpreendimento,
                ano: filterAno,
                checklists: filterChecklist ? [filterChecklist] : undefined
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
        .then(res => {
            if(!isArray(res.data)){
                setData([])
            }
            else{
                setData(res?.data)
            }
            setLoading(false)
        })
        .catch(err => setData([]))}

    // REALIZAR O GET DO RELATÓRIO SEMPRE QUE TROCA ALGUM FILTRO
    useEffect(() => {
        getRelatorios()
    }, [filterAno, filterChecklist, filterEmpreendimento]);
    

    return(
        <Container>
            <Row>
                <Col lg={'auto'}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th
                                    api={{
                                        url: window.host+'/api/sql.php?do=select&component=grupo_empreendimento',
                                    }}
                                    onChange={handleFilterEmpreendimento}
                                >
                                    Empreendimento
                                </Th>
                                <Th align="center" colspan={5}>Qtde. de Avaliados</Th>
                            </Tr>
                            <Tr>
                                <Th>Avaliado</Th>
                                <Th width={1} align="center">1º Tri</Th>
                                <Th width={1} align="center">2º Tri</Th>
                                <Th width={1} align="center">3º Tri</Th>
                                <Th width={1} align="center">4º Tri</Th>
                                <Th width={1} align="center">Ano</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loading ?                             
                                <Tr>
                                    <Td width={1} align="center" ><Loader /></Td>
                                    <Td width={1} align="center" ></Td>
                                    <Td width={1} align="center" ></Td>
                                    <Td width={1} align="center" ></Td>
                                </Tr>                            
                            : 
                                (data.length > 0 ? data.map((item, i) => {

                                const getColor = (num) => num <= 10 && 'text-danger' || num > 10 && num <= 15 && 'text-success' || num > 15 && 'text-primary'

                                // DEFINE TOTAIS
                                total_1 = total_1 + (item.tri_1 ?? 0);
                                total_2 = total_2 + (item.tri_2 ?? 0);
                                total_3 = total_3 + (item.tri_3 ?? 0);
                                total_4 = total_4 + (item.tri_4 ?? 0);

                                return(
                                    <Tr key={'item_'+item.ei}>
                                        <Td width={1}>{item.rede}</Td>
                                        <Td width={1} align="center" className={getColor(item.tri_1)}>{item.tri_1 ?? 0}</Td>
                                        <Td width={1} align="center" className={getColor(item.tri_2)}>{item.tri_2 ?? 0}</Td>
                                        <Td width={1} align="center" className={getColor(item.tri_3)}>{item.tri_3 ?? 0}</Td>
                                        <Td width={1} align="center" className={getColor(item.tri_4)}>{item.tri_4 ?? 0}</Td>
                                        <Td width={1} align="center">{(item.tri_1 ?? 0) + (item.tri_2 ?? 0) + (item.tri_3 ?? 0) + (item.tri_4 ?? 0)}</Td>
                                    </Tr>
                                )
                            }) : <Tr><Td colspan="100%">Nenhum registro encontrado</Td></Tr>)}                       
                        </Tbody>

                        <Tfoot>
                            <Tr>
                                <Td>Total</Td>
                                <Td width={1} align="center">{total_1}</Td>
                                <Td width={1} align="center">{total_2}</Td>
                                <Td width={1} align="center">{total_3}</Td>
                                <Td width={1} align="center">{total_4}</Td>
                                <Td width={1} align="center">{total_1 + total_2 + total_3 + total_4}</Td>
                            </Tr>
                        </Tfoot>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}
