import { useState, useEffect } from 'react';

import Gerenciador from '../../../../components/body/gerenciador';
import Input from '../../../../components/body/form/input';
import InputContainer from '../../../../components/body/form/inputcontainer';
import SelectReact from '../../../../components/body/select';
import Table from '../../../../components/body/table';
import Tbody from '../../../../components/body/table/tbody';
import Tr from '../../../../components/body/table/tr';
import Td from '../../../../components/body/table/tbody/td';
import Form from '../../../../components/body/form';
import Icon from '../../../../components/body/icon';
import Button from '../../../../components/body/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Switch from '../../../../components/body/switch';
import { scrollToCol } from '../../../../_assets/js/global';

export default function Subcategorias({id_grupo, emp, category, id_apl, callback, disabled, fases, chamados, visitas}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('');

    // ESTADOS DO FORM
    const [reload, setReload] = useState(false);
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [microssistema, setMicrossistema] = useState('');
    const [todosSistemas, setTodosSistemas] = useState(true);
    const [subcategoriaGrupo, setSubcategoriaGrupo] = useState(false);
    const [optionsMicrossistemas, setOptionsMicrossistemas] = useState([]);
    const [optionsModulos, setOptionsModulos] = useState([]);
    const [modulo, setModulo] = useState(undefined);

    // RESETA O FILTRO E O ITEM ATIVO CASO TROQUE O GRUPO
    useEffect(() => {
        setFilter(null);
        setEdit(false);
    },[emp, category]);

    // CALLBACK DA API DA TABLE
    const handleSetItems = (e) => {
        setItems(e)
    }

    // RESETAR FORM
    function reset_form(){
        setId('');
        setNome('');
        setMicrossistema('');
        setTodosSistemas(true);
        setSubcategoriaGrupo(false);
        setModulo(undefined);
    }

    // AÇÕES AO ABRIR FORM DE CADASTRO
    const handleOpenForm = (id, nome, microssistema, id_apl, id_grupo, id_modulo) => {
        if(optionsMicrossistemas.length == 0){
            axios({
                method: 'get',
                url: window.host_madnezz+'/systems/integration-react/api/registry.php?do=get_system_type&system_job=23&filter_id_module='
            }).then((response) => {
                if(response.data){
                    setOptionsMicrossistemas(response.data);
                }
            })
        }

        if(optionsModulos.length == 0){
            axios({
                method: 'get',
                url: window.host_madnezz+"/systems/integration-react/api/list.php?do=get_module&empreendimento_id="+emp
            }).then((response) => {
                if(response.data){
                    setOptionsModulos(response.data);
                }
            })
        }

        if(id){
            let microssistema_aux;
            if(microssistema){
                microssistema_aux = JSON.parse(microssistema)?.id;
            }

            setEdit(id);
            setId(id);
            setNome(nome);
            setMicrossistema(microssistema_aux);
            setModulo(id_modulo);
            setTodosSistemas(id_apl == 1 ? true :false);
            setSubcategoriaGrupo(id_grupo ? true : false);
        }else{
            setEdit(true);
            reset_form();
        }

        // SCROLL AUTOMÁTICO ATÉ O FORMULÁRIO DE EDIÇÃO
        scrollToCol('subcategoria_insert');

        if(callback){
            callback({
                edit: true
            })
        }
    }

    // AÇÕES AO FECHAR FORM DE CADASTRO
    const handleCloseForm = () => {
        setEdit(false);
        reset_form();

        if(callback){
            callback({
                edit: false
            });
        }
    }

    // AÇÕES AO ENVIAR FORM DE CADASTRO
    const handleSendForm = () => {
        setId('');
        setNome('');        
        setMicrossistema('');
        setModulo(undefined);
        setEdit(false);        
    }

    // VERIFICA STATUS DO FORM
    const handleFormStatus = (e) => {
        setButtonStatus(e);
    }

    // RECARREGA LISTA AO RECEBER O RETORNO DA API DE CADASTRO
    const handleReloadForm = (e) => {
        setReload(!reload);
    }

    // ATIVAR / DESATIVAR ITEM
    const handleSetItemActive = (id, ativo) => {
        toast('Subcategoria ' + (ativo ? 'ativada' : 'desativada'));

        axios({
            method: 'post',
            url: window.host_madnezz + "/systems/integration-react/api/registry.php?do=set_subcategoryActive",
            data: {
                id: id,
                active: ativo,
                id_ite: category
            },
            headers: { "Content-Type": "multipart/form-data" }
        }).then(() => {
            // handleReloadForm();
        })
    }

    // FILTRO DE INATIVOS
    const handleSetFilterInactive = () => {
        setFilterInactive(!filterInactive);
        handleReloadForm();
    }

    // TOAST
    const handleToast = () => {
        if(id){
            return 'Subcategoria editada com sucesso!';
        }else{
            return 'Subcategoria cadastrada com sucesso!';
        }
    }

    // DATA FORM
    const data = {
        id: id,
        id_apl: (todosSistemas ? 1 : (id_apl ? id_apl : window.rs_id_apl)),
        empreendimento_id: (subcategoriaGrupo ? undefined : emp),
        para: (subcategoriaGrupo ? 2 : undefined),
        name: nome,
        aux: (microssistema ? '{"id":'+microssistema+'}' : ''),
        id_ite: category,
        id_ite_aux: modulo        
    }

    return(
        <>
            <Gerenciador 
                id="subcategoria"
                title="Subcategorias"
                search={
                    <Input
                        name="filter_subcategoria"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                }
                icon={
                    <Icon
                        type="new"
                        title="Nova subcategoria"
                        onClick={() => handleOpenForm()}
                    />
                }
                switch={
                    <Input
                        type={'checkbox'}
                        name="subcategorias_inativas"
                        label="Inativas"
                        inverse={true}
                        onClick={handleSetFilterInactive}
                    />
                }
                disabled={disabled}
            >
                <Table
                    id="table_subcategoria"
                    api={window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_subcategory"}
                    params={{
                        filter_active: (filterInactive ? [0,1] : [1]),
                        filter_id_category: [category],
                        id_grupo: id_grupo,
                        empreendimento_id: emp,
                        id_apl: (id_apl ? id_apl : undefined)
                    }}
                    onLoad={handleSetItems}
                    reload={reload + emp + category}
                    text_limit={(window.isMobile ? 20 : 30)}
                >
                    <Tbody>
                        {(items.length > 0 ?
                            items.filter((item) => {
                                if (!filter) return true;
                                if (item.label.toLowerCase().includes(filter.toLocaleLowerCase())) {
                                    return true
                                }
                            }).map((item, i) => {
                                let sistemas = '';

                                if(item?.id_apl == 1){
                                    sistemas = '(Todos os sistemas)';
                                }else{
                                    if(item?.id_apl == 223){
                                        sistemas = '(Jobs)';
                                    }else if(item?.id_apl == 224){
                                        sistemas = '(Chamados)';
                                    }else if(item?.id_apl == 225){
                                        sistemas = '(Fases)';
                                    }else if(item?.id_apl == 226){
                                        sistemas = '(Visitas)';
                                    }else if(item?.id_apl == 227){
                                        sistemas = '(Obras)';
                                    }else if(item?.id_apl == 229){
                                        sistemas = '(Comunicados)';
                                    }else if(item?.id_apl == 230){
                                        sistemas = '(Checklist)';
                                    }else if(item?.id_apl == 231){
                                        sistemas = '(Notificações)';
                                    }
                                }

                                return(
                                    <Tr
                                        key={'subcategoria_'+item.value}
                                    >
                                        <Td>
                                            {item.label} <span className="text-secondary">{sistemas}</span>
                                        </Td>

                                        <Td width={1} align="center">
                                            <Icon 
                                                type={'edit'}
                                                active={(edit === item.value ? true : false)}
                                                onClick={() => handleOpenForm(item?.value, item?.label, item?.arquivo, item?.id_apl, item?.id_grupo, item?.id_ite_aux)}
                                                animated
                                            />

                                            <Switch
                                                type="check"
                                                title={(item.ativo == 1 ? 'Desativar' : 'Ativar')}
                                                checked={(item.ativo == 1 ? true : false)}
                                                onChange={() => handleSetItemActive(item?.value, (item.ativo == 1 ? 0 : 1))}
                                                animated
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        :
                            <></>
                        )}
                    </Tbody>
                </Table>
            </Gerenciador>

            {/* FORMULÁRIO DE CADASTRO/EDIÇÃO */}
            {(edit ? 
                <Gerenciador 
                    id="subcategoria_insert"
                    title={(id ? ('Editar '+nome) : 'Nova Subategoria')}
                    search={false}
                    icon={
                        <Icon
                            type="reprovar"
                            title="Fechar"
                            onClick={handleCloseForm}
                        />
                    }
                    disabled={disabled}
                >
                    <Form
                        api={window.host_madnezz+"/systems/integration-react/api/registry.php?do=set_subcategory"}
                        data={data}
                        status={handleFormStatus}
                        response={handleReloadForm}
                        callback={() => handleSendForm(true)}
                        toast={handleToast}
                    >
                        <Input
                            type="text"
                            name="subcategoria_nome"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <SelectReact
                            name="subcategoria_microssistema"
                            id="subcategoria_microssistema"
                            label="Microssistema (Opcional)"
                            options={optionsMicrossistemas}
                            value={microssistema}
                            required={false}
                            onChange={(e) => setMicrossistema(e.value)}
                        />

                        {(chamados ?
                            <SelectReact
                                id="subcategoria_modulo"
                                name="subcategoria_modulo"
                                options={optionsModulos}
                                label="Encaminhar ao módulo"
                                value={modulo}
                                onChange={(e) => setModulo(e.value)}
                                required={false}
                            />
                        :'')}

                        <InputContainer>
                            <Switch
                                id="todos_sistemas"
                                name="todos_sistemas"
                                label="Disponível em todos os sistemas:"
                                checked={todosSistemas}
                                onChange={() => setTodosSistemas(!todosSistemas)}
                            />
                        </InputContainer>

                        {(window.rs_id_grupo ?
                            <InputContainer>
                                <Switch
                                    id="subcategoria_grupo"
                                    name="subcategoria_grupo"
                                    label="Disponível para todos do grupo:"
                                    checked={subcategoriaGrupo}
                                    onChange={() => setSubcategoriaGrupo(!subcategoriaGrupo)}
                                />
                            </InputContainer>
                        :'')}

                        <Button
                            type="submit"
                            status={buttonStatus}
                        >
                            Salvar
                        </Button>
                    </Form>
                </Gerenciador>
            :'')}
        </>
    )
}
