import { useContext, useEffect, useState } from "react";
import Col from "../../../components/body/col";
import Icon from "../../../components/body/icon";
import Row from "../../../components/body/row";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import "./style.scss";
import Item from "./Item";
import { GlobalContext } from "../../../context/Global";
import axios from "axios";
import { useNavigate } from "react-router";

export default function Lista() {
    // NAVIGATE
    const navigate = useNavigate();

    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);   

    // ESTADOS
    const [obras, setObras] = useState([]);
    const [obraActived, setObraActived] = useState('');
    const [options, setOptions] = useState([]);
    const [inactive, setInactive] = useState(false);
    const [reload, setReload] = useState(false);

    // FILTROS
    const [filterLoja, setFilterLoja] = useState([]);
    const [filterQualificacao, setFilterQualificacao] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterFormato, setFilterFormato] = useState([]);
    const [filterVistoria, setFilterVistoria] = useState([]);

    // OPTIONS VISTORIAS
    const optionsVistorias = [
        {value: 1, label: 'Inicial'},
        {value: 2, label: 'Intermediária'},
        {value: 3, label: 'Final'}
    ]

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
    },[]);

    // GET OBRAS
    const handleSetObras = (e) => {
        setObras(e);

        if(window.rs_permission_apl === 'operador'){
            if(e.length > 0){
                setObraActived(e[0].id_job_status);
            }
        }
    }

    // SETA LINHAS INATIVAS QUANDO CLICA PARA VISUALIZAR ALGUMA
    const handleSetInactive = (e) => {
        setInactive(e);
    }

    // GET OPTIONS
    useEffect(() => {
        if(options.length == 0){
            axios({
                url: window.host_madnezz + "/systems/integration-react/api/registry.php?do=get_reportFilters",
                method: "get"
            }).then((response) => {
                if(response.data){
                    setOptions(response.data);
                }
            })
        }
    },[]);

    // RELOAD
    const handleReload = (e) => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        },500);
    }

    // FILTRO DE LOJA
    const handleFilterLoja = (e) => {
        setFilterLoja(e);
    }

    // FILTRO DE QUALIFICAÇÃO
    const handleFilterQualificacao = (e) => {
        setFilterQualificacao(e);
    }

    // FILTRO DE STATUS
    const handleFilterStatus = (e) => {
        setFilterStatus(e);
    }

    // FILTRO DE FORMATO
    const handleFilterFormato = (e) => {
        setFilterFormato(e);
    }

    // FILTRO DE VISTORIA
    const handleFilterVistoria = (e) => {
        setFilterVistoria(e);
    }

    // TROCA TELA
    const handleChangeView = () => {
        navigate('/systems/obras-react/relatorio/'+window.token+'/');
    }

    // AÇÕES AO FECHAR DETALHES DO ITEM
    const handleOnClose = () => {
        setObraActived('');
    }

    return (
        <>
            {/* <ModalCadastroObra
                show={showCadastroObra}
                handleShow={handleShowCadastroObra}
                onHide={handleCloseCadastroObra}
            /> */}
            
            <Row>
                <Col lg={12}>
                    <Table
                        id="obras"
                        api={window.host_madnezz+"/systems/integration-react/api/list.php?do=get_list"}
                        params={
                            {
                                filter_type: '5',
                                filter_id_store: (window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : filterLoja),
                                filter_id_format: filterFormato,
                                filter_id_qualification: filterQualificacao,
                                filter_id_status: filterStatus
                            }
                        }
                        text_limit={false}
                        onLoad={handleSetObras}
                        reload={reload}
                        overflow={inactive}
                    >
                        <Thead className="obras-lista-head">
                            <Tr>
                                <Th
                                    name={(!window.rs_id_lja || window.rs_id_lja == 0 ? 'loja' : '')}
                                    api={
                                        (!window.rs_id_lja || window.rs_id_lja == 0 ?
                                            {url: window.host + '/api/sql.php?do=select&component=loja&luc=true'}
                                        :
                                            ''
                                        )
                                    }
                                    onChange={handleFilterLoja}
                                >
                                    Loja
                                </Th>

                                <Th mobile={false}>Iniciado</Th>

                                <Th
                                    name="formato"
                                    items={options.format}
                                    mobile={false}
                                    onChange={handleFilterFormato}
                                >
                                    Formato
                                </Th>

                                <Th
                                    name="qualificacao"
                                    items={options.qualification}
                                    mobile={false}
                                    onChange={handleFilterQualificacao}
                                >
                                    Qualificação
                                </Th>

                                <Th mobile={false}>Documentos</Th>

                                {/* <Th mobile={false}>
                                    Vistoria
                                </Th> */}

                                <Th
                                    name="status"
                                    items={options.status}
                                    mobile={false}
                                    onChange={handleFilterStatus}
                                >
                                    Status
                                </Th>

                                <Th
                                    width={1}
                                    align="center"
                                    className="hide-print"
                                >
                                    Ações
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(obras.length > 0 ?
                                obras.map((obra, i) => {
                                    return (
                                        <Item
                                            key={obra?.id_job_status}
                                            obra={obra}
                                            obraActived={obraActived}
                                            options={options}
                                            active={handleSetInactive}
                                            inactive={inactive}
                                            onClose={handleOnClose}
                                            callback={handleReload}
                                        />
                                    )
                                })
                            :
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
