import { useEffect, useState, useContext } from "react";
import { TreinamentoContext } from "../../../../../context/Treinamento";

import Icon from "../../../../../components/body/icon";
import Loader from "../../../../../components/body/loader";
import Td from "../../../../../components/body/table/tbody/td";
import Tr from "../../../../../components/body/table/tr";
import SubsubItems from "../subsubitems";
import axios from "axios";

export default function Subitems(props){
    // CONTEXT
    const {
        cursoId,
        handleCurso,
        handleSetCursoId
    } = useContext(TreinamentoContext);

    // ESTADOS
    const [subitems, setSubitems] = useState([]);
    const [collapse, setCollapse] = useState(false);

    // CARREGAMENTO
    useEffect(() => {
        if(subitems.length==0){
            axios.get(window.host+'/systems/treinamento/api/lista.php?do=get_foldersFiles&level=1&aux_id='+props.id).then((response) => {
                setSubitems(response.data);
            })
        }
    },[]);

    return(
        (subitems.length>0 ?
            (subitems?.map((item, i) => {
                return(
                    <>
                        <Tr
                            key={item.id}
                            active={(item.curso_id && cursoId == item.id?true:false)}
                            cursor="pointer"
                            onClick={() => (item.curso_id ? handleCurso(item.curso_id, item.nome) : (handleSetCursoId(''), setCollapse(!collapse)))}
                        >
                            <Td
                                key={item.id}
                                style={{paddingLeft:40}}      
                                colspan={(item.curso_id?2:1)}                                
                                title={item.nome}
                            >
                                {item.nome}
                            </Td>
                            {(!item.curso_id ?
                                <Td
                                    width={1}
                                >
                                    <Icon
                                        type={(collapse?'collapseOut':'collapseIn')}
                                    />
                                </Td>
                            :'')}
                        </Tr>
                        {(collapse && !item.curso_id?
                            <SubsubItems id={item.id} />
                        :'')}
                    </>
                )
            }))
        :
            <Tr>
                <Td empty={true}>
                    <Loader show={true}/>
                </Td>
            </Tr>
        )
    )
}