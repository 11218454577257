import { Routes, Route, useParams } from 'react-router-dom';
import { decrypt } from '../../_assets/js/global';
import Container from '../../components/body/container';
import axios from 'axios';

// PÁGINAS
import Lista from './Lista/';

export default function News() {
  const params = useParams();
  const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
  var tokenUrl, idJob;

  if (paramsUrlCount > 0) {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    idJob = params['*'].substring(params['*'].lastIndexOf('/') + 1)
  } else {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.idJob = idJob;
  window.token = tokenUrl;
  axios.defaults.headers.common['Authorization'] = 'Bearer '+window.token;
  window.rs_id_emp = decryptedReturn.id_emp;
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_permission_apl = decryptedReturn.pus;

  return (
    <Container>
      <Routes>
        <Route path="/:token" index={true} element={<Lista />} />
        <Route path="lista/:token" element={<Lista />} />
      </Routes>
    </Container>
  );
}
