import { useState, useEffect } from "react";
import Gerenciador from "../../../components/body/gerenciador";
import Row from "../../../components/body/row";
import Icon from "../../../components/body/icon";
import Table from "../../../components/body/table";
import Tr from "../../../components/body/table/tr";
import Td from "../../../components/body/table/tbody/td";
import Input from "../../../components/body/form/input";
import axios from "axios";
import GerenciadorSistema from "./Sistema";
import Switch from "../../../components/body/switch";
import CadastroIndustria from "./Sistema/cadIndustria";
import CadastroMotivo from "./Estrutura/cadMotivo";
import { toast } from "react-hot-toast";

export default function GerenciadorTrade({ icons, filters }) {

    //
    const [gerenciador, setGerenciador] = useState(true);
    //GERENCIADOR DE INDÚSTRIA
    const [industria, setIndustria] = useState();
    const [searchIndustria, setSearchIndustria] = useState('');
    const [editIndustria, setEditIndustria] = useState(false);
    const [inativoIndustria, setInativoIndustria] = useState();
    const [industriaId, setIndustriaId] = useState('');
    const [industriaNome, setIndustriaNome] = useState('');
    const [industriaSelected, setIndustriaSelected] = useState();
    //GERENCIADOR DE PRODUTO
    const [motivo, setMotivo] = useState('');
    const [searchMotivo, setSearchMotivo] = useState('');
    const [inativoMotivo, setInativoMotivo] = useState('');
    const [editMotivo, setEditMotivo] = useState(false);
    const [motivoId, setMotivoId] = useState('');
    const [motivoNome, setMotivoNome] = useState();

    //PEGAR INFO API INDUSTRIA
    function getIndustry() {
        axios.get(window.host + '/systems/trade/api/gerenciador-beta.php?', {
            params: {
                do: 'industria',
                ativo: 1,
                _: ''
            }
        }).then((response) => {
            setIndustria(response.data)
        })
    };

    useEffect(() => {
        getIndustry();
    }, []);

    //ABRIR FECHAR GERENCIADOR EDITAR INDÚSTRIA 
    const handleEditIndustry = (id, nome) => {
        setEditIndustria(true);
        setIndustriaId(id);
        setIndustriaNome(nome)
        setIndustriaSelected('');
    }
    //FECHAR GERENCIADOR EDITAR INDÚSTRIA 
    const callback_industria = (res) => (setEditIndustria(res), getIndustry());

    //PEGAR INFO API INDUSTRIA
    function getMotive() {
        axios.get(window.host + '/systems/trade/api/gerenciador-beta.php?', {
            params: {
                do: 'motivo',
                ativo: 1,
                _: ''
            }
        }).then((response) => {
            setMotivo(response.data)
        })
    };

    useEffect(() => {
        getMotive();
    }, []);

    //FECHAR GERENCIADOR EDITAR INDÚSTRIA 
    const callback_motivo = (res) => (setEditMotivo(res), getMotive());

    //ABRIR/FECHAR GERENCIADOR DE CONTRATO POR INDÚSTRIA
    const handleMotivo = (id, nome) => {
        setEditMotivo(true);
        setMotivoId(id)
        setMotivoNome(nome)
    }

    //UPDATE ATIVOS
    //ATIVOS/INATIVOS -> PASSANDO A TABELA POR PARAMETRO 
    function updateAtivos(id, status, table) {
        let res = status == 0 ? 1 : 0;
        axios({
            method: "post",
            url: window.host + "/systems/trade/api/gerenciador-beta.php",
            headers: { "Content-Type": "multipart/form-data" },
            data: {
                do: 'ativo',
                tipo: table,
                id: id,
                status: res
            },
        }).then(() => {
            if (table == "industria") {
                getIndustry();
                if (res == 0) {
                    toast("Indústria desativada com sucesso!")
                } else if (res == 1) {
                    toast("Indústria ativada com sucesso!")
                };
                //setSelected('');
            } else if (table == "motivo") {
                getMotive();
                if (res == 0) {
                    toast("Motivo desativado com sucesso!")
                } else if (res == 1) {
                    toast("Motivo ativada com sucesso!")
                };
            };
        })
    };

    useEffect(() => {
        if (icons) {
            icons(<></>)
        }
        if (filters) {
            filters(
                <>
                </>
            )
        }
    }, []);

    return (
        <Row>
            {
                gerenciador ?
                    <>
                        <Gerenciador
                            id="industria_gerenciador"
                            title="Indústria"
                            autoScroll={true}
                            icon={
                                <>
                                    <Icon type="new" onClick={() => handleEditIndustry()} />
                                    <Icon type="cog" title="Gerenciador Estrutura" onClick={() => (setGerenciador(!gerenciador), setEditMotivo(false))} />
                                </>
                            }
                            search={<Input placeholder="Pesquisar" value={searchIndustria} onChange={(e) => setSearchIndustria(e.target.value)} />}
                            switch={
                                <Input
                                    type="checkbox"
                                    id="check_inativos_industria"
                                    label="Mostrar Inativos"
                                    inverse={true}
                                    onChange={(e) => (setInativoIndustria(e.target.checked))}
                                />
                            }
                        >
                            <Table fullHeight={true}>
                                {
                                    industria &&
                                    industria.filter((item) => {
                                        if (!searchIndustria) return true;
                                        if (item.nome.toLowerCase().includes(searchIndustria.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr
                                                key={item.id}
                                                cursor="pointer"
                                                active={(industriaSelected == item.id ? true : false)}
                                            //innerRef={(selected == item.id) ? scrollElement : {}}
                                            >
                                                <Td
                                                    hide={item.ativo == 0 ? (inativoIndustria ? true : false) : ''}
                                                    onClick={() => (setIndustriaSelected(industriaSelected == item.id ? '' : item.id), setEditIndustria(false))}
                                                >
                                                    {item.nome}
                                                </Td>
                                                <Td
                                                    align="end"
                                                    hide={item.ativo == 0 ? (inativoIndustria ? true : false) : ''}
                                                >
                                                    <Icon
                                                        type="edit"
                                                        //animated
                                                        onClick={() => handleEditIndustry(item.id, item.nome)} />
                                                    <Switch
                                                        checked={(item.ativo == 1 ? true : false)}
                                                    //onClick={()=>updateAtivos(item.id, item.ativo, 'industria')}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Table>
                        </Gerenciador>
                        {
                            editIndustria &&
                            <CadastroIndustria
                                id={industriaId}
                                nome={industriaNome}
                                callback={callback_industria}
                            />
                        }
                        {
                            industriaSelected &&
                            <GerenciadorSistema industria_id={industriaSelected} />
                        }
                    </>
                    :
                    <>
                        <Gerenciador
                            id="motivo"
                            title="Motivo"
                            autoScroll={true}
                            icon={
                                <>
                                    <Icon type="new" onClick={() => handleMotivo()} />
                                    <Icon type="enter" title="Gerenciador Sistema" onClick={() => (setGerenciador(!gerenciador), setEditIndustria(false))} />
                                </>
                            }
                            search={<Input placeholder="Pesquisar" value={searchMotivo} onChange={(e) => setSearchMotivo(e.target.value)} />}
                            switch={
                                <Input
                                    type="checkbox"
                                    id="check_inativos"
                                    label="Mostrar Inativos"
                                    inverse={true}
                                    onChange={(e) => (setInativoMotivo(e.target.checked))}
                                />
                            }
                        >
                            <Table fullHeight={true}>
                                {
                                    motivo &&
                                    motivo.filter((item) => {
                                        if (!searchMotivo) return true;
                                        if (item.nome.toLowerCase().includes(searchMotivo.toLowerCase())) {
                                            return true
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Tr
                                                key={item.id}
                                                cursor="pointer"
                                            //active={(motivoSelected == item.id ? true : false)}
                                            //innerRef={(selected == item.id) ? scrollElement : {}}
                                            >
                                                <Td
                                                    hide={item.ativo == 0 ? (inativoMotivo ? true : false) : ''}
                                                // onClick={() => (setIndustriaSelected(motivoSelected == item.id ? '' : item.id), setEditIndustria(false))}
                                                >
                                                    {item.nome}
                                                </Td>
                                                <Td
                                                    align="end"
                                                    //animated
                                                    hide={item.ativo == 0 ? (inativoMotivo ? true : false) : ''}
                                                >
                                                    <Icon type='edit' onClick={() => handleMotivo(item.id, item.nome)} />
                                                    <Switch
                                                        checked={(item.ativo == 1 ? true : false)}
                                                    //onClick={()=>updateAtivos(item.id, item.ativo, 'motivo')}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Table>
                        </Gerenciador>
                        {
                            editMotivo &&
                            <CadastroMotivo
                                id={motivoId}
                                nome={motivoNome}
                                callback={callback_motivo}
                            />
                        }
                    </>
            }
        </Row>
    )
}